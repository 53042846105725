import styled from "styled-components";

export const LoginContent = styled.div`
  text-align: center;
  img {
    max-width: 100%;
    height: auto;
  }
  form {
    max-width: 660px;
    padding: 1rem;
    margin: 1rem auto;
    text-align: left;
    background-color: #fcfcfc;
    h1 {
      font-weight: 500;
      font-size: 2rem !improtant;
      margin: 1rem 0.3rem;
    }
    .ant-col-16 {
      // max-width: 600px;
      display: block;
      flex: unset !important;
      max-width: unset !important;
    }
    input {
      // max-width: 560px !important;
    font-size: 1.2rem !important;
      height: 66px;
      background-color: #fcfcfc;
    }
  }
  .ant-input-password {
    background-color: #fcfcfc;
  }
  .ant-form-item-control-input {
    // max-width: 560px !important;
  }
  label {
    font-size: 1rem !important;
    font-weight: 700;
    ::before {
      display: none !important;
    }
  }
  }
  button {
    width: 110px;
    background-color: #a20010;
    color: #ffffff;
    height: 66px;
    border: none;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    &:hover {
      background-color: #a2001085;
      color: #ffffff;
    }
  }
  a {
    text-decoration: none;
    font-weight: 700;
    font-size: 18px;
    color: #949494;
  }
  @media (max-width: 768px) {
    form {
      width: 100% !important;
      .ant-col-16 {
        // max-width: 600px;
        display: block;
        flex: unset !important;
        max-width: unset !important;
      }
    }
    button {
      height: 36px;
      font-size: 0.9rem;
      float: left;
    }
    
  }
`;
