export const increment = () => {
  return {
    type: "INCREMENT",
  };
};

export const decrement = () => {
  return {
    type: "DECREMENT",
  };
};

export const Business = (data) => {
  return {
    type: "BUSINESS",
    payload: data,
  };
};
export const Owner_Information = (data) => {
  return {
    type: "OWNER_INFORMATION",
    payload: data,
  };
};
export const Odd_Product = (data) => {
  return {
    type: "ODD_PRODUCT",
    payload: data,
  };
};
export const Customer = (data) => {
  return {
    type: "CUSTOMER",
    payload: data,
  };
};
export const Vehicle_Number = (data) => {
  return {
    type: "VEHICLE",
    payload: data,
  };
};
export const Vehicle_Odd = (data) => {
  return {
    type: "VEHICLE_ODD",
    payload: data,
  };
};

export const vehicle = (data) => {
  return {
    type: "VEHICLE",
    payload: data,
  };
};
export const Physical_Damage_Odd = (data) => {
  return {
    type: "PHYSICAL_DAMAGE_ODD",
    payload: data,
  };
};
export const Driver_Info = (data) => {
  return {
    type: "DRIVER_INFO",
    payload: data,
  };
};
//vehicle model missing
export const Driver = (data) => {
  return {
    type: "DRIVER",
    payload: data,
  };
};
export const Current_Insurance = (data) => {
  return {
    type: "CURRENT_INSURANCE",
    payload: data,
  };
};
export const Insurance_Odd = (data) => {
  return {
    type: "INSURANCE_ODD",
    payload: data,
  };
};
export const Additional_Insured = (data) => {
  return {
    type: "ADDITIONAL_INSURED",
    payload: data,
  };
};
export const Limit_S = (data) => {
  return {
    type: "LIMIT_S",
    payload: data,
  };
};
export const coverage = (data) => {
  return {
    type: "COVERAGE_LIMTS",
    payload: data,
  };
};
export const Liability_Odd = (data) => {
  return {
    type: "LIABILITY_ODD",
    payload: data,
  };
};
export const Liability_Action = (data) => {
  return {
    type: "LIABILITY_ACTION",
    payload: data,
  };
};
export const Trailer_Limits = (data) => {
  return {
    type: "TRAILER_LIMITS",
    payload: data,
  };
};
