import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

// layout
import Layout from "./components/layout/Layout";

// all pages
import Describe from "./pages/describeBusiness/index";
import Customer from "./pages/Customers";
import Coverage from "./pages/Coverage";
import UsDot from "./pages/UsDot/";
import DBA from "./pages/DBA";
import LinkExpire from "./pages/404/LinkExpire";
// import Home2 from "./pages/Home/Home2";
// import Address from "./pages/owner";
// import BusinessAddress from "./pages/BusinessAddress/index";
// import BusinessEntity from "./pages/BusinessEntity/index";
// import TAbs from "./pages/tabs/Index";
// import Info from "./pages/vehicleInfo/index";
// import AnVehicle from "./pages/anothervehicle/index";
// import BFrame from "./pages/businessframe/index";
// import Vcard from "./pages/vehiclecard/index";
// import License from "./pages/driversLicense/index";
// import FrameOne from "./pages/frame/index";
// import Insurance1 from "./pages/Insurance1/index";
// import Physical from "./pages/physicalDamage/index";
// import Park from "./pages/parkVehicles/index";
// import VList from "./pages/vehicleList/index";
import Drive from "./pages/drivers/Index";
import AllProduct from "./pages/product/Index";
import OddProduct from "./pages/productodd/Index";
import VehicleTabs from "./pages/vehicleTabs/index";
import Vodd from "./pages/vehicleodd/index";
import Vehicle from "./pages/vehicles/index";
import Contact from "./pages/contactinfo/index";
import Insurance from "./pages/currentInsurance/index";
import InsuranceOdd from "./pages/InsuranceOdd/index";
import DotNum from "./pages/dotNumber/index";
import Accidents from "./pages/driversAccidents/index";
import Entity from "./pages/entityType/index";
import Liability1 from "./pages/liability/index";
import LiabilityOdd1 from "./pages/liabilityOdd/index";
import PhysicalOdd from "./pages/physicalDamageOdd/index";
import VIdentity from "./pages/vehiclesIdentity/index";
import CoveragesL from "./pages/coverageslimit";
import LocationAddress from "./pages/address/index";
import Login from "./pages/login/index";
import AddInsureds from "./pages/additionalInsureds";
import VModale from "./pages/vehicleModel";
import TLimits from "./pages/trailerLimits";
import ContactInfo from "./components/ContactInfo";
// import BlanketAdditionalInsurance from "./components/BlanketAdditionalInsurance";
import BlanketAdditional from "./pages/BlanketAdditionalInsurance/index";
import HiredLiability from "./pages/HiredLiability";
import DriversList from "./pages/DriversList";

const AllRoutes = () => {
  const [render, setRender] = useState(false);
  return (
    <div>
      <Layout>
        <Routes>
          <Route path="/" element={<Contact />} />
          <Route path="/coverage" element={<Coverage />} />
          <Route path="/describe" element={<Describe />} />
          <Route path="/customer" element={<Customer />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/usdot" element={<UsDot />} />
          <Route path="/dba" element={<DBA />} />
          <Route path="/locationaddress" element={<LocationAddress />} />
          <Route path="/driver" element={<Drive />} />
          <Route path="/product" element={<AllProduct />} />
          <Route path="/oddproduct" element={<OddProduct />} />
          <Route path="/vehicle" element={<Vehicle />} />
          <Route path="/vehicletabs" element={<VehicleTabs />} />
          <Route path="/vehicleodd" element={<Vodd />} />
          <Route path="/vehicleidentity" element={<VIdentity />} />
          <Route path="/contactinfo" element={<Contact />} />
          <Route path="/currentinsurance" element={<Insurance />} />
          <Route path="/insuranceodd" element={<InsuranceOdd />} />
          <Route path="/404" element={<LinkExpire />} />
          {/* blanket additional issurance */}
          <Route
            path="/blanket-additional-insurance"
            element={<BlanketAdditional />}
          />

          {/* Hired Liability */}
          <Route path="/hired-liability" element={<HiredLiability />} />

          {/** Drivers List */}
          <Route path="/drivers-list" element={<DriversList />} />

          <Route path="/dotnumber" element={<DotNum />} />
          <Route path="/accidents" element={<Accidents />} />
          <Route path="/entitytype" element={<Entity />} />
          <Route path="/liability" element={<Liability1 />} />
          <Route path="/liabilityodd" element={<LiabilityOdd1 />} />
          <Route path="/coverageslimit" element={<CoveragesL />} />
          <Route path="/physicalodd" element={<PhysicalOdd />} />
          <Route path="/additionalinsureds" element={<AddInsureds />} />
          <Route path="/vmodel" element={<VModale />} />
          <Route path="/tlimits" element={<TLimits />} />
          {/* <Route path="/home" element={<Home2 />} /> */}
          {/* <Route path="/businessaddress" element={<BusinessAddress />} /> */}
          {/* <Route path="/businessentity" element={<BusinessEntity />} /> */}
          {/* <Route path="/address" element={<Address />} /> */}
          {/* <Route path="/tabs" element={<TAbs />} /> */}
          {/* <Route path="/vehicleinfo" element={<Info />} /> */}
          {/* <Route path="/vehiclecard" element={<Vcard />} /> */}
          {/* <Route path="/anvehicle" element={<AnVehicle />} /> */}
          {/* <Route path="/businessframe" element={<BFrame />} /> */}
          {/* <Route path="/insurance" element={<Insurance1 />} /> */}
          {/* <Route path="/license" element={<License />} /> */}
          {/* <Route path="/frame" element={<FrameOne />} /> */}
          {/* <Route path="/vehiclelist" element={<VList />} /> */}
          {/* <Route path="/physical" element={<Physical />} /> */}
          {/* <Route path="/park" element={<Park />} /> */}
        </Routes>
      </Layout>
    </div>
  );
};

export default AllRoutes;
