import React from "react";
import { Container } from "react-bootstrap";
import { Form } from "antd";
import { NextBtn } from "../../styles";
import Drivers from "../../components/Drivers";
import Product from "../../components/Product";
import { Link } from "react-router-dom";

const AllProduct = () => {
  return (
    <Container>
      <Form name="basic" layout="vertical" onFinish={""} onFinishFailed={""}>
        <Link to="">
          <img src="./images/chevron.svg" alt="click here" />
          PREVIOUS
        </Link>
        <Product />
        <NextBtn>Next</NextBtn>
      </Form>
    </Container>
  );
};

export default AllProduct;
