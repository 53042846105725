import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Form, Input } from 'antd'
import { FaAddressCard } from 'react-icons/fa'
import { ToastContainer, toast } from 'react-toastify'

import { Link, useNavigate } from 'react-router-dom'
import { ContactArea } from '../styles'
import { useSelector, useDispatch } from 'react-redux'
import { increment, decrement, AddContactInfo } from '../action/counterAction'

import '../styles/contactInfo.css'

const REQUIRED_ITEMS = [
  'business',
  'coverage',
  'customer',
  // "driver",
  'selectedDrivers',
  'summary',
  'selectedVehicles'
]

const ContactInfo = () => {
  const dispatch = useDispatch()

  /* Navigator */
  const navigate = useNavigate()

  const counter = useSelector(state => state.counterReducer)

  const [data, setData] = useState({})
  const [allData, setAllData] = useState({
    business: {},
    customer: {},
    driver: {},
    vehicle: [],
    selectedDrivers: [],
    coverage: {},
    summary: {}
  })

  useEffect(() => {
    const formPushed = localStorage.getItem('formPushed')
    const parsedFormPushed = formPushed && JSON.parse(formPushed)

    if (formPushed) {
      navigate(0)
      localStorage.removeItem('formPushed')
    }
  }, [])

  const handleContactInfo = e => {
    const { name, value } = e.target
    setData({ ...data, [name]: value })
  }
  const pushDataToLocalStorage = () => {
    localStorage.setItem('business', JSON.stringify(data))
  }

  const ContactInfoValidator = () => {
    const {
      business_first_name: firstName,
      business_last_name: lastName,
      business_email: email,
      business_phone: photNumber
    } = data
    return firstName && lastName && email && photNumber
  }

  /**
   * display notification
   * @param {string} message - message recevied from api response
   */
  const notify = message => {
    Object.keys(localStorage).forEach(key => {
      localStorage.removeItem(key)
    })

    toast.success(message)

    setTimeout(() => {
      navigate('/')
    }, 4000)
  }

  function allStorage () {
    const allDataFromLocalStorage = {}
    for (let key in localStorage) {
      if (REQUIRED_ITEMS.includes(key)) {
        if (key === 'selectedVehicles') {
          allDataFromLocalStorage.vehicle = JSON.parse(
            localStorage[key]
          ).selectedVehicles

          continue
        }

        allDataFromLocalStorage[key] = JSON.parse(localStorage[key])
      }
    }
    setAllData(prev => {
      return { ...prev, ...allDataFromLocalStorage }
    })
  }

  React.useEffect(() => {
    allStorage()
  }, [])

  function postData () {
    let dataToPost = {
      ...allData,
      business: { ...allData.business, ...data },
      coverage: { ...allData.coverage },
      summary: { ...data }
    }

    axios
      .patch(
        'https://backend.cffinsure.com/v1/api/dashboard/cff_dashboard_data',
        dataToPost
      )
      .then(data => notify(data.data.message))
  }

  // Populate Previous data if user go back to previous page.
  useEffect(() => {
    const business = localStorage.getItem('business')
    const parsedBusiness = business && JSON.parse(business)

    setData(prev => {
      return { ...prev, ...parsedBusiness }
    })
  }, [])

  return (
    <Form
      fields={[
        {
          name: 'business_first_name',
          value: data?.business_first_name
        },
        {
          name: 'business_m_i',
          value: data?.business_m_i
        },
        {
          name: 'business_last_name',
          value: data?.business_last_name
        },
        {
          name: 'business_email',
          value: data?.business_email
        },
        {
          name: 'business_phone',
          value: data?.business_phone
        }
      ]}
    >
      <div className='innerSectionOdd'>
        <ToastContainer />
        <ContactArea style={{ marginTop: '4rem' }}>
          <h1 className='main-heading'>
            What is your preferred contact information?
          </h1>
          <div className='nameInputs'>
            <Form.Item
              label={<span className='labelOdd'>First Name</span>}
              name='business_first_name'
              rules={[
                {
                  required: true,
                  message: 'Please input your first name'
                }
              ]}
            >
              <Input
                className='fixed-input'
                suffix={<FaAddressCard />}
                name='business_first_name'
                onChange={handleContactInfo}
              />
            </Form.Item>
            <Form.Item
              label={<span className='labelOdd'>M.I.</span>}
              name='business_m_i'
              rules={[
                {
                  required: true,
                  message: 'm.i.!'
                }
              ]}
            >
              <Input name='business_m_i' onChange={handleContactInfo} />
            </Form.Item>
            <Form.Item
              label={<span className='labelOdd'>Last Name</span>}
              name='business_last_name'
              rules={[
                {
                  required: true,
                  message: 'Please input your last name.'
                }
              ]}
            >
              <Input name='business_last_name' onChange={handleContactInfo} />
            </Form.Item>
          </div>
          <Form.Item
            label={<span className='labelOdd'>Email Address</span>}
            name='business_email'
            rules={[
              {
                required: true,
                message: 'Please input your email address.'
              }
            ]}
          >
            <Input name='business_email' onChange={handleContactInfo} />
          </Form.Item>
          <Form.Item
            label={<span className='labelOdd'>Phone Number</span>}
            name='business_phone'
            rules={[
              {
                required: true,
                message: 'Please input your phone number.'
              }
            ]}
          >
            <Input name='business_phone' onChange={handleContactInfo} />
          </Form.Item>
          {/* <Link to="/coverage" className="nextBtn" >
          Next
        </Link> */}

          <Link
            onClick={pushDataToLocalStorage}
            to={(ContactInfoValidator() && '/coverage') || '#'}
            className={`nextBtn ${!ContactInfoValidator() && 'btn-disable'}`}
            style={{
              margin: 0,
              marginTop: 60,
              pointerEvents: (!ContactInfoValidator() && 'none') || ''
            }}
            disabled={!ContactInfoValidator()}
          >
            Next
          </Link>
        </ContactArea>
      </div>
    </Form>
  )
}

export default ContactInfo
