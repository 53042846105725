import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link, useNavigate } from "react-router-dom";

// Toaster
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";

import { Form, Input, Select, Button } from "antd";

import apiRequest from "../apiRequest";
import { Liability_Odd } from "../action/counterAction";
import { LiabilityOddArea } from "../styles";

const REQUIRED_ITEMS = [
  "business",
  "coverage",
  "customer",
  "driver",
  "summary",
  "selectedVehicles",
];

const LiabilityOdd = () => {
  const [data, setData] = useState({});
  const [yesTrailerButtonClass, setYesTrailerButtonClass] = useState("");
  const [noTrailerButtonClass, setNoTrailerButtonClass] = useState("");

  const [yesNonTrailerButtonClass, setYesNonTrailerButtonClass] = useState("");
  const [noNonTrailerButtonClass, setNoNonTrailerButtonClass] = useState("");

  const [yesHookButtonClass, setYesHookButtonClass] = useState("");
  const [noHookButtonClass, setNoHookButtonClass] = useState("");

  const [yesGaragButtonClass, setYesGaragButtonClass] = useState("");
  const [noGaragButtonClass, setNoGaragButtonClass] = useState("");

  const [yesGeneralLiabilityButtonClass, setYesGeneralLiabilityButtonClass] =
    useState("");
  const [noGeneralLiabilityButtonClass, setNoGeneralLiabilityButtonClass] =
    useState("");

  /* Navigator */
  const navigate = useNavigate();

  /**
   * display notification
   * @param {string} message - message recevied from api response
   */
  const notify = (message) => {
    Object.keys(localStorage).forEach((key) => {
      localStorage.removeItem(key);
    });

    toast.success(message);
    localStorage.setItem("formPushed", JSON.stringify(true));
    setTimeout(() => {
      navigate("/");
    }, 2000);
  };

  const [allData, setAllData] = useState({
    business: {},
    customer: {},
    driver: {},
    vehicle: [],
    coverage: {},
    summary: {},
  });

  React.useEffect(() => {
    allStorage();
  }, []);

  useEffect(() => {
    const liabilityOdd = localStorage.getItem("liabilityOdd");
    const parsedLiabilityOdd = liabilityOdd && JSON.parse(liabilityOdd);
    const {
      garbage_keepers,
      gernal_libility,
      non_own_damage,
      on_hook,
      trailer_interchange,
    } = parsedLiabilityOdd || {};
    (garbage_keepers && handleGarbageKeeperYes()) ||
      (garbage_keepers === 0 && handleGarbageKeeperNo());
    (gernal_libility && handleGeneralLiabilityYes()) ||
      (gernal_libility === 0 && handleGeneralLiabilityNo());
    (non_own_damage && handleNonOwnDamageYes()) ||
      (non_own_damage === 0 && handleNonOwnDamageNo());
    (on_hook && handleOnHookYes()) || (on_hook === 0 && handleOnHookNo());
    (trailer_interchange && handleTrailerInterchangeYes()) ||
      (trailer_interchange === 0 && handleTrailerInterchangeNo());

    liabilityOdd &&
      setData((prev) => {
        return { ...prev, ...parsedLiabilityOdd };
      });
  }, []);

  useEffect(() => {
    localStorage.setItem("liabilityOdd", JSON.stringify(data));
  });

  function allStorage() {
    const allDataFromLocalStorage = {};
    for (let key in localStorage) {
      if (REQUIRED_ITEMS.includes(key)) {
        if (key === "selectedVehicles") {
          allDataFromLocalStorage.vehicle = JSON.parse(
            localStorage[key]
          ).selectedVehicles;
          continue;
        }

        allDataFromLocalStorage[key] = JSON.parse(localStorage[key]);
      }
    }
    setAllData((prev) => {
      return { ...prev, ...allDataFromLocalStorage };
    });
  }

  const [testValueOdd, setTestValueOdd] = useState(true);

  const handleSelect = (key) => (value) => {
    if (key === "trailer_interchange") {
      if (value === "1") {
        setTestValueOdd(false);
      } else if (value === "0") {
        setTestValueOdd(true);
      }
    }
    setData((prev) => {
      return { ...prev, [key]: value };
    });
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleLiabilityOdd = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const { Option } = Select;

  // const handleChange = (value) => {
  //   if (value === "0") {
  //     setData({ ...data, summary_interchange_1: "0" });
  //     setTestValueOdd(true);
  //   } else {
  //     if (value === "1") {
  //       setData({ ...data, summary_interchange_1: "1" });
  //       setTestValueOdd(false);
  //     }
  //   }
  // };

  const allDataFromReducx = useSelector((state) => state.contactInfoReducer);

  function postData() {
    let dataToPost = {
      ...allData,
      coverage: { ...allData.coverage, ...data },
      summary: { ...data },
    };

    axios
      .patch(
        "https://backend.cffinsure.com/v1/api/dashboard/cff_dashboard_data",
        dataToPost
      )
      .then((data) => {
        axios
          .post("https://backend.cffinsure.com/v1/api/temp", {
            email: "ahmadtouseef946@gmail.com",
            data: {
              ...localStorage,
            },
          })
          .then(() => {
            notify(data.data.message);
          });
      })
      .catch((err) => {});
  }

  const pushDataToLocalStorage = () => {
    let preData = JSON.parse(localStorage.getItem("coverage"));
    let updatedData = { ...preData, ...data };
    localStorage.setItem("coverage", JSON.stringify(updatedData));
  };

  /*
   * current Insurance Values Validator
   * @return {boolean}
   **/
  const liabilityOddValidator = () => {
    return (
      generalLiabilityValidator() &&
      trailerInterchangeValicator() &&
      nonTrailerInterchangeValicator() &&
      onHookValidator() &&
      garbageKeeperValidator()
    );
  };

  const generalLiabilityValidator = () => {
    return data?.gernal_libility == 1 || data?.gernal_libility == 0;
  };

  const trailerInterchangeValicator = () => {
    if (yesTrailerButtonClass) {
      return data?.number_of_non_owned && data?.trailer_deductable;
    } else if (noTrailerButtonClass) {
      return true;
    }
  };

  const nonTrailerInterchangeValicator = () => {
    if (yesNonTrailerButtonClass) {
      return data?.non_own_deductable;
    } else if (noNonTrailerButtonClass) {
      return true;
    }
  };

  const onHookValidator = () => {
    if (yesHookButtonClass) {
      return data?.on_hook_deductable;
    } else if (noHookButtonClass) {
      return true;
    }
  };

  const garbageKeeperValidator = () => {
    if (yesGaragButtonClass) {
      return data?.garage_keepers_deductable;
    } else if (noGaragButtonClass) {
      return true;
    }
  };

  const handleClick = (name, value) => {
    setData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleTrailerInterchangeYes = () => {
    setYesTrailerButtonClass(true);
    setNoTrailerButtonClass(false);
    handleClick("trailer_interchange", 1);
  };
  const handleTrailerInterchangeNo = () => {
    handleClick("trailer_interchange", 0);
    setYesTrailerButtonClass(false);
    setNoTrailerButtonClass(true);
  };

  const handleNonOwnDamageYes = () => {
    setYesNonTrailerButtonClass(true);
    setNoNonTrailerButtonClass(false);
    handleClick("non_own_damage", 1);
  };
  const handleNonOwnDamageNo = () => {
    setYesNonTrailerButtonClass(false);
    setNoNonTrailerButtonClass(true);
    handleClick("non_own_damage", 0);
  };

  const handleGeneralLiabilityYes = () => {
    setYesGeneralLiabilityButtonClass(true);
    setNoGeneralLiabilityButtonClass(false);
    handleClick("gernal_libility", 1);
  };
  const handleGeneralLiabilityNo = () => {
    setYesGeneralLiabilityButtonClass(false);
    setNoGeneralLiabilityButtonClass(true);
    handleClick("gernal_libility", 1);
  };

  const handleOnHookYes = () => {
    setYesHookButtonClass(true);
    setNoHookButtonClass(false);
    handleClick("on_hook", 1);
  };
  const handleOnHookNo = () => {
    setNoHookButtonClass(true);
    setYesHookButtonClass(false);
    handleClick("on_hook", 0);
  };

  const handleGarbageKeeperYes = () => {
    setYesGaragButtonClass(true);
    setNoGaragButtonClass(false);
    handleClick("garbage_keepers", 1);
  };
  const handleGarbageKeeperNo = () => {
    setNoGaragButtonClass(true);
    setYesGaragButtonClass(false);
    handleClick("garbage_keepers", 0);
  };

  return (
    <div className="innerSection" style={{ marginTop: "3rem" }}>
      <Form
        fields={[
          {
            name: "number_of_non_owned",
            value: data?.number_of_non_owned,
          },
          {
            name: "trailer_deductable",
            value: data?.trailer_deductable,
          },
          {
            name: "non_own_deductable",
            value: data?.non_own_deductable,
          },
          {
            name: "on_hook_deductable",
            value: data?.on_hook_deductable,
          },
          {
            name: "garage_keepers_deductable",
            value: data?.garage_keepers_deductable,
          },
        ]}
      >
        <LiabilityOddArea>
          <ToastContainer />
          <h1 className="hmb-0" style={{ fontSize: 25, fontWeight: 600 }}>
            Trailer Interchange
          </h1>
          <div className="" style={{ flexWrap: "wrap" }}>
            <Form.Item label="" name="lpl" style={{ marginRight: "1rem" }}>
              <div>
                <Button
                  className={yesTrailerButtonClass === true ? "active" : ""}
                  value={1}
                  name="trailer_interchange"
                  onClick={(e) => {
                    handleTrailerInterchangeYes();
                  }}
                >
                  Yes
                </Button>
                <Button
                  className={noTrailerButtonClass === true ? "active" : ""}
                  value={0}
                  name="trailer_interchange"
                  onClick={(e) => {
                    handleTrailerInterchangeNo();
                  }}
                >
                  No
                </Button>
              </div>
            </Form.Item>
            {yesTrailerButtonClass === true ? (
              <>
                <Form.Item
                  label={
                    <span className="labelOdd">
                      Number of Non-Owned Trailers
                    </span>
                  }
                  name="number_of_non_owned"
                  rules={[
                    {
                      required: true,
                      message: "!",
                    },
                  ]}
                  style={{
                    marginRight: "1rem",
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "column",
                  }}
                >
                  <Input
                    name="number_of_non_owned"
                    // onChange={handleLiabilityOdd}
                    onChange={handleChange}
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <span className="labelOdd">
                      Coverage limit and deductible
                    </span>
                  }
                  name="trailer_deductable"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "!",
                  //   },
                  // ]}
                >
                  <Select
                    style={{ height: "50px", width: "420px" }}
                    name="trailer_deductable"
                    onSelect={handleSelect("trailer_deductable")}
                    placeholder="Select"
                    allowClear
                  >
                    <Option value="$5k with a $500 Deductible">
                      $5k with a $500 Deductible
                    </Option>
                    <Option value="$5k with a $1,000 Deductible">
                      $5k with a $1,000 Deductible
                    </Option>
                    <Option value="$10k with a $500 Deductible">
                      $10k with a $500 Deductible
                    </Option>
                    <Option value="$10k with a $1,000 Deductible">
                      $10k with a $1,000 Deductible
                    </Option>
                    <Option value="$25k with a $500 Deductible">
                      $25k with a $500 Deductible
                    </Option>
                    <Option value="$25k with a $500 Deductible">
                      $25k with a $500 Deductible
                    </Option>
                    <Option value="$50k with a $1,000 Deductible">
                      $50k with a $1,000 Deductible
                    </Option>
                    <Option value="$50k with a $1,000 Deductible">
                      $50k with a $1,000 Deductible
                    </Option>
                    <Option value="$75k with a $1,000 Deductible">
                      $75k with a $1,000 Deductible
                    </Option>
                    <Option value="$75k with a $1,000 Deductible">
                      $75k with a $1,000 Deductible
                    </Option>
                    <Option value="$100k with a $1,000 Deductible">
                      $100k with a $1,000 Deductible
                    </Option>
                    <Option value="$100k with a $1,000 Deductible">
                      $100k with a $1,000 Deductible
                    </Option>
                    <Option value="$150k with a $1,000 Deductible">
                      $150k with a $1,000 Deductible
                    </Option>
                    <Option value="$150k with a $1,000 Deductible">
                      $150k with a $1,000 Deductible
                    </Option>
                    <Option value="$200k with a $1,000 Deductible">
                      $200k with a $1,000 Deductible
                    </Option>
                    <Option value="$200k with a $1,000 Deductible">
                      $200k with a $1,000 Deductible
                    </Option>
                    <Option value="$250k with a $1,000 Deductible">
                      $250k with a $1,000 Deductible
                    </Option>
                    <Option value="$250k with a $1,000 Deductible">
                      $250k with a $1,000 Deductible
                    </Option>
                  </Select>
                </Form.Item>
              </>
            ) : (
              ""
            )}
          </div>
          <h1 className="hmb-0" style={{ fontSize: 25, fontWeight: 600 }}>
            Non-Owned Trailer Physical Damage
          </h1>
          <div style={{ alignItems: "center" }}>
            <div>
              <Button
                className={yesNonTrailerButtonClass === true ? "active" : ""}
                value={1}
                name="non_own_damage"
                onClick={(e) => {
                  handleNonOwnDamageYes();
                }}
              >
                Yes
              </Button>
              <Button
                className={noNonTrailerButtonClass === true ? "active" : ""}
                value={0}
                name="trailer_interchange"
                onClick={(e) => {
                  handleNonOwnDamageNo();
                }}
              >
                No
              </Button>
            </div>
            {yesNonTrailerButtonClass && (
              <div>
                <Form.Item
                  label={
                    <span className="labelOdd">
                      Coverage limit and deductible
                    </span>
                  }
                  name="non_own_deductable"
                  rules={[
                    {
                      required: true,
                      message: "!",
                    },
                  ]}
                >
                  <Select
                    style={{ height: "50px", width: "420px" }}
                    name="non_own_deductable"
                    // onChange={handleLiabilityOdd}
                    onSelect={handleSelect("non_own_deductable")}
                    placeholder="Select"
                    allowClear
                  >
                    <option value="$5k with a $500 Deductible">
                      $5k with a $500 Deductible
                    </option>
                    <option value="$5k with a $1,000 Deductible">
                      $5k with a $1,000 Deductible
                    </option>
                    <option value="$10k with a $500 Deductible">
                      $10k with a $500 Deductible
                    </option>
                    <option value="$10k with a $1,000 Deductible">
                      $10k with a $1,000 Deductible
                    </option>
                    <option value="$25k with a $500 Deductible">
                      $25k with a $500 Deductible
                    </option>
                    <option value="$25k with a $500 Deductible">
                      $25k with a $500 Deductible
                    </option>
                    <option value="$50k with a $1,000 Deductible">
                      $50k with a $1,000 Deductible
                    </option>
                    <option value="$50k with a $1,000 Deductible">
                      $50k with a $1,000 Deductible
                    </option>
                    <option value="$75k with a $1,000 Deductible">
                      $75k with a $1,000 Deductible
                    </option>
                    <option value="$75k with a $1,000 Deductible">
                      $75k with a $1,000 Deductible
                    </option>
                    <option value="$100k with a $1,000 Deductible">
                      $100k with a $1,000 Deductible
                    </option>
                    <option value="$100k with a $1,000 Deductible">
                      $100k with a $1,000 Deductible
                    </option>
                    <option value="$150k with a $1,000 Deductible">
                      $150k with a $1,000 Deductible
                    </option>
                    <option value="$150k with a $1,000 Deductible">
                      $150k with a $1,000 Deductible
                    </option>
                    <option value="$200k with a $1,000 Deductible">
                      $200k with a $1,000 Deductible
                    </option>
                    <option value="$200k with a $1,000 Deductible">
                      $200k with a $1,000 Deductible
                    </option>
                    <option value="$250k with a $1,000 Deductible">
                      $250k with a $1,000 Deductible
                    </option>
                    <option value="$250k with a $1,000 Deductible">
                      $250k with a $1,000 Deductible
                    </option>
                  </Select>
                </Form.Item>
              </div>
            )}
          </div>
          <p style={{ fontSize: 25, fontWeight: 600, marginBottom: 0 }}>
            General Liability
          </p>
          <div>
            <div>
              <Button
                className={
                  yesGeneralLiabilityButtonClass === true ? "active" : ""
                }
                value={1}
                name="gernal_libility"
                onClick={(e) => {
                  handleGeneralLiabilityYes();
                }}
              >
                Yes
              </Button>
              <Button
                className={
                  noGeneralLiabilityButtonClass === true ? "active" : ""
                }
                value={0}
                name="trailer_interchange"
                onClick={(e) => {
                  handleGeneralLiabilityNo();
                }}
              >
                No
              </Button>
            </div>
          </div>
          <h1
            className="hmb-0"
            style={{ fontSize: 25, fontWeight: 600, marginTop: "1.5rem" }}
          >
            On Hook
          </h1>
          <div style={{ alignItems: "center" }}>
            <div>
              <Button
                className={yesHookButtonClass === true ? "active" : ""}
                value={1}
                name="on_hook"
                onClick={(e) => {
                  handleOnHookYes();
                }}
              >
                Yes
              </Button>
              <Button
                className={noHookButtonClass === true ? "active" : ""}
                value={0}
                name="on_hook"
                onClick={(e) => {
                  handleOnHookNo();
                }}
              >
                No
              </Button>
            </div>
            {yesHookButtonClass && (
              <Form.Item
                label={
                  <span className="labelOdd">
                    Coverage limit and deductible
                  </span>
                }
                name="on_hook_deductable"
                // rules={[
                //   {
                //     required: true,
                //     message: "!",
                //   },
                // ]}
              >
                <Select
                  style={{ height: "50px", width: "420px" }}
                  name="on_hook_deductable"
                  // onChange={handleLiabilityOdd}
                  onSelect={handleSelect("on_hook_deductable")}
                  placeholder="Select"
                  allowClear
                >
                  <option value="$5k with a $500 Deductible">
                    $5k with a $500 Deductible
                  </option>
                  <option value="$5k with a $1,000 Deductible">
                    $5k with a $1,000 Deductible
                  </option>
                  <option value="$10k with a $500 Deductible">
                    $10k with a $500 Deductible
                  </option>
                  <option value="$10k with a $1,000 Deductible">
                    $10k with a $1,000 Deductible
                  </option>
                  <option value="$25k with a $500 Deductible">
                    $25k with a $500 Deductible
                  </option>
                  <option value="$25k with a $500 Deductible">
                    $25k with a $500 Deductible
                  </option>
                  <option value="$50k with a $1,000 Deductible">
                    $50k with a $1,000 Deductible
                  </option>
                  <option value="$50k with a $1,000 Deductible">
                    $50k with a $1,000 Deductible
                  </option>
                  <option value="$75k with a $1,000 Deductible">
                    $75k with a $1,000 Deductible
                  </option>
                  <option value="$75k with a $1,000 Deductible">
                    $75k with a $1,000 Deductible
                  </option>
                  <option value="$100k with a $1,000 Deductible">
                    $100k with a $1,000 Deductible
                  </option>
                  <option value="$100k with a $1,000 Deductible">
                    $100k with a $1,000 Deductible
                  </option>
                  <option value="$150k with a $1,000 Deductible">
                    $150k with a $1,000 Deductible
                  </option>
                  <option value="$150k with a $1,000 Deductible">
                    $150k with a $1,000 Deductible
                  </option>
                  <option value="$200k with a $1,000 Deductible">
                    $200k with a $1,000 Deductible
                  </option>
                  <option value="$200k with a $1,000 Deductible">
                    $200k with a $1,000 Deductible
                  </option>
                  <option value="$250k with a $1,000 Deductible">
                    $250k with a $1,000 Deductible
                  </option>
                  <option value="$250k with a $1,000 Deductible">
                    $250k with a $1,000 Deductible
                  </option>
                </Select>
              </Form.Item>
            )}
          </div>
          <h1
            className="hmb-0"
            style={{ fontSize: 25, fontWeight: 600, marginTop: 24 }}
          >
            Garage keepers
          </h1>
          <div style={{ alignItems: "center" }}>
            <div>
              <Button
                className={yesGaragButtonClass === true ? "active" : ""}
                value={1}
                name="garbage_keepers"
                onClick={(e) => {
                  handleGarbageKeeperYes();
                }}
              >
                Yes
              </Button>
              <Button
                className={noGaragButtonClass === true ? "active" : ""}
                value={0}
                name="garbage_keepers"
                onClick={(e) => {
                  handleGarbageKeeperNo();
                }}
              >
                No
              </Button>
            </div>

            {yesGaragButtonClass && (
              <Form.Item
                label={
                  <span className="labelOdd">
                    Coverage limit and deductible
                  </span>
                }
                name="ptz"
                rules={[
                  {
                    required: true,
                    message: "!",
                  },
                ]}
              >
                <Select
                  style={{ height: "50px", width: "420px" }}
                  name="garage_keepers_deductable"
                  // onChange={handleLiabilityOdd}
                  onSelect={handleSelect("garage_keepers_deductable")}
                  placeholder="Select"
                  allowClear
                >
                  <Option value="$5k with a $500 Deductible">
                    $5k with a $500 Deductible
                  </Option>
                  <Option value="$5k with a $1,000 Deductible">
                    $5k with a $1,000 Deductible
                  </Option>
                  <Option value="$10k with a $500 Deductible">
                    $10k with a $500 Deductible
                  </Option>
                  <Option value="$10k with a $1,000 Deductible">
                    $10k with a $1,000 Deductible
                  </Option>
                  <Option value="$25k with a $500 Deductible">
                    $25k with a $500 Deductible
                  </Option>
                  <Option value="$25k with a $500 Deductible">
                    $25k with a $500 Deductible
                  </Option>
                  <Option value="$50k with a $1,000 Deductible">
                    $50k with a $1,000 Deductible
                  </Option>
                  <Option value="$50k with a $1,000 Deductible">
                    $50k with a $1,000 Deductible
                  </Option>
                  <Option value="$75k with a $1,000 Deductible">
                    $75k with a $1,000 Deductible
                  </Option>
                  <Option value="$75k with a $1,000 Deductible">
                    $75k with a $1,000 Deductible
                  </Option>
                  <Option value="$100k with a $1,000 Deductible">
                    $100k with a $1,000 Deductible
                  </Option>
                  <Option value="$100k with a $1,000 Deductible">
                    $100k with a $1,000 Deductible
                  </Option>
                  <Option value="$150k with a $1,000 Deductible">
                    $150k with a $1,000 Deductible
                  </Option>
                  <Option value="$150k with a $1,000 Deductible">
                    $150k with a $1,000 Deductible
                  </Option>
                  <Option value="$200k with a $1,000 Deductible">
                    $200k with a $1,000 Deductible
                  </Option>
                  <Option value="$200k with a $1,000 Deductible">
                    $200k with a $1,000 Deductible
                  </Option>
                  <Option value="$250k with a $1,000 Deductible">
                    $250k with a $1,000 Deductible
                  </Option>
                  <Option value="$250k with a $1,000 Deductible">
                    $250k with a $1,000 Deductible
                  </Option>
                </Select>
              </Form.Item>
            )}
          </div>

          <Link
            to="#"
            style={{ color: "white", marginLeft: 0, marginTop: 60 }}
            onClick={postData}
            disabled={!liabilityOddValidator()}
            className={`nextBtn ${!liabilityOddValidator() && "btn-disable"}`}
          >
            Submit
          </Link>
        </LiabilityOddArea>
        <span></span>
      </Form>
    </div>
  );
};

export default LiabilityOdd;
