import React, { useState } from "react";
import { Form, Select } from "antd";
import { Row, Col } from "react-bootstrap";

const LimitItem2 = (props) => {
  const [limitValue, setLimitValue] = useState(true);
  const [splitValue, setSplitValue] = useState(false);
  const { Option } = Select;
  const [data, setData] = useState({});
  const [currentValue, setCurrentValue] = useState("Not Selected");
  const [coverage, setCoverage] = useState("CSL");


  React.useEffect(() => {
    if (Object.values(data).length)
      localStorage.setItem("UIM", JSON.stringify(data?.UIM));
  }, [data]);

  const handleLimitItemChange = (e) => {
    const { name, value } = e.target;

    props.handleData("uim", { ...data, value });
    if (value !== "Select Value") {
      setData({ ...data, [name]: value });
    }
    // localStorage.setItem("UIM", data.UIM);
  };

  const handleChange = (value) => {
    setCoverage(value);
    // props.liabilityChange(value)
    if (value === "3") {
      setLimitValue(false);
    } else {
      setLimitValue(true);
    }
    if (value === "1") {
      setSplitValue(true);
    } else {
      setSplitValue(false);
    }
  };

  const formateValue = (value) => {
    return props.cat + value;
  };

  const handleSelect = (value) => {
    setCurrentValue(value);
  };

  React.useEffect(() => {
    setLimitValue(3);
    handleChange("CSL");
  }, []);


  React.useEffect(() => {
    const uim = localStorage.getItem("UIM");
    const parsedUim = uim && JSON.parse(uim);

    uim && setCoverage(Object.keys(parsedUim)[0])
  })

  return (
    <>
      <Col md={3} className="coverageInputs">
        <div className="liner"></div>
        <p className="coverLogo">
          {props.coverHeading}
          <div>?</div>
        </p>
        <Form.Item
          label=""
          name={props.coverName}
          rules={[
            {
              required: true,
              message: "!",
            },
          ]}
        >
          <Select
            name="liability"
            className="NewSelect2"
            onChange={handleChange}
            allowClear
            defaultValue={coverage}
            onSelect={handleSelect}
          >
            <Option value="CSL">CSL</Option>
            <Option value="SPLIT">SPLIT</Option>
            <Option value="Non Trucking">Non Trucking</Option>
            <Option value="3">Not Selected</Option>
          </Select>
        </Form.Item>
      </Col>
      <Col md={8} className="limitInputs">
        {limitValue === true ? (
          <Form.Item
            label=""
            name={props.limitName}
            rules={[
              {
                required: true,
                message: "!",
              },
            ]}
          >
            <select
              className="NewSelect"
              name="UIM"
              placeholder="Select"
              defaultValue="love"
              value="love"
              allowClear
              onChange={handleLimitItemChange}
            >
              {splitValue === true ? (
                <>
                  <option value="$25k / $50k / $15k">$25k / $50k / $15k</option>
                  <option value="$50k / $100k / $25k">
                    $50k / $100k / $25k
                  </option>
                  <option value="$100k / $300k / $50k">
                    $100k / $300k / $50k
                  </option>
                  <option value="$100k / $300k / $100k">
                    $100k / $300k / $100k
                  </option>
                  <option value="$250k / $500k / $100k">
                    $250k / $500k / $100k
                  </option>
                </>
              ) : (
                <>
                  <option value="Select Value">Select Value</option>
                  <option value="$100k">$100k</option>
                  <option value="$300k">$300k</option>
                  <option value=" $500k">$500k</option>
                  <option value=" $750k">$750k</option>
                  <option value="$1 Million">$1 Million</option>
                  <option value="$1.5 Million">$1.5 Million</option>
                  <option value="$2 Million">$2 Million</option>
                </>
              )}
            </select>
          </Form.Item>
        ) : (
          ""
        )}
      </Col>
    </>
  );
};

export default LimitItem2;
