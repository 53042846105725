import React, { useEffect } from "react";
import { Button, DatePicker, Form, Select } from "antd";
import { ButtonsArea } from "../styles/index";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { Current_Insurance } from "../action/counterAction";

import moment from "moment";

const CurrentInsurance = () => {
  const [data, setData] = useState({});
  const dispatch = useDispatch();

  const handleSelectDate = (date, dateString) => {
    setData((prev) => {
      return { ...prev, driver_exp_date: dateString };
    });
  };

  const handleCurrentInsurance = (key, e) => {
    setData((prev) => {
      return { ...prev, [key]: e };
    });
    // console.log(data)
  };
  const pushDataToLocalStorage = () => {
    // const driver = localStorage.getItem("driver");
    const driver = localStorage.getItem("business");
    const driverParsed = (driver && JSON.parse(driver)) || {};
    localStorage.setItem(
      "business",
      JSON.stringify({ ...driverParsed, ...data })
    );
  };
  const dateFormat = "MM/DD/YYYY";
  const { Option } = Select;

  // Implement style if catagory is in state
  const setActiveOrDisactive = (catagory, value) => {
    return {
      borderColor: data[catagory] === value && "#a20010",
      backgroundColor: data[catagory] === value && "#b57f82",
      color: data[catagory] === value && "#ffffff",
    };
  };

  /*
   * current Insurance Values Validator
   * @return {boolean}
   **/
  const currentInsuranceValidator = () => {
    const { driver_continous_ins, driver_currently_ensure, driver_exp_date, driver_libility_limit } = data;
    return driver_continous_ins && driver_currently_ensure && driver_exp_date && driver_libility_limit;
  };

  useEffect(() => {
    const { driver_continous_ins, driver_currently_ensure, driver_exp_date, driver_libility_limit } = data;
    console.log((driver_continous_ins && driver_currently_ensure && driver_exp_date && driver_libility_limit) || false);
    // console.log(data, ' data ')
  })

  useEffect(() => {
    const driver = localStorage.getItem("business");
    const parsedDriver = driver && JSON.parse(driver);
    const {
      driver_currently_ensure,
      driver_continous_ins,
      driver_libility_limit,
      driver_exp_date,
    } = parsedDriver || {};
    if (driver_currently_ensure == "0") {
      setActiveOrDisactive("driver_currently_ensure", "0");
    } else if (driver_currently_ensure) {
      setActiveOrDisactive("driver_currently_ensure", "1");
    }
    setActiveOrDisactive("driver_continous_ins", "1");
    if (driver_continous_ins == "0") {
      setActiveOrDisactive("driver_continous_ins", "0");
    } else if (driver_continous_ins) {
      setActiveOrDisactive("driver_continous_ins", "1");
    }

    console.log(driver_currently_ensure, " EXCLUDE");
    driver &&
      setData((prev) => {
        return {
          driver_currently_ensure,
          driver_continous_ins,
          driver_libility_limit,
          driver_exp_date,
        };
      });
  }, []);


  console.log(data, ' data/.....')

  return (
    <Form
      fields={[
        {
          name: "driver_libility_limit",
          value: data?.driver_libility_limit,
        },
      ]}
    >
      <div className="innerSection">
        <ButtonsArea>
          <div >
            <h1 style={{ fontSize: 25, fontWeight: 600 }}>Are you currently insured?</h1>
            <p>
              Personal autos also qualify for proof of prior insurance discount
            </p>
            <Button
              style={setActiveOrDisactive("driver_currently_ensure", "1")}
              onClick={() => setData({ ...data, driver_currently_ensure: "1" })}
            >
              Yes
            </Button>
            <Button
              style={setActiveOrDisactive("driver_currently_ensure", "0")}
              onClick={() => setData({ ...data, driver_currently_ensure: "0" })}
            >
              No
            </Button>
          </div>
          <div className="insuranceYears" style={{ marginTop: 15, marginBottom: 12}}>
            <h1 style={{ fontSize: 25, fontWeight: 600 }}>
              Have you had at least one year of continuous insurance?
            </h1>
            <Button
              style={setActiveOrDisactive("driver_continous_ins", "1")}
              onClick={() => setData({ ...data, driver_continous_ins: "1" })}
            >
              Yes
            </Button>
            <Button
              style={setActiveOrDisactive("driver_continous_ins", "0")}
              onClick={() => setData({ ...data, driver_continous_ins: "0" })}
            >
              No
            </Button>
          </div>
          <Form.Item
            label={
              <p style={{ fontSize: 25, fontWeight: 600, marginBottom: 0 }}>
                Current Bodily Injury Liability Limit
              </p>
            }
            name="driver_libility_limit"
            rules={[
              {
                required: true,
                message: "Please input your liability limit",
              },
            ]}
          >
            <Select
              style={{ height: "50px", width: "410px" }}
              allowClear
              name="driver_libility_limit"
              onChange={(value) => {
                handleCurrentInsurance("driver_libility_limit", value);
              }}
              placeholder="Select"
            >
              <Option value="$30k/$60k">$30k/$60k</Option>
              <Option value="$50k/$100k">$50k/$100k</Option>
              <Option value="$100k/$300k">$100k/$300k</Option>
              <Option value="$250k/$500k">$250k/$500k</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={
              <p style={{ fontSize: 25, fontWeight: 600, marginBottom: 0 }}>Current Policy Expiration Date</p>
            }
            name="driver_exp_date"
            rules={[
              {
                required: true,
                message: "Please input your policy expiration date",
              },
            ]}
          >
            {console.log(data?.driver_exp_date)}
            <DatePicker
              // defaultValue={moment(data?.driver_exp_date)}
              value={data?.driver_exp_date && moment(data?.driver_exp_date)}
              placeholder="MM/DD/YYYY"
              format={dateFormat}
              name="driver_exp_date"
              onChange={handleSelectDate}
            />
          </Form.Item>
        </ButtonsArea>
        <Link
          onClick={pushDataToLocalStorage}
          to={(currentInsuranceValidator() && "/insuranceodd") || "#"}
          style={{ marginLeft: 0 }}
          className={`nextBtn ${!currentInsuranceValidator() && "btn-disable"}`}
        >
          Next
        </Link>
        <span></span>
      </div>
    </Form>
  );
};

export default CurrentInsurance;
