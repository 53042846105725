import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { VehicleOddArea } from "../styles/index";
import { Link } from "react-router-dom";
import { Vehicle_Odd } from "../action/counterAction";
import { useDispatch } from "react-redux";

const VehiclesOdd = () => {
  const dispatch = useDispatch();
  const [showVehicleInputs, setShowVehicleInputs] = useState(false);
  const [noButtonClass, setNoButtonClass] = useState(false);
  const [yesButtonClass, setYesButtonClass] = useState(false);
  const [data, setData] = useState({});
  const [isDataAvailable, setIsDataAvailable] = useState(true);

  // will hold, Model, Model Year, Make
  const [vehicleDetail, setVehicleDetail] = useState(false);
  // is vehicle data avail
  const [isAvail, setIsAvail] = useState(true);

  // getting data from localstorage which was taken from previous step, by using VIM
  React.useEffect(() => {
    const vehicleData = localStorage.getItem("vehicle");
    const vehicleDataParsed = (vehicleData && JSON.parse(vehicleData)) || {};
    const {
      "Model Year": modelYear,
      Model: model,
      Make: make,
    } = vehicleDataParsed;
    setData(prev => {
      return {...prev, vehicle_model: model?.Value, vehicle_maker: make?.Value, vehicle_year: modelYear?.Value}
    })
    setVehicleDetail(vehicleDataParsed);
  }, []);

  React.useEffect(() => {
    console.log(vehicleDetail, " data ");
  });

  React.useEffect(() => {
    if (!vehicleDetail || !Object.values(vehicleDetail).length) {
      setShowVehicleInputs(true);
      setNoButtonClass(true);
      setYesButtonClass(false);
    } else {
      setShowVehicleInputs(false);
      setNoButtonClass(false);
      // setYesButtonClass(true);
    }
  }, [vehicleDetail]);

  const pushDataToLocalStorage = () => {
    let preData = JSON.parse(localStorage.getItem("vehicle"));
    if (!Object.values(preData).length || noButtonClass) {
      let updatedData = {
        ...preData,
        Model: {
          Value: data?.vehicle_model,
        },
        Make: {
          Value: data?.vehicle_maker,
        },
        "Model Year": {
          Value: data?.vehicle_year,
        },
      };
      localStorage.setItem("vehicle", JSON.stringify(updatedData));
    }
  };

  const handleVehicleOdd = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  // Display Vehicle Info
  const displayVichleInfo = () => {
    const {
      "Model Year": modelYear,
      Model: model,
      Make: make,
    } = vehicleDetail;

    if (modelYear?.Value && model?.Value && make?.Value) {
      return (
        modelYear?.Value +
        " " +
        make?.Value +
        " " +
        model?.Value
      ).toUpperCase();
    }
    return "Data is Not Available";
  };

  /*
   * Customer Values Validator
   * @return {boolean}
   **/
  const vehicleOddValidator = () => {
    const editVehicle = localStorage.getItem("editVehicle");
    const parsedEditVehicle = editVehicle && JSON.parse(editVehicle);
    // debugger;
    const { vehicle_year, vehicle_maker, vehicle_model } = data;


    // if (Object.keys(vehicleDetail).length) {
    //   const { Make, Model, "Model Year": modelYear} = vehicleDetail;
    // }

    if (noButtonClass) {
      return (
        (vehicle_year && vehicle_maker && vehicle_model) || (vehicleDetail?.Make?.Value && vehicleDetail?.Model?.Value && vehicleDetail?.["Model Year"]?.Value)
      );
    } else if (yesButtonClass) {
      return true;
    }

  };

  // React.useEffect(() => {
  //   console.log(vehicleDetail, ' vehicleDetails')
  //   const addVehicleOrTrailer = localStorage.getItem("addVehicle") || localStorage.getItem("addTrailer");
  //   const parsedAddVehicleOrTrailer = addVehicleOrTrailer && JSON.parse(addVehicleOrTrailer);
  //
  //   setVehicleDetail({});
  // })

  return (
    <div className="innerSection">
      <VehicleOddArea>
        <h1 style={{ fontWeight: "600", fontSize: 25 }}>
          Is this your vehicle?
        </h1>
        {showVehicleInputs === false ? (
          <h3 style={{ marginBottom: "2rem" }}>{displayVichleInfo()}</h3>
        ) : (
          ""
        )}
        <Button
          className={yesButtonClass === true ? "active" : ""}
          onClick={() => {
            setShowVehicleInputs(false);
            setYesButtonClass(true);
            setNoButtonClass(false);
          }}
        >
          Yes
        </Button>
        <Button
          className={noButtonClass === true ? "active" : ""}
          onClick={(e) => {
            setShowVehicleInputs(true);
            setNoButtonClass(true);
            setYesButtonClass(false);
          }}
        >
          No
        </Button>
      </VehicleOddArea>
      {showVehicleInputs === true ? (
        <div>
          <Form.Item
            label={<p style={{ fontSize: 25, fontWeight: 600, marginTop: 15, marginBottom: 0, padding: 0 }}>What is the Vehicle year?</p>}
            name="xyz"
            rules={[
              {
                required: true,
                message: "Please input your vehicle year",
              },
            ]}
          >
            <Input
              name="vehicle_year"
              placeholder="Vehicle Year"
              onChange={handleVehicleOdd}
              defaultValue={vehicleDetail?.["Model Year"]?.Value}
            />
          </Form.Item>
          <Form.Item
            label={<p style={{ fontSize: 25, fontWeight: 600, marginTop: 15, marginBottom: 0, padding: 0 }}>What is the Vehicle make?</p>}
            name="yax"
            rules={[
              {
                required: true,
                message: "Please input your vehicle make",
              },
            ]}
          >
            <Input
              name="vehicle_maker"
              placeholder="Vehicle make."
              onChange={handleVehicleOdd}
              defaultValue={vehicleDetail?.Make?.Value}
            />
          </Form.Item>
          <Form.Item
            label={
              <p style={{ fontSize: 25, fontWeight: 600, marginTop: 15, marginBottom: 0, padding: 0 }}>What is the Vehicle model?</p>
            }
            name="zax"
            rules={[
              {
                required: true,
                message: "Please input your vehicle model",
              },
            ]}
          >
            <Input
              name="vehicle_model"
              placeholder="Vehicle model"
              onChange={handleVehicleOdd}
              defaultValue={vehicleDetail?.Model?.Value}
            />
          </Form.Item>
        </div>
      ) : (
        ""
      )}
      <Link
        onClick={pushDataToLocalStorage}
        to={(vehicleOddValidator() && "/vehicle") || "#"}
        style={{ marginLeft: 0, marginTop: 60 }}
        className={`nextBtn ${!vehicleOddValidator() && "btn-disable"}`}
      >
        Next
      </Link>
    </div>
  );
};

export default VehiclesOdd;
