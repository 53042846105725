import React, { useState, useEffect } from "react";
import { Form, Input, Button } from "antd";
import { FaRegAddressCard } from "react-icons/fa";
import { BusinessArea } from "../styles/index";
import { Link } from "react-router-dom";
import { Business_DBA } from "../action/counterAction";
import { useDispatch } from "react-redux/es/exports";

const Dba = () => {
  const dispatch = useDispatch();
  const [businessValue, setBusinessValue] = useState(false);
  const [businessName, setBusinessName] = useState("");
  const [noButtonClass, setNoButtonClass] = useState(false);
  const [yesButtonClass, setYesButtonClass] = useState(false);
  const [data, setData] = useState({});

  const [dotBusinessName, setDotBusinessName] = useState(""); // is business name avail from dot Api.!

  // is this your address
  const [isYourAddress, setIsYourAddress] = useState(true);

  const [yesBusinessName, setYesBusinessName] = useState(false);
  const [noBusinessName, setNoBusinessName] = useState(false);

  const getBusinessNameFromDot = () => {
    const dot = localStorage.getItem("dot");
    const dotObj = (dot && JSON.parse(dot)) || {};
    if (Object.values(dotObj).length) {
      const legalName = JSON.parse(localStorage.getItem("dot")).legalName;
      setDotBusinessName(legalName);
      !data?.business_name &&
        setData((prev) => {
          return { ...prev, business_name: legalName };
        });
      return legalName;
    }
  };

  const dbaHandle = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const pushDataToLocalStorage = () => {
    const business = localStorage.getItem("business");
    const parsedBusiness = business && JSON.parse(business);

    if (noButtonClass) {
      localStorage.setItem(
        "business",
        JSON.stringify({ ...parsedBusiness, ...data, business_DBA: "" })
      );
      return;
    }

    let preData = JSON.parse(localStorage.getItem("business"));
    let updatedData = { ...preData, ...data };
    localStorage.setItem("business", JSON.stringify(updatedData));
  };

  // Set busniess name provided by dot api
  useEffect(() => {
    getBusinessNameFromDot();
  }, []);

  const businessNameValidator = () => {
    if (dotBusinessName) {
      return (yesBusinessName && true) || data?.business_name;
    } else if (data?.business_name) {
      return true;
    }
  };

  // validator
  const dbaValidator = () => {
    return businessNameValidator() && (noButtonClass || data?.business_DBA);
  };

  const handleYes = () => {
    setBusinessValue(true);
    setYesButtonClass(true);
    setNoButtonClass(false);
  };

  const handleNo = () => {
    setBusinessValue(false);
    setYesButtonClass(false);
    setNoButtonClass(true);
  };

  // Populate Previous data if user go back to previous page.
  useEffect(() => {
    const business = localStorage.getItem("business");
    const parsedBusiness = business && JSON.parse(business);

    const { business_name, business_DBA } = parsedBusiness;
    if (business_name && business_DBA) {
      handleYes();
      setData((prev) => {
        return { ...prev, business_name, business_DBA };
      });
    } else if (business_name && !business_DBA) {
      handleNo();
      setData((prev) => {
        return { ...prev, business_name };
      });
    }
  }, []);

  const cleanFromLocalStorage = () => {
    const business = localStorage.getItem("business");
    const parsedBusiness = business && JSON.parse(business);

    localStorage.setItem(
      "business",
      JSON.stringify({
        ...parsedBusiness,
        us_dot_number: "",
        years_in_business: "",
      })
    );
  };

  return (
    <>
      <Form
        fields={[
          {
            name: "business_DBA",
            value: data?.business_DBA,
          },
        ]}
      >
        <div className="innerSection">
          <BusinessArea>
            <Form.Item
              label={
                <p
                  style={{
                    fontWeight: 600,
                    fontSize: 25,
                    padding: 0,
                    margin: 0,
                  }}
                >
                  What is the business name?
                </p>
              }
              name="abc"
              rules={[
                {
                  required: true,
                  message: "Please input your business name!",
                },
              ]}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                {/** if business name is avail by dot api then ask is it current or not.? */}
                {dotBusinessName && (
                  <div>
                    <div style={{ fontSize: 20 }}>
                      <p>Is this your business name?</p>
                      <p>{dotBusinessName}</p>
                    </div>
                    <Form.Item>
                      <Button
                        className={yesBusinessName === true ? "active" : ""}
                        onClick={() => {
                          setData((prev) => {
                            return { ...prev, business_name: "" };
                          });
                          setYesBusinessName(true);
                          setNoBusinessName(false);
                          setIsYourAddress(true);
                        }}
                      >
                        Yes
                      </Button>
                      <Button
                        className={noBusinessName === true ? "active" : ""}
                        onClick={() => {
                          setData((prev) => {
                            return { ...prev, business_name: "" };
                          });
                          setNoBusinessName(true);
                          setYesBusinessName(false);
                          setIsYourAddress(false);
                        }}
                      >
                        No
                      </Button>
                    </Form.Item>
                  </div>
                )}

                {/** Check is dot api provide business name, or if provide then it is your name or not.! */}
                {(!dotBusinessName || !isYourAddress) && (
                  <Input
                    placeholder="Business Name"
                    suffix={<FaRegAddressCard />}
                    name="business_name"
                    onChange={dbaHandle}
                    value={data?.business_name}
                    // defaultValue={}
                  />
                )}
              </div>
            </Form.Item>
            <Form.Item
              label={
                <p
                  style={{
                    fontSize: 25,
                    fontWeight: 600,
                    padding: 0,
                    margin: 0,
                  }}
                >
                  Does the business have a DBA?
                </p>
              }
              name="def"
              rules={[
                {
                  required: true,
                  message: "Please input your doing business as!",
                },
              ]}
            >
              <Button
                className={yesButtonClass === true ? "active" : ""}
                onClick={() => {
                  handleYes();
                }}
              >
                Yes
              </Button>
              <Button
                className={noButtonClass === true ? "active" : ""}
                onClick={() => {
                  handleNo();
                }}
              >
                No
              </Button>
              {businessValue === true ? (
                <Form.Item
                  label={<span className="labelOdd">DBA Name</span>}
                  name="business_DBA"
                  rules={[
                    {
                      required: true,
                      message: "Please input your DBA Name",
                    },
                  ]}
                >
                  <Input
                    placeholder="DBA"
                    name="business_DBA"
                    onChange={dbaHandle}
                  />
                </Form.Item>
              ) : (
                ""
              )}
            </Form.Item>

            <Link
              onClick={pushDataToLocalStorage}
              to={(dbaValidator() && "/describe") || "#"}
              className={`nextBtn ${!dbaValidator() && "btn-disable"}`}
              style={{
                margin: 0,
                marginTop: 60,
                pointerEvents: (!dbaValidator() && "none") || "",
              }}
              disabled={!dbaValidator()}
            >
              Next
            </Link>
          </BusinessArea>
          <span></span>
        </div>
      </Form>
    </>
  );
};

export default Dba;
