import React, {useEffect} from "react";
import { Tabs } from "antd";
import { VehicleBodyArea } from "../styles/index";
import TabBody from "./TabBody";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Customer } from "../action/counterAction";
import {
  AllAreaData,
  CarPickupAreaData,
  LargePassengerAreaData,
  SpecialtyAreaData,
  SuggestedAreaData,
  TrailersAreaData,
  TrucksAreaData,
} from "../data/appdata";
import { useState } from "react";

const VehicleBody = () => {
  const dipatch = useDispatch();
  const { TabPane } = Tabs;
  const [data, setData] = useState({});

  // Populate Previous data if user go back to previous page.
  useEffect(() => {
    const customer = localStorage.getItem('customer');
    const parsedCustomer = customer && JSON.parse(customer);
    const { vehicle_type, vehicle_image } = parsedCustomer || {};
    customer && setData(prev => {
      return { ...prev, vehicle_type, vehicle_image }
    })

  }, [])

  const initializeVehicle = () => {
    const selectedVehicles = localStorage.getItem("selectedVehicles");
    const countSelectedVehicles = localStorage.getItem("countSelectedVehicles");

    !selectedVehicles &&
    createItemsInLocalStorage("selectedVehicles", {
      vin: {},
      selectedVehicleCount: 0,
      selectedVehicles: [],
    });
    !countSelectedVehicles && localStorage.setItem("countSelectedVehicles", 0);
  }

  React.useEffect(() => {
    initializeVehicle();
  }, []);


  useEffect(() => {
    const addVehicleOrTrailer = localStorage.getItem("addTrailer") || localStorage.getItem("addVehicle");
    const vehicle = localStorage.getItem("vehicle");

    const customer = localStorage.getItem('customer');
    const parsedCustomer = customer && JSON.parse(customer);
    const { vehicle_type, vehicle_image } = parsedCustomer || {};

    const parsedAddVehicleOrTrailer = addVehicleOrTrailer && JSON.parse(addVehicleOrTrailer);
    if (addVehicleOrTrailer) {
      localStorage.setItem("countSelectedVehicles", 0)
      localStorage.removeItem("addTrailer") || localStorage.removeItem("addVehicle");
      localStorage.setItem('vehicle', JSON.stringify({}));
      if (customer) {
        setData(prev => {
          return { ...prev, vehicle_type: '', vehicle_image: '' }
        })
        localStorage.setItem("customer", JSON.stringify({...parsedCustomer, vehicle_type: '', vehicle_image: ''}))
      }
    }
  })

  React.useEffect(() => {
    const countSelectedVehicles = localStorage.getItem("countSelectedVehicles");

    countSelectedVehicles &&
      Object.values(data).length &&
      localStorage.setItem(
        "countSelectedVehicles",
        JSON.parse(localStorage.getItem("selectedVehicles"))
          .selectedVehicleCount
      );
  });

  React.useEffect(() => {
    pushDataToLocalStorage();
  }, [data]);

  /**
   * Remove previus Seleted Vehicle
   *
   */
  React.useEffect(() => {
    const customer = localStorage.getItem("customer");
    !customer && localStorage.setItem("customer", JSON.stringify({}));
  }, []);

  const createItemsInLocalStorage = (itemName, data) => {
    localStorage.setItem(itemName, JSON.stringify({ ...data }));
  };

  const pushDataToLocalStorage = () => {
    let preData = JSON.parse(localStorage.getItem("customer"));
    let updatedData = { ...preData, ...data };
    localStorage.setItem("customer", JSON.stringify(updatedData));

    dipatch(Customer(updatedData));
  };
  const handleDataChange = (e) => {
    setData(e);
  };

  /**
   * Delete Edited Vehicle From Selected Vehicles.
   */

  React.useEffect(() => {
    const editedVehicle = localStorage.getItem("editedVehicle");
    const countSelectedVehicles = localStorage.getItem("countSelectedVehicles");

    if (editedVehicle && Object.values(data).length) {
      const selectedVehicles = JSON.parse(
        localStorage.getItem("selectedVehicles")
      );

      const editedVehicleParsed = JSON.parse(editedVehicle);
      const updatedSelectedVehildes = {
        ...selectedVehicles,
        selectedVehicles: selectedVehicles.selectedVehicles.filter(
          (vehicle) => {
            return vehicle.vehicle_type !== editedVehicleParsed;
          }
        ),
        selectedVehicleCount: selectedVehicles.selectedVehicleCount - 1,
      };
      localStorage.setItem(
        "selectedVehicles",
        JSON.stringify({ ...updatedSelectedVehildes })
      );

      localStorage.removeItem("editedVehicle");
      localStorage.setItem("countSelectedVehicles", countSelectedVehicles - 1);
    }
  });

  /*
   * Customer Values Validator
   * @return {boolean}
   **/
  const vehicleBodyValidator = () => {
    return Object.keys(data).length;
  };

  const checkAddTrailer = () => {
    const addTrailer = localStorage.getItem("addTrailer");
    const parsedAddTrailer = addTrailer && JSON.parse(addTrailer);
    if (parsedAddTrailer) {
      return 1;
      localStorage.removeItem('addTrailer')
    } else {
      return 0;
    }
  }
  return (
    <div className="innerSectionOdd" style={{ marginTop: '3rem' }}>
      <VehicleBodyArea>
        <h1 style={{ fontWeight: "600", fontSize: 25 }}>
          What is the body type of this vehicle?
        </h1>
        <Tabs defaultActiveKey={`${checkAddTrailer()}`} >
          <TabPane tab="Truck" key="0">
            <TabBody
              tabBodyData={TrucksAreaData}
              handleChange={handleDataChange}
              currentVehicle={data?.vehicle_type}
            />
          </TabPane>
          <TabPane tab="Trailer" key="1">
            <TabBody
              tabBodyData={TrailersAreaData}
              handleChange={handleDataChange}
              currentVehicle={data?.vehicle_type}
            />
          </TabPane>
          {/* <TabPane tab="All" key="2">
            <TabBody tabBodyData={AllAreaData} />
          </TabPane> 
          () => dispatch(Vehicle_Body())
          */}
        </Tabs>
        <Link
          to={(vehicleBodyValidator() && "/vehicleidentity") || "#"}
          className={`nextBtn ${!vehicleBodyValidator() && "btn-disable"}`}
          style={{ marginLeft: 0 }}
        >
          Next
        </Link>
      </VehicleBodyArea>
      <span></span>
    </div>
  );
};

export default VehicleBody;
