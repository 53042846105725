import React from "react";
import { Button } from "antd";
import { VehiclesArea } from "../styles/index";
import { Row, Col } from "react-bootstrap";
import { useState } from "react";
import { Link } from "react-router-dom";
const VehicleModel = () => {
  // const [data , setData] = useState({})
  // const handleVehicleModel = (e) => {
  //   const {name,value} = e.target
  //   setData({...data,[name]:value})
  //   console.log(data)
  // }

  const [selectedVehicles, setSelectedVehicles] = React.useState([]);
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    updateSelectedVehicles(count);
  }, []);

  /**
   * Check is vehicle already exist
   */
  const isVehicleExist = () => {
    const selectedVehicle = getDataFromLocalStorage("customer");
    const selectedVehicles = getDataFromLocalStorage("selectedVehicles");
    console.log(selectedVehicle, selectedVehicles, "selec");
    if (selectedVehicle && selectedVehicles) {
      return selectedVehicles.selectedVehicles.some((vehicle) => {
        return selectedVehicle.vehicle_type === vehicle.vehicle_type;
      });
    }
    return false;
  };

  const updateSelectedVehicles = React.useCallback(
    (count) => {
      const selectedVehicle = getDataFromLocalStorage("customer");
      const selectedVehicles = getDataFromLocalStorage("selectedVehicles");
      const vin = getDataFromLocalStorage("vehicle");
      const countSelectedVehicles = getDataFromLocalStorage(
        "countSelectedVehicles"
      );

      if (
        (Object.values(selectedVehicle).length &&
          countSelectedVehicles === selectedVehicles?.selectedVehicleCount) ||
        Object.keys(selectedVehicles).length === 0
      ) {
        if (!isVehicleExist()) {
          const updatedSelectedVehildes = {
            ...selectedVehicles,
            selectedVehicles: [
              ...selectedVehicles?.selectedVehicles,
              { ...selectedVehicle, vin },
            ],
            selectedVehicleCount: selectedVehicles?.selectedVehicleCount + 1,
          };
          setDataToLocalStorage("selectedVehicles", {
            ...updatedSelectedVehildes,
          });
        }

        // setCount(count);
      }
      setSelectedVehicles(
        JSON.parse(localStorage.getItem("selectedVehicles")).selectedVehicles
      );
    },
    [count]
  );

  // Display Vehicle Info
  const displayVichleInfo = (vin, vehicle_type) => {
    const { "Model Year": modelYear, Model: model, Make: make } = vin;

    if (modelYear?.Value && model?.Value && make?.Value) {
      return modelYear?.Value + " " + make?.Value + " " + model?.Value;
    }

    return vehicle_type;
  };

  const getDataFromLocalStorage = (item) => {
    const data = localStorage.getItem(item);
    const dataParsed = data && JSON.parse(data);
    return dataParsed;
  };

  /**
   *
   * @param {string} item key in localstorage
   * @param {object} value Object which will set to item
   */
  const setDataToLocalStorage = (item, value) => {
    localStorage.setItem(item, JSON.stringify(value));
  };

  /**
   *  Delete selected Vehicle
   *  @param {vehicleType}
   */
  const handleDelete = (vehicleType) => {
    setSelectedVehicles((prev) => {
      return prev.filter((vehicle) => {
        return vehicle?.vehicle_type !== vehicleType;
      });
    });
    setTimeout(() => {
      deletedSelectedVehicleFromLocalStorage(vehicleType);
    }, 1000);
  };

  /**
   * Delete Selected Vehicle From localStorage
   * @param {string} vehicleType
   */
  const deletedSelectedVehicleFromLocalStorage = (vehicleType) => {
    const data = getDataFromLocalStorage("selectedVehicles");
    const updateSeletedVehicles = {
      ...data,
      selectedVehicles: [
        ...(() => {
          return data.selectedVehicles.filter((vehicle) => {
            return vehicle.vehicle_type !== vehicleType;
          });
        })(),
      ],
      selectedVehicleCount:
        (data?.selectedVehicleCount > 0 && data?.selectedVehicleCount - 1) || 0,
    };
    console.log(updateSeletedVehicles, "updated");
    setDataToLocalStorage("selectedVehicles", { ...updateSeletedVehicles });
  };

  /**
   * Edit Selected Vehicle
   * @param {string} vehicleType
   */

  const handleEdit = (vehicleType) => {
    localStorage.setItem("editedVehicle", JSON.stringify(vehicleType));
  };



  return (
    <div className="innerSectionOdd" style={{ marginTop: "4rem" }}>
      <VehiclesArea>
        <div className="mt-5 vehicleButtons">
          <h1 className="sectionHeading mt-5" style={{fontSize: 25, fontWeight: 600}}>
            Here are the vehicles on the quote:
          </h1>
          <div>
            {selectedVehicles?.map((vehicle) => {
              return (
                <Row className="align-items-center mt-5">
                  <Col md={1} style={{ marginRight: 10 }}>
                    <div>
                      <img src={`/images/${vehicle.vehicle_image}`} alt="" />
                    </div>
                  </Col>
                  <Col md={10}>
                    <div>
                      <h3>
                        {displayVichleInfo(vehicle?.vin, vehicle?.vehicle_type)}
                      </h3>
                      <Button
                        onClick={() => handleDelete(vehicle?.vehicle_type)}
                      >
                        Remove
                      </Button>
                      <Button
                        onClick={() =>
                          localStorage.setItem(
                            "editVehicle",
                            JSON.stringify(true)
                          )
                        }
                      >
                        <Link
                          className="new-link"
                          to="/vehicletabs"
                          onClick={() => handleEdit(vehicle.vehicle_type)}
                        >
                          Edit
                        </Link>
                      </Button>
                      <Button>
                        <Link className="new-link" to="/vehicletabs">
                          Add Another TRUCK
                        </Link>
                      </Button>
                    </div>
                  </Col>
                </Row>
              );
            })}
          </div>
        </div>
        <div className="vehicleButtons">
          <h3 style={{ marginTop: "1rem" }}>
            Add another vehicle by year, make, model or VIN
          </h3>
          <p>
            You should include all vehiclestrilers used in the customer’s
            business thet will be in his or her possession for greater then 30
            days.
          </p>
          <Button onClick={() => {
            localStorage.setItem("addVehicle", JSON.stringify(true));
          }}>
            <Link className="new-link" to="/vehicletabs">
              Add Vehicle
            </Link>
          </Button>
          <Button onClick={() => {
            localStorage.setItem("addTrailer", JSON.stringify(true));
          }}>
            <Link className="new-link" to="/vehicletabs">
              Add Trailer
            </Link>
          </Button>
        </div>
      </VehiclesArea>
      <span></span>
    </div>
  );
};

export default VehicleModel;
