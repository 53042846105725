import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPoop } from '@fortawesome/free-solid-svg-icons'
// import style from './LinkExpire.module.css'

const styles = {
  body: {
    backgroundColor: '#fffaf0',
    color: '#333',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0',
    textAlign: 'center',
    minHeight: '100vh',
    fontFamily: '"Courier Prime", sans-serif'
  },
  h1: {
    color: '#7b341e',
    margin: '0',
    fontSize: '300px',
    letterSpacing: '10px',
    lineHeight: '230px'
  },
  'h1 .iconStyle': { position: 'relative', fontSize: '200px', top: '-20px' },
  h2: { fontSize: '65px', margin: '0' },
  '.text': { fontSize: '20px', lineHeight: '30px', margin: '20px 0' },
  '.attribution a': { color: '#333' },
  '@media screen and (max-width: 600px)': {
    h1: { fontSize: '150px', lineHeight: '120px' },
    'h1 .iconStyle': { fontSize: '100px', top: '-10px' },
    h2: { fontSize: '50px' },
    '.text': { maxWidth: '80vw' }
  }
}

export default function LinkExpire () {
  const navigate = useNavigate()
  return (
    <div style={styles.body}>
      <h1 style={styles.h1}>
        4<FontAwesomeIcon icon={faPoop} style={styles['h1 .iconStyle']} />4
      </h1>
      <h2 style={styles.h2}>Sh*t!</h2>
      <p className='text'>
        Link which is you are looking for <i>has Expired</i>.<br />
        For opon it again <i>contact</i> with admin.
        <br />I know, I know, these puns are <i>crappy</i>!
      </p>
      <small className='attribution'>
        <i>
          Go back to the{' '}
          <Link to='/' onClick={() => navigate('/abc')}>
            Home
          </Link>
        </i>
      </small>
    </div>
  )
}
