import React, { useState } from "react";
import { Form, Input } from "antd";
import { DotArea } from "../styles/index";
import { Link } from "react-router-dom";
import { BusinessYears } from "../action/counterAction";
import { useDispatch } from "react-redux";
const DotNumber = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({});

  const handleDotNumber = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setData({ ...data, [name]: value });
    // console.log(data)
  };
  const pushDataToLocalStorage = () => {
    let preData = JSON.parse(localStorage.getItem("business"));
    console.log(preData, " data ");
    let updatedData = { ...preData, ...data };
    // console.log(updatedData)
    localStorage.setItem("business", JSON.stringify(updatedData));
    //  console.log(...preData,newData)
  };

  return (
    <div className="innerSection">
      <DotArea>
        <Form.Item
          label="Number of years in business?"
          name="abc"
          rules={[
            {
              required: true,
              message: "Please input your years in business!",
            },
          ]}
        >
          {/* no of years in business is missing in api */}
          <Input
            defaultValue="00"
            name="years_in_business_2"
            onChange={handleDotNumber}
          />
        </Form.Item>
        <Link
          onClick={pushDataToLocalStorage}
          to="/entitytype"
          className="nextBtn"
        >
          Next
        </Link>
      </DotArea>
      <span></span>
    </div>
  );
};

export default DotNumber;
