const initialState = {
  business: {},
  customer: {},
  driver: {},
  vehicle: [],
  coverage: {},
  summary: {},
};

const contactInfoReducer = (state = initialState, action) => {
  // console.log('in reducer',action)
  switch (action.type) {
    case "BUSINESS":
      return { ...state, business: action.payload };
    case "OWNER_INFORMATION":
      return { ...state, owner_information: action.payload };
    case "ODD_PRODUCT":
      return { ...state, odd_product: action.payload };
    case "CUSTOMER":
      return { ...state, customer: action.payload };
    case "VEHICLE_NUMBER":
      return { ...state, vehicle_number: action.payload };
    case "VEHICLE_ODD":
      return { ...state, vehicle_odd: action.payload };
    case "VEHICLE":
      return { ...state, vehicle: action.payload };
    case "PHYSICAL_DAMAGE_ODD":
      return { ...state, physical_damage_odd: action.payload };
    //vehiclemodel missing
    case "DRIVER_INFO":
      return { ...state, driver_info: action.payload };
    case "DRIVER":
      return { ...state, driver: action.payload };
    case "CURRENT_INSURANCE":
      return { ...state, current_insurance: action.payload };
    case "INSURANCE_ODD":
      return { ...state, insurance_odd: action.payload };
    case "ADDITIONAL_INSURED":
      return { ...state, additional_Insured: action.payload };
    case "LIMIT_S":
      return { ...state, limits: action.payload };
    case "COVERAGE_LIMTS":
      return { ...state, coverage: action.payload };
    case "LIABILITY_ACTION":
      return { ...state, liability_action: action.payload };
    case "TRAILER_LIMITS":
      return { ...state, trailer_limits: action.payload };

    case "LIABILITY_ODD":
      return { ...state, summary: action.payload };
    default:
      return state;
  }
};

export default contactInfoReducer;
