import React, { useEffect } from "react";
import { Button } from "antd";
import { VehiclesArea } from "../styles/index";
import { Row, Col } from "react-bootstrap";
import { useState } from "react";
import { Link } from "react-router-dom";

// Avatar
import Avatar from "react-avatar";

const DriversList = () => {
  const [selectedDrivers, setSelectedDrivers] = React.useState([]);
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    if (getDataFromLocalStorage("editedDriver")) {
      replaceEditedDriver();
      localStorage.removeItem("editedDriver");
    } else {
      updateSelectedDrivers(count);
    }
  }, [count]);

  useEffect(() => {
    removeAddNewDriver();
  }, []);

  const getDataFromLocalStorage = (item) => {
    const data = localStorage.getItem(item);
    const dataParsed = (data && JSON.parse(data)) || false;
    return dataParsed;
  };

  /**
   * Check is vehicle already exist
   */
  const isDriverExist = () => {
    const selectedDriver = getDataFromLocalStorage("driver");
    const selectedDrivers = getDataFromLocalStorage("selectedDrivers");

    // console.log(selectedVehicle, selectedVehicles, "selec");
    if (selectedDriver && selectedDrivers) {
      return selectedDrivers.some((driver) => {
        return driver.driver_licence === selectedDriver.driver_licence;
      });
    }
    return false;
  };

  /**
   * Replace selected driver which was select for edit to new Upcoming array
   * */
  const replaceEditedDriver = () => {
    const currentDriver = getDataFromLocalStorage("driver");
    const selectedDrivers = getDataFromLocalStorage("selectedDrivers");
    const editedDriver = getDataFromLocalStorage("editedDriver");

    console.log(currentDriver, editedDriver, " editeddd");
    const updatedArray = selectedDrivers.map((driver) => {
      const { edit } = driver;
      return (edit && { ...currentDriver }) || driver;
    });
    setSelectedDrivers((prev) => {
      return [...updatedArray];
    });

    setDataToLocalStorage("selectedDrivers", [...updatedArray]);
  };

  const updateSelectedDrivers = React.useCallback(
    (count) => {
      const selectedDriver = getDataFromLocalStorage("driver"); // current Selected Driver

      const selectedDrivers = getDataFromLocalStorage("selectedDrivers"); // Drivers array

      if (Object.values(selectedDriver).length) {
        // check is Driver already exist or not.!
        // if current Driver is not exist
        if (!isDriverExist()) {
          const updatedDrivers = [{ ...selectedDriver }, ...selectedDrivers];
          setDataToLocalStorage("selectedDrivers", [...updatedDrivers]);
        }
      }
      setSelectedDrivers(JSON.parse(localStorage.getItem("selectedDrivers")));
    },
    [count]
  );

  // Display Vehicle Info
  const displayVichleInfo = (driver) => {
    const { driver_first_name, driver_last_name, driver_licence } = driver;
    <label htmlFor="driver">Hllo</label>;
    return (
      <div style={{ display: "flex", columnGap: 30 }}>
        <p id="driver">{driver_first_name + " " + driver_last_name}</p>
      </div>
    );
  };

  /**
   *
   * @param {string} item key in localstorage
   * @param {object} value Object which will set to item
   */
  const setDataToLocalStorage = (item, value) => {
    localStorage.setItem(item, JSON.stringify(value));
  };

  /**
   *  Delete selected Vehicle
   *  @param {vehicleType}
   */
  const handleDelete = (licenceNumber) => {
    setSelectedDrivers((prev) => {
      return prev.filter((driver) => {
        return driver?.driver_licence !== licenceNumber;
      });
    });

    setTimeout(() => {
      deletedSelectedVehicleFromLocalStorage(licenceNumber);
    }, 1000);
  };

  /**
   * Delete Selected Driver From localStorage
   * @param {string} licenceNumber
   */
  const deletedSelectedVehicleFromLocalStorage = (licenceNumber) => {
    const updatedDrivers = selectedDrivers.filter((driver) => {
      return driver?.driver_licence !== licenceNumber;
    });
    setDataToLocalStorage("selectedDrivers", [...updatedDrivers]);
  };

  /**
   * Edit Selected Driver
   * @param {string} driverLicence
   */

  const handleEdit = (driverLicence) => {
    const addEditPropertyInDriver = selectedDrivers.map((driver) => {
      const { driver_licence } = driver;
      return (
        (driver_licence === driverLicence && { ...driver, edit: true }) ||
        driver
      );
    });
    setDataToLocalStorage("selectedDrivers", [...addEditPropertyInDriver]);
    localStorage.setItem("editedDriver", JSON.stringify(true));
  };

  /** removePerviousFields */
  const removePerviousFields = () => {
    localStorage.setItem("driver", JSON.stringify({}));
    localStorage.setItem("addNewDriver", JSON.stringify(true));
  };

  /** Delete add New Driver key in localstorage */
  const removeAddNewDriver = () => {
    const addNewDriver = localStorage.getItem("addNewDriver");

    if (addNewDriver) {
      localStorage.removeItem("addNewDriver");
    }
  };

  return (
    <div className="innerSectionOdd" style={{ marginTop: "7rem" }}>
      <VehiclesArea>
        <div className="mt-5 vehicleButtons">
          <h1 className="sectionHeading mt-5">
            Here are the Drivers on the quote:
          </h1>
          {(selectedDrivers.length && (
            <div>
              {selectedDrivers?.map((driver) => {
                const { driver_first_name, driver_last_name } = driver;
                return (
                  <Row className="align-items-center mt-5">
                    <Col md={1} style={{ marginRight: 10 }}>
                      <div>
                        <Avatar
                          name={driver_first_name + " " + driver_last_name}
                          round="5px"
                          color={Avatar.getRandomColor([""])}
                        />
                      </div>
                    </Col>
                    <Col md={10}>
                      <div>
                        <h3>{displayVichleInfo(driver)}</h3>
                        <Button
                          onClick={() => handleDelete(driver?.driver_licence)}
                        >
                          Remove
                        </Button>

                        <Button onClick={removeAddNewDriver}>
                          <Link
                            className="new-link"
                            to={"/driver"}
                            onClick={() => handleEdit(driver?.driver_licence)}
                          >
                            Edit
                          </Link>
                        </Button>
                        <Button onClick={removePerviousFields}>
                          <Link className="new-link" to="/driver">
                            Add Another Driver
                          </Link>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                );
              })}
            </div>
          )) || (
            <div>
              <h1>There is no Driver yet.!!!</h1>
              <Button onClick={() => {
                removeAddNewDriver();
                removePerviousFields()
              }}>
                <Link className="new-link" to={"/driver"}>
                  Add a driver
                </Link>
              </Button>
            </div>
          )}
        </div>
      </VehiclesArea>
      <span></span>
    </div>
  );
};

export default DriversList;
