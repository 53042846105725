import React from "react";
import { Container } from "react-bootstrap";
import { Form } from "antd";
import { Link } from "react-router-dom";
import CoveragesLimit from "../../components/CoveragesLimit";

const CoveragesL = () => {
  return (
    <Container>
      <Form name="basic" layout="vertical" onFinish={""} onFinishFailed={""}>
        <Link className="prevBtn" to="/drivers-list">
          <img src="./images/chevron.svg" alt="click here" />
          PREVIOUS
        </Link>
        <CoveragesLimit />
        {/* <Link to="/liability" className="nextBtn">
          Next
        </Link> */}
      </Form>
    </Container>
  );
};

export default CoveragesL;
