import React, {useEffect, useState} from "react";
import { Form, Button } from "antd";
import { ButtonsArea } from "../styles/index";
import { Link } from "react-router-dom";
import { Entity_Type } from "../action/counterAction";
import { useDispatch } from "react-redux";

const EntityType = () => {
  const [data, setData] = useState({});
  const pushDataToLocalStorage = () => {
    let preData = JSON.parse(localStorage.getItem("business"));
    let updatedData = { ...preData, ...data };
    // console.log(updatedData)
    localStorage.setItem("business", JSON.stringify(updatedData));
    //  console.log(...preData,newData)
  };
  const dispatch = useDispatch();

  // Implement style if catagory is in state
  const setActiveOrDisactive = (value) => {
    return {
      borderColor: data.entity_type === value && "#a20010",
      backgroundColor: data.entity_type === value && "#b57f82",
      color: data.entity_type === value && "#ffffff",
    };
  };

  /*
   * EntityType Values Selection Validator
   * @return {boolean}
   **/
  const entityTypeValidator = () => {
    return data?.entity_type && true;
  };


  // Populate Previous data if user go back to previous page.
  useEffect(() => {
    const business = localStorage.getItem('business');
    const parsedBusiness = business && JSON.parse(business);
    const {entity_type} = parsedBusiness;
    setData(prev => {
      return { entity_type }
    })

  }, [])


  return (
    <div className="innerSection">
      <Form.Item
        label={
          <p style={{ fontSize: 25, fontWeight: 600, padding: 0 }}>
            What is the business entity type?
          </p>
        }
        name="abc"
        rules={[
          {
            required: true,
            message: "Please input your USDOT!",
          },
        ]}
      >
        <ButtonsArea>
          <Button
            style={setActiveOrDisactive("Corporation")}
            onClick={() => {
              setData({ entity_type: "Corporation" });
            }}
          >
            Corporation
          </Button>
          <Button
            style={setActiveOrDisactive("Individual")}
            onClick={() => {
              setData({ entity_type: "Individual" });
            }}
          >
            Individual
          </Button>
          <Button
            style={setActiveOrDisactive("LLC")}
            onClick={() => {
              setData({ entity_type: "LLC" });
            }}
          >
            LLC
          </Button>
          <Button
            style={setActiveOrDisactive("Municipality")}
            onClick={() => {
              setData({ entity_type: "Municipality" });
            }}
          >
            Municipality
          </Button>
          <Button
            style={setActiveOrDisactive("Partnership")}
            onClick={() => {
              setData({ entity_type: "Partnership" });
            }}
          >
            Partnership
          </Button>
          <Button
            style={setActiveOrDisactive("Other")}
            onClick={() => {
              setData({ entity_type: "Other" });
            }}
          >
            Other
          </Button>
        </ButtonsArea>
        <Link
          onClick={pushDataToLocalStorage}
          to={(entityTypeValidator() && "/dba") || "#"}
          className={`nextBtn ${!entityTypeValidator() && "btn-disable"}`}
          style={{ marginLeft: 0, marginTop: 60 }}
        >
          Next
        </Link>
      </Form.Item>
      <span></span>
    </div>
  );
};

export default EntityType;
