import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link, useNavigate } from "react-router-dom";

// Toaster
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";

import { Form, Input, Select, Button } from "antd";

import apiRequest from "../apiRequest";
import { Liability_Odd } from "../action/counterAction";
import { LiabilityOddArea } from "../styles";

import { RiAddCircleFill, RiDeleteBinLine } from "react-icons/ri";

const REQUIRED_ITEMS = [
  "business",
  "coverage",
  "customer",
  "driver",
  "summary",
  "selectedVehicles",
];

const HiredLiability = () => {
  const [data, setData] = useState({});
  const [commodities, setCommodities] = useState({ item0: "" });
  const [coverageLimits, setCoverageLimits] = useState({ item1: "" });

  const [noButtonClass, setNoButtonClass] = useState(false);
  const [yesButtonClass, setYesButtonClass] = useState(false);
  const [moterTruckYesButtonClass, setMoterTruckYesButtonClass] =
    useState(false);
  const [moterTruckNoButtonClass, setMoterTruckNoButtonClass] = useState(false);

  /* Navigator */
  const navigate = useNavigate();

  /**
   * display notification
   * @param {string} message - message recevied from api response
   */
  const notify = (message) => {
    Object.keys(localStorage).forEach((key) => {
      localStorage.removeItem(key);
    });

    toast.success(message);

    setTimeout(() => {
      navigate("/");
    }, 4000);
  };

  const [allData, setAllData] = useState({
    business: {},
    customer: {},
    driver: {},
    vehicle: [],
    coverage: {},
    summary: {},
  });

  React.useEffect(() => {
    allStorage();
  }, []);

  function allStorage() {
    const allDataFromLocalStorage = {};
    for (let key in localStorage) {
      if (REQUIRED_ITEMS.includes(key)) {
        if (key === "selectedVehicles") {
          allDataFromLocalStorage.vehicle = JSON.parse(
            localStorage[key]
          ).selectedVehicles;
          continue;
        }

        allDataFromLocalStorage[key] = JSON.parse(localStorage[key]);
      }
    }
    setAllData((prev) => {
      return { ...prev, ...allDataFromLocalStorage };
    });
  }

  const [testValueOdd, setTestValueOdd] = useState(true);

  const handleSelect = (key) => (value) => {
    setData((prev) => {
      return { ...prev, [key]: value };
    });
  };

  const handleClick = (name, value) => {
    console.log(value, name);
    setData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const { Option } = Select;

  const pushDataToLocalStorage = () => {
    let preData = JSON.parse(localStorage.getItem("coverage"));
    let updatedData = { ...preData, ...data };
    localStorage.setItem("coverage", JSON.stringify(updatedData));
  };


  useEffect(() => {
    console.log(data)
  })
  /*
   * current Insurance Values Validator
   * @return {boolean}
   **/
  const liabilityOddValidator = () => {
    if (data?.motor_truck_cargo === 0 && (data?.hired_auto_liability === 0 || data?.hired_auto_liability)) {
      return true;
    } else if ((data?.hired_auto_liability || !data?.hired_auto_liability) && data?.motor_truck_cargo) {
      return data?.coverage_limit_deductible && data?.commoditiesArr?.item0;
      return Object.keys(data).length === 3 && data?.commoditiesArr?.item0;
    }
    // return (
    //   (data?.motor_truck_cargo &&
    //     Object.keys(data).length === 4 &&
    //     data?.commoditiesArr?.item0) ||
    //   (data?.commoditiesArr?.item0 && Object.keys(data).length === 4)
    // );
    // return Object.keys(data).length === 4;
  };

  const handleCommodities =
    (name) =>
    ({ target: { value } }) => {
      // setData((prev) => {
      //   return { ...prev, commodities: { ...prev.commodities, [name]: value } };
      // });
      setCommodities((prev) => {
        return { ...prev, [name]: value };
      });
    };

  React.useEffect(() => {
    setData((prev) => {
      return { ...prev, commoditiesArr: commodities };
    });
  }, [commodities]);

  React.useEffect(() => {
    console.log(data, " data");
  });

  let i = 0;
  const handleNewCommodities = () => {
    const key = "item" + "" + (i + 1);
    setCommodities((prev) => {
      return { ...prev, [key]: "" };
    });
  };

  /** Shift Item numbers */
  const shiftItemNumber = (c, index) => {
    const itemsKeyArra = Object.keys(c);
    const firstItemName = itemsKeyArra[0];

    const updatdKetArr = itemsKeyArra?.map((item, index) => {
      return { ["item" + "" + index]: c[item] }
    })

    // var arr = [{key:"11", value:"1100"},{key:"22", value:"2200"}];
    var object = updatdKetArr.reduce(
        (obj, item) => Object.assign(obj, { [Object.keys(item)[0]]: Object.values(item)[0] }), {});

    setCommodities(prev => {
      return {...object}
    })
    console.log(object)
    console.log(updatdKetArr, ' updatedArr ');
  }

  const handleRemoveCommodities = (index) => {

    const comoditiesArr = Object.entries(commodities);
    const updatedComoditiesArr = comoditiesArr.filter((item, i) => {
      return i !== index;
    });
    const comoditiesObj = Object.fromEntries(updatedComoditiesArr);

    setCommodities(() => {
      return { ...comoditiesObj }
    })
  };

  const handleHiredYes = () => {
    setYesButtonClass(true);
    setNoButtonClass(false);
    handleClick("hired_auto_liability", 1);
  }

  const handleHiredNo = () => {
    handleClick("hired_auto_liability", 0);
    setYesButtonClass(false);
    setNoButtonClass(true);
  }


  const handleMotorYes = () => {
    setMoterTruckYesButtonClass(true);
    setMoterTruckNoButtonClass(false);
    handleClick("motor_truck_cargo", 1);
  }

  const handleMotorNo = () => {
    handleClick("motor_truck_cargo", 0);
    setMoterTruckYesButtonClass(false);
    setMoterTruckNoButtonClass(true);
  }

  // Populate Previous data if user go back to previous page.
  useEffect(() => {
    const coverage = localStorage.getItem('coverage');
    const parsedCoverage = coverage && JSON.parse(coverage);

    if (coverage) {
      const { hired_auto_liability, motor_truck_cargo, commoditiesArr, coverage_limit_deductible } = parsedCoverage || {};
      (hired_auto_liability && handleHiredYes()) || hired_auto_liability === 0 && handleHiredNo();
      (motor_truck_cargo && handleMotorYes() || motor_truck_cargo === 0 && handleMotorNo());
      console.log(commoditiesArr, 'ccc')
      commoditiesArr && setCommodities(commoditiesArr);
      setData(prev => {
        return {...prev, coverage_limit_deductible}
      })
    }

  }, [])


  return (
    <div className="innerSection" style={{ marginTop: "2rem" }}>

      <LiabilityOddArea>
        <ToastContainer />
        <Form>
          <h1
            className="hmb-0"
            style={{ fontSize: 25, fontWeight: 600, marginTop: "1.5rem" }}
          >
            Hired Auto Liability
          </h1>
          <div>
            <Button
              className={yesButtonClass === true ? "active" : ""}
              value={1}
              name="hired_auto_liability"
              onClick={(e) => {
                handleHiredYes()
              }}
            >
              Yes
            </Button>
            <Button
              className={noButtonClass === true ? "active" : ""}
              value={0}
              name="hired_auto_liability"
              onClick={(e) => {
                handleHiredNo();
              }}
            >
              No
            </Button>
          </div>
          <h1
            className="hmb-0"
            style={{ fontSize: 25, fontWeight: 600, marginTop: "1.5rem" }}
          >
            Motor Truck Cargo
          </h1>
          <div style={{ display: "flex", marginBottom: 0 }}>
            <Form.Item
              label=""
              name="ots"
              rules={[
                {
                  required: true,
                  message: "!",
                },
              ]}
              style={{ marginRight: "1rem" }}
            >
              <div style={{ alignSelf: "flex-end" }}>
                <Button
                  style={{ marginBottom: 0 }}
                  className={moterTruckYesButtonClass === true ? "active" : ""}
                  value={1}
                  name="motor_truck_cargo"
                  onClick={(e) => {
                    handleMotorYes()
                  }}
                >
                  Yes
                </Button>
                <Button
                  className={moterTruckNoButtonClass === true ? "active" : ""}
                  style={{ marginBottom: 0 }}
                  value={0}
                  name="motor_truck_cargo"
                  onClick={(e) => {
                    handleMotorNo();
                  }}
                >
                  No
                </Button>
              </div>
            </Form.Item>
          </div>
          {/* Coverage Limit and Deductable */}
          {moterTruckYesButtonClass && (
            <>
              <Form.Item
                  label={<p style={{fontSize: 25, fontWeight: 600, marginBottom: 0}}>Coverage limit and deductible</p>}
                name=""
                rules={[
                  {
                    required: true,
                    message: "!",
                  },
                ]}
                style={{ marginRight: "1rem" }}
              >
                <Select
                    defaultValue={data?.coverage_limit_deductible}
                  style={{ height: "50px", width: "420px" }}
                  name="motor_truck_cargo"
                  onSelect={handleSelect("coverage_limit_deductible")}
                  placeholder="Select"
                  allowClear
                >
                  <Option value="$5k with a $500 Deductible">
                    $5k with a $500 Deductible
                  </Option>
                  <Option value="$5k with a $1,000 Deductible">
                    $5k with a $1,000 Deductible
                  </Option>
                  <Option value="$10k with a $500 Deductible">
                    $10k with a $500 Deductible
                  </Option>
                  <Option value="$10k with a $1,000 Deductible">
                    $10k with a $1,000 Deductible
                  </Option>
                  <Option value="$25k with a $500 Deductible">
                    $25k with a $500 Deductible
                  </Option>
                  <Option value="$25k with a $500 Deductible">
                    $25k with a $500 Deductible
                  </Option>
                  <Option value="$50k with a $1,000 Deductible">
                    $50k with a $1,000 Deductible
                  </Option>
                  <Option value="$50k with a $1,000 Deductible">
                    $50k with a $1,000 Deductible
                  </Option>
                  <Option value="$75k with a $1,000 Deductible">
                    $75k with a $1,000 Deductible
                  </Option>
                  <Option value="$75k with a $1,000 Deductible">
                    $75k with a $1,000 Deductible
                  </Option>
                  <Option value="$100k with a $1,000 Deductible">
                    $100k with a $1,000 Deductible
                  </Option>
                  <Option value="$100k with a $1,000 Deductible">
                    $100k with a $1,000 Deductible
                  </Option>
                  <Option value="$150k with a $1,000 Deductible">
                    $150k with a $1,000 Deductible
                  </Option>
                  <Option value="$150k with a $1,000 Deductible">
                    $150k with a $1,000 Deductible
                  </Option>
                  <Option value="$200k with a $1,000 Deductible">
                    $200k with a $1,000 Deductible
                  </Option>
                  <Option value="$200k with a $1,000 Deductible">
                    $200k with a $1,000 Deductible
                  </Option>
                  <Option value="$250k with a $1,000 Deductible">
                    $250k with a $1,000 Deductible
                  </Option>
                  <Option value="$250k with a $1,000 Deductible">
                    $250k with a $1,000 Deductible
                  </Option>
                </Select>
              </Form.Item>

              {/** Label */}
              <div>
                <p style={{ fontSize: 25, fontWeight: 600 }}>Cargo Types</p>
                <Button
                  onClick={() => handleNewCommodities()}
                  style={{
                    padding: "5px 10px",
                    marginBottom: 15,
                    marginLeft: 9,
                    marginRight: 0,
                  }}
                  icon={
                    <RiAddCircleFill
                      style={{ paddingRight: 5, fontSize: 20 }}
                    />
                  }
                >
                  Add
                </Button>
              </div>
              <div
                style={{
                  marginTop: 10,
                  display: "flex",
                  alignItems: "flex-end",
                  columnGap: 40,
                  rowGap: 25,
                  flexWrap: "wrap",
                }}
              >
                {Object.keys(commodities || {}).map((c, index) => {
                  i = index;
                  return (
                    <div>
                      <Input
                        defaultValue={commodities?.[c]}
                        value={commodities?.[c]}
                        placeholder={index + 1 + "."}
                        onChange={handleCommodities("item" + index)}
                        addonAfter={
                          index > 0 && (
                            <RiDeleteBinLine
                              onClick={() => handleRemoveCommodities(index)}
                              style={{
                                cursor: "pointer",
                                fontSize: "1.2rem",
                                color: "#a20010",
                              }}
                            />
                          )
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </>
          )}

          {/* What commodities are you hauling? */}
        </Form>
        <Link
          to="/liabilityodd"
          style={{ color: "white", marginLeft: 0, marginTop: 60 }}
          onClick={pushDataToLocalStorage}
          disabled={!liabilityOddValidator()}
          className={`nextBtn ${!liabilityOddValidator() && "btn-disable"}`}
        >
          Next
        </Link>
      </LiabilityOddArea>
      <span></span>

    </div>
  );
};

export default HiredLiability;
