import React from "react";
import { Container } from "react-bootstrap";
import { Form } from "antd";
import { Link } from "react-router-dom";
import Vehicles from "../../components/Vehicles";

const Vehicle = () => {
  return (
    <Container>
      <Form name="basic" layout="vertical" onFinish={""} onFinishFailed={""}>
        <Link className="prevBtn" to="/vehicleodd">
          <img src="./images/chevron.svg" alt="click here" />
          PREVIOUS
        </Link>
        <Vehicles />
        {/* <Link to="/physicalodd" className="nextBtn">
          Next
        </Link> */}
      </Form>
    </Container>
  );
};

export default Vehicle;
