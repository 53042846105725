import React, { useEffect, useState, useRef } from "react";
import {Button, Form, Input, InputNumber, Select} from "antd";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

// Icons
import { RiAddCircleFill } from "react-icons/ri";
import { IoMdRemoveCircle } from "react-icons/io";

import { PhysicalArea } from "../styles";
import { vehicle } from "../action/counterAction";
const PhysicalDamageOdd = () => {
  const selectRef = useRef(null);

  const dispatch = useDispatch();
  const { Option } = Select;
  const { TextArea } = Input;
  const [value, setValue] = useState("");

  const [equipments, setEquipments] = useState([{ equipment: "", value: "" }]);

  const [showTextArea, setShowTextArea] = useState(false);
  const [showSelect, setShowSelect] = useState(false);
  const [noButtonClass, setNoButtonClass] = useState(false);
  const [yesButtonClass, setYesButtonClass] = useState(false);
  const [noButtonClassOdd, setNoButtonClassOdd] = useState(false);
  const [yesButtonClassOdd, setYesButtonClassOdd] = useState(false);
  const [vehicleDetails, setVehicleDetails] = useState({});

  const [leaseVehicle, setLeaseVehicle] = useState("");

  const [data, setData] = useState({});
  const handlePhysicalDamage = (key, index) => (e) => {
    console.log(key, index, ' 444444444');
    if (key === "value") {
      const arr = [...equipments];
      arr[index] = { ...arr[index], [key]: e };
      setEquipments((prev) => {
        return [...arr];
      });
      return;
    } else if (key === 'equipment') {
      const {name, value} = e?.target;
      const arr = [...equipments];
      arr[index] = { ...arr[index], [key]: value };
      setEquipments((prev) => {
        return [...arr];
      });
      return;
    }
    // alert(e);
    selectRef.current.blur();
    key && setData({ ...data, [key]: e });
    const { name, value } = e.target;
    setData({ ...data, [name]: value });


    // console.log(data)
  };
  const pushDataToLocalStorage = () => {
    let preData = JSON.parse(localStorage.getItem("vehicle"));
    let updatedData = {
      ...preData,
      ...data,
      attached_equipments: [...equipments],
    };

    localStorage.setItem("vehicle", JSON.stringify(updatedData));
    dispatch(vehicle(updatedData));
  };

  // getting data from localstorage which was taken from previous step, by using VIM
  useEffect(() => {
    const vehicleData = localStorage.getItem("vehicle");
    const vehicleDataParsed = (vehicleData && JSON.parse(vehicleData)) || {};
    const {
      lease_vehicle,
      vehicle_value,
      attached_equipments,
      attach_equipment_p1,
      attach_equipment_p2,
      attach_equipment,
      coverage_vehicle,
      coverage_vehicle_p1,
      coverage_vehicle_p2,
    } = vehicleDataParsed;

    setLeaseVehicle(lease_vehicle || "");

    vehicleData &&
      setData({
        lease_vehicle,
        vehicle_value,
        attach_equipment,
        attach_equipment_p1,
        attach_equipment_p2,
        coverage_vehicle,
        coverage_vehicle_p1,
        coverage_vehicle_p2,
      });

    if (coverage_vehicle) {
      setYesButtonClassOdd(true);
      setShowSelect(true);
      setNoButtonClassOdd(false);
    } else if (coverage_vehicle === 0) {
      setNoButtonClassOdd(true);
    }
    attached_equipments?.length && setEquipments(attached_equipments);
    attach_equipment && setShowTextArea(true);

    setVehicleDetails(vehicleDataParsed);
  }, []);

  // Display Vehicle Info
  const displayVichleInfo = () => {
    const {
      "Model Year": modelYear,
      Model: model,
      Make: make,
    } = vehicleDetails;

    if (modelYear?.Value && model?.Value && make?.Value) {
      return modelYear?.Value + " " + make?.Value + " " + model?.Value;
    }
  };

  /*
   * PhysicalOdd Values Validator
   * @return {boolean}
   **/
  const bhysicalOddValueValidator = () => {
    const validateCheckEquipment = () => {
      const obj = equipments[0] || {};
     return obj?.equipment && obj?.value;
      // return attach_equipment_p1 && attach_equipment_p2;
    };

    const validateCoverageVehicle = () => {
      return coverage_vehicle_p1 && coverage_vehicle_p2;
    };

    const {
      lease_vehicle,
      vehicle_value,
      attach_equipment,
      coverage_vehicle,
      attach_equipment_p1,
      attach_equipment_p2,
      coverage_vehicle_p1,
      coverage_vehicle_p2,
    } = data;
    return (
      lease_vehicle &&
      vehicle_value &&
        ((attach_equipment && validateCheckEquipment()) ||
        attach_equipment === 0) &&
      ((coverage_vehicle && validateCoverageVehicle()) ||
        coverage_vehicle === 0)
    );
  };

  const handleRemoveEquipment = (index) => {
    setEquipments((prev) => {
      return prev.filter((e, i) => {
        return i !== index;
      });
    });
  };

  const handleChange = ({ target: { name, value } }) => {
    setData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  useEffect(() => {
    console.log(data, " ->>>");
  });

  const handleYes = () => {
    setData({ ...data, attach_equipment: 1 });
    setShowTextArea(true);
    setYesButtonClass(true);
    setNoButtonClass(false);
  }

  const handleNo = () => {
    setData({ ...data, attach_equipment: 0 });
    setShowTextArea(false);
    setYesButtonClass(false);
    setNoButtonClass(true);
  }


  // Populate Previous data if user go back to previous page.
  useEffect(() => {
    const vehicle = localStorage.getItem('vehicle');
    const parsedVehicle = vehicle && JSON.parse(vehicle);
    const { attach_equipment, attached_equipments } = parsedVehicle;
    if (attach_equipment) {
      setEquipments([...attached_equipments])
    } else if (attach_equipment === 0) {
      handleNo();
    }
    setData(prev => {
      return { ...prev, ...parsedVehicle}
    })
  }, [])




  return (
    <Form
      fields={[
        {
          name: ["lease_vehicle"],
          value: data?.lease_vehicle,
        },
        {
          name: ["vehicle_value"],
          value: data?.vehicle_value,
        },
        {
          name: ["driver_licence"],
          value: data?.driver_licence,
        },
        {
          name: ["driver_state"],
          value: data?.driver_state,
        },
      ]}
    >
      <div className="innerSectionOdd" style={{ marginTop: "3rem" }}>
        <PhysicalArea>
          <p
            style={{ fontSize: 37, marginBottom: "3rem" }}
            className="sectionHeading"
          >
            {displayVichleInfo()}
          </p>
          <div style={{ marginBottom: "1rem" }}>
            <Form.Item
              label={
                <p style={{ fontSize: 25, fontWeight: 600, marginBottom: 0 }}>
                  Is there a loan/lease on this vehicle?
                </p>
              }
              name="lease_vehicle"
              rules={[
                {
                  required: true,
                  message: "Please input your loan/lease of vehicle!",
                },
              ]}
            >
              <Select
                placeholder="Loan / Lease"
                style={{ height: "50px", width: "410px" }}
                name="lease_vehicle"
                onChange={handlePhysicalDamage("lease_vehicle")}
                allowClear
                ref={selectRef}
                value={leaseVehicle}
              >
                <Option style={{ height: "" }} value="Yes – Loan">
                  Yes – Loan
                </Option>
                <Option value="Yes – Lease">Yes – Lease</Option>
                <Option value="No">No</Option>
              </Select>
            </Form.Item>
          </div>
          <Form fields={[
            {
              name: 'vehicle_value',
              value: data?.vehicle_value
            }
          ]}>
          <Form.Item
            label={
              <p style={{ fontSize: 25, marginBottom: 0, fontWeight: 600 }}>
                What is the vehicle value?
              </p>
            }
            name="vehicle_value"
            rules={[
              {
                required: true,
                message: "Please input your vehicle value!",
              },
            ]}
            prefix={"$"}
          >
            <InputNumber
                defaultValue={data?.vehicle_value}
                name="vehicle_value"
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                onChange={handlePhysicalDamage("vehicle_value")}
            />
            {/*<InputNumber*/}
            {/*  formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}*/}
            {/*  parser={value => value.replace(/\$\s?|(,*)/g, '')}*/}
            {/*  prefix={"$"}*/}
            {/*  name="vehicle_value"*/}
            {/*  placeholder="Vehicle Value"*/}
            {/*  onChange={handlePhysicalDamage()}*/}
            {/*  // defaultValue={"$"}*/}
            {/*  // onChnage={handleChange}*/}
            {/*/>*/}
          </Form.Item>
          </Form>
          <h1 style={{ marginTop: "1rem", fontSize: 25, fontWeight: 600 }}>
            Does the truck have any permanently attached equipment?
          </h1>
          <div
            className="textAreasBtn"
            style={{
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <div>
              <Button
                className={
                  (yesButtonClass || showTextArea) === true ? "active" : ""
                }
                onClick={() => {
                  handleYes();
                }}
              >
                Yes
              </Button>

              <Button
                className={noButtonClass === true ? "active" : ""}
                onClick={() => {
                  handleNo();
                }}
              >
                No
              </Button>
              {yesButtonClass && (
                <Button
                  onClick={() => {
                    setEquipments((prev) => {
                      return [...prev, { equipment: "", value: "" }];
                    });
                  }}
                  icon={
                    <RiAddCircleFill
                      style={{ paddingRight: 5, fontSize: 20 }}
                    />
                  }
                >
                  Add
                </Button>
              )}
            </div>
            {showTextArea === true ? (
              <div style={{ marginTop: "1rem", padding: 0, margin: 0 }}>
                {equipments?.map((eqp, index) => {
                  return (
                    <div>
                      <AttachedEquipments
                        equipments={eqp}
                        equipmentsLength={equipments.length}
                        index={index}
                        handleRemoveEquipment={handleRemoveEquipment}
                        handlePhysicalDamage={handlePhysicalDamage}
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </div>
          <h1 style={{ marginTop: "1rem", fontSize: 25, fontWeight: 600 }}>
            Would you like physical damage coverage for this vehicle?
          </h1>
          <div className="inputsBtn" style={{display: 'inline'}}>
            <Button
              className={yesButtonClassOdd === true ? "active" : ""}
              onClick={() => {
                setData({ ...data, coverage_vehicle: 1 });
                setShowSelect(true);
                setYesButtonClassOdd(true);
                setNoButtonClassOdd(false);
              }}
            >
              Yes
            </Button>
            <Button
              className={noButtonClassOdd === true ? "active" : ""}
              onClick={() => {
                setData({ ...data, coverage_vehicle: 0 });
                setShowSelect(false);
                setYesButtonClassOdd(false);
                setNoButtonClassOdd(true);
              }}
            >
              No
            </Button>
            {showSelect === true ? (

              <div >
                <Form.Item
                  label={
                    <span className="labelOdd">
                      What is the comprehensive deductible?
                    </span>
                  }
                  name="ayz"
                  rules={[
                    {
                      required: true,
                      message: "Please input your comprehensive deductible!",
                    },
                  ]}
                >
                  <Select
                      ref={selectRef}
                    style={{ height: "50px", width: "410px" }}
                    name="coverage_vehicle_p1"
                    onChange={handlePhysicalDamage("coverage_vehicle_p1")}
                    // defaultValue="4"
                    placeholder="Select deductible"
                    allowClear
                    defaultValue={data?.coverage_vehicle_p1}
                  >
                    <Option value="0">Not Selected</Option>
                    <Option value="100$">$100</Option>
                    <Option value="250$">$250</Option>
                    <Option value="500$">$500</Option>
                    <Option value="1,000$">$1,000</Option>
                    <Option value="2,500$">$2,500</Option>
                    <Option value="5,000$">$5,000</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label={
                    <span className="labelOdd">
                      What is the collision deductible?
                    </span>
                  }
                  name="zyz"
                  rules={[
                    {
                      required: true,
                      message: "Please input your collision deductible!",
                    },
                  ]}
                >
                  <Select
                    ref={selectRef}
                    style={{ height: "50px", width: "410px" }}
                    name="coverage_vehicle_p2"
                    onChange={handlePhysicalDamage("coverage_vehicle_p2")}
                    placeholder="Select deductible"
                    allowClear
                    defaultValue={data?.coverage_vehicle_p2}
                  >
                    <Option value="0">Not Selected</Option>
                    <Option value="1">$100</Option>
                    <Option value="2">$250</Option>
                    <Option value="3">$500</Option>
                    <Option value="4">$1,000</Option>
                    <Option value="5">$2,500</Option>
                    <Option value="6">$5,000</Option>
                  </Select>
                </Form.Item>
              </div>
            ) : (
              ""
            )}
          </div>
          <Link
            onClick={pushDataToLocalStorage}
            to={(bhysicalOddValueValidator() && "/vmodel") || "#"}
            style={{ marginLeft: 0, marginTop: 60 }}
            className={`nextBtn ${
              !bhysicalOddValueValidator() && "btn-disable"
            }`}
          >
            Next
          </Link>
        </PhysicalArea>
        <span></span>
      </div>
    </Form>
  );
};

export default PhysicalDamageOdd;

const AttachedEquipments = ({
  equipments,
  handleRemoveEquipment,
  index,
  equipmentsLength,
  handlePhysicalDamage,
}) => {
  const { equipment, value } = equipments;
  const inputRef = useRef();
  console.log(value, " value");
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Form
        fields={[
          {
            name: "attach_equipment_p2",
            value,
          },
        ]}
      >
        <Form.Item
          label={
            <span className="labelOdd" style={{ marginBottom: 0 }}>
              What type of permanently attached equipment?
            </span>
          }
          name={"pzm" + index}
          rules={[
            {
              required: true,
              message: "Please input type of permanently attached equipment!",
            },
          ]}
        >
          <Input
            name="attach_equipment_p1"
            onChange={handlePhysicalDamage("equipment", index)}
            placeholder="Type"
            defaultValue={equipment}
          />
        </Form.Item>
        <Form.Item
          label={
            <span className="labelOdd">
              Value of the permanently attached equipment?
            </span>
          }
          // name={"zpm" + index}
          rules={[
            {
              required: true,
              message: "Please input value of permanently attached equipment!",
            },
          ]}
          initialValue="$"
        >
          <InputNumber
              defaultValue={value}
              name="value"
              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              onChange={handlePhysicalDamage("value", index)}
              ref={inputRef}
          />
          {/*<Input*/}
          {/*  prefix="$"*/}
          {/*  name="attach_equipment_p2"*/}
          {/*  onChange={handlePhysicalDamage("value", index)}*/}
          {/*  placeholder="Type"*/}
          {/*  defaultValue={value}*/}
          {/*/>*/}
        </Form.Item>
        {index !== 0 && (
          <Form.Item
            label={
              <span
                style={{ marginLeft: 20, fontWeight: 600 }}
                className="labelOdd"
              >
                Remove
              </span>
            }
            name={"zpm" + index}
            rules={[
              {
                required: true,
                message:
                  "Please input value of permanently attached equipment!",
              },
            ]}
          >
            <Button
              style={{ marginLeft: 20 }}
              onClick={() => handleRemoveEquipment(index)}
            >
              <IoMdRemoveCircle
                style={{
                  textAlign: "center",

                  fontSize: 25,
                  color: "#a20010",
                }}
              />
            </Button>
          </Form.Item>
        )}
      </Form>
    </div>
  );
};
