import React from "react";
import VehicleCard from "./VehicleCard";
const TabBody = (props) => {
  return (
    <div className="tabBody">
      {props.tabBodyData.map((item) => (
        <VehicleCard
          currentVehicle={props.currentVehicle}
          handleChange={props.handleChange}
          data={item}
        />
      ))}
    </div>
  );
};

export default TabBody;
