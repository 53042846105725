import React, { useEffect } from "react";
import { Form, Input, Button } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";

import { BusinessArea } from "../styles/index";
import { useDispatch } from "react-redux/es/exports";
import { Business } from "../action/counterAction";
import apiRequest from "../apiRequest";

const Address = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [location, setLocation] = useState("");
  const [isLocationProvidedByDot, setIsLocationProvidedByDot] = useState(false);

  // street
  const [street, setStreet] = useState("");

  // zip code
  const [zip, setZip] = useState("");

  // city
  const [city, setCity] = useState("");

  // country
  const [country, setContry] = useState("");

  // State
  const [state, setState] = useState("");

  const [noButtonClass, setNoButtonClass] = useState("");
  const [yesButtonClass, setYesButtonClass] = useState("");

  /**
   * Id Location Is Available from dot Api, then display to input field.
   */
  React.useEffect(() => {
    const dot = localStorage.getItem("dot");
    const dotPrased = dot && Object.keys(dot).length && JSON.parse(dot);

    if (dot && Object.values(dotPrased).length) {
      const { phyStreet, phyZipcode, phyCity, phyState, phyCountry } =
        dotPrased;
      const locationFromDot = `${phyStreet} ${phyZipcode} ${phyCity} ${phyState} ${phyCountry}`;
      setStreet(phyStreet);
      setZip(phyZipcode);
      setCity(phyCity);
      setState(phyState);
      setContry(phyCountry);

      setLocation(locationFromDot);
      setIsLocationProvidedByDot(true);
      setData({ business_located: locationFromDot });
    }
  }, []);

  const handleSuggestByGoogle = (address, name) => {
    const { formatted_address } = address;
    console.log(formatted_address);
    setData((prev) => {
      return { [name]: formatted_address };
    });
    setLocation(formatted_address);
    // postData();
  };

  const handleAddress = (e) => {
    const { name, value } = e.target;
    setData((prev) => {
      return { ...prev, [name]: value };
    });
    // setLocation(value);
    // postData();
  };

  const handleData = ({ target: { name, value } }) => {
    setData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const pushDataToLocalStorage = () => {
    let preData = JSON.parse(localStorage.getItem("business"));
    let updatedData = { ...preData, ...data };
    // console.log(updatedData)
    localStorage.setItem("business", JSON.stringify(updatedData));
    dispatch(Business(updatedData));
  };
  function postData() {
    apiRequest({
      method: `post`,
      url: `/dashboard/google_location_data`,
      data: {
        location,
      },
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  /*
   * Address Validator
   * @return {boolean}
   **/
  const addressValidator = () => {
    if (yesButtonClass) {
      return true;
    }
    console.log(yesButtonClass, " button");
    return Object.keys(data).length === 4;
  };

  // stack over flow
  function compIsType(t, s) {
    for (let z = 0; z < t.length; ++z) if (t[z] == s) return true;

    return false;
  }

  function handlePlaceChanged(p) {
    const place = p;
    //console.log(" from google auto complete place===>",place);
    let lat,
      lng,
      addrSel,
      placeName,
      placeId = "";
    let country,
      state,
      city = null;
    if (place.geometry !== undefined) {
      const plcGeom = place.geometry;
      if (plcGeom.location !== undefined) {
        lat = plcGeom.location ? plcGeom.location.lat() : "";
        lng = plcGeom.location ? plcGeom.location.lng() : "";
      }
    }

    addrSel =
      place.formatted_address !== undefined ? place.formatted_address : "";
    placeName = place.name !== undefined ? place.name : "";
    placeId = place.place_id !== undefined ? place.place_id : "";

    if (place.address_components !== undefined) {
      let addrComp = place.address_components;
      for (let i = 0; i < addrComp.length; ++i) {
        var typ = addrComp[i].types;
        if (compIsType(typ, "administrative_area_level_1"))
          state = addrComp[i].long_name; //store the state
        else if (compIsType(typ, "locality"))
          city = addrComp[i].long_name; //store the city
        else if (compIsType(typ, "country")) country = addrComp[i].long_name; //store the country

        //we can break early if we find all three data
        if (state != null && city != null && country != null) break;
      }
    }

    let nameData = "";
    if (this.props.showKey !== undefined) {
      if (this.props.showKey == "CITY") {
        nameData = city;
      } else if (this.props.showKey == "STATE") {
        nameData = state;
      } else if (this.props.showKey == "COUNTRY") {
        nameData = country;
      } else if (this.props.showKey == "PLACE_NAME") {
        nameData = country;
      } else if (this.props.showKey == "FORMATTED_ADDRESS") {
        nameData = addrSel;
      } else if (this.props.showKey == "PLACE_ID") {
        nameData = placeId;
      } else {
        nameData = addrSel;
      }
    } else {
      nameData = addrSel;
    }

    if (
      this.props.removeTextFlag !== undefined &&
      this.props.removeTextFlag == "YES"
    ) {
      nameData = "";
    }

    let stateResp = {
      lat: lat,
      lng: lng,
      formattedAddress: addrSel,
      placeName: placeName,
      placeId: placeId,
      city: city,
      state: state,
      country: country,
      textboxtext: nameData,
    };
    console.log(stateResp, " state Resp ");
    // this.setState(stateResp, () => {
    //   this.props.selectedGoogleDataInfo !== undefined &&
    //     this.props.selectedGoogleDataInfo(stateResp);
    // });
    console.log(place, " place ");
    //console.log("==lat==>", lat," ==lng===>",lng, "==addrSel==>",addrSel);
    //this.props.onPlaceLoaded(place);
  }

  useEffect(() => {
    console.log(data, " data ...");
    // console.log(street, city, state, zip);
  });
  //
  // Populate Previous data if user go back to previous page.
  useEffect(() => {
    const business = localStorage.getItem("business");
    const parsedBusiness = business && JSON.parse(business);
    const { business_city, business_state, business_zip, business_street } =
      parsedBusiness;
    if (business_city && business_state && business_zip && business_street) {
      setData((prev) => {
        return {
          ...prev,
          business_city,
          business_state,
          business_zip,
          business_street,
        };
      });
    }
  }, []);

  return (
    <div className="innerSection">
      <Form
        fields={[
          {
            name: "business_city",
            value: data?.business_city,
          },
          {
            name: "business_state",
            value: data?.business_state,
          },
          {
            name: "business_zip",
            value: data?.business_zip,
          },
        ]}
      >
        <BusinessArea>
          <p style={{ fontSize: 25, fontWeight: 600 }}>
            Where is the business located?
          </p>
          {isLocationProvidedByDot && (
            <>
              <p style={{ fontSize: 27.88 }}>Is this your Business Address?</p>
              <p style={{ fontSize: 20.88 }}>{location}</p>
              <div>
                <Button
                  className={yesButtonClass === true ? "active" : ""}
                  onClick={() => {
                    setData({ ...data, attach_equipment: 1 });
                    setYesButtonClass(true);
                    setNoButtonClass(false);
                  }}
                >
                  Yes
                </Button>
                <Button
                  className={noButtonClass === true ? "active" : ""}
                  onClick={() => {
                    setData({});
                    // setLocation("")
                    setYesButtonClass(false);
                    setNoButtonClass(true);
                  }}
                >
                  No
                </Button>
              </div>
            </>
          )}

          {(!isLocationProvidedByDot || noButtonClass) && (
            <>
              <Form.Item
                className="addressItem"
                name="business_street"
                rules={[
                  {
                    required: !location ? true : false,
                    message: "Please input your business location",
                  },
                ]}
              >
                <label className="labelOdd">Street</label>
                {/*<Input name="business_located" onChange={handleAddress} />*/}
                <Autocomplete
                  defaultValue={data?.business_street}
                  apiKey={"AIzaSyDWym7RhxhsyluE-pcR-cxB_KLRrqgDYWU"}
                  onPlaceSelected={(place) => {
                    handleSuggestByGoogle(place, "business_street");
                  }}
                  // value={location}
                  // defaultValue={location}
                  name="business_street"
                  onChange={handleAddress}
                  options={{
                    types: ["(regions)"],
                    componentRestrictions: { country: "us" },
                  }}
                />
              </Form.Item>
              <div>
                <div className="multipleItems">
                  <Form.Item
                    label={<span className="labelOdd">City</span>}
                    name="business_city"
                    rules={[
                      {
                        required: true,
                        message: "Please input your city.",
                      },
                    ]}
                  >
                    <Input
                      name="business_city"
                      value={data?.business_city}
                      onChange={handleData}
                      placeholder="City"
                      defaultValue={data?.business_city}
                    />
                  </Form.Item>
                  {/* state onChange error */}
                  <Form.Item
                    label={<span className="labelOdd">State</span>}
                    name="business_state"
                    rules={[
                      {
                        required: true,
                        message: "Please input your state.",
                      },
                    ]}
                  >
                    <Input
                      name="business_state"
                      value={data?.business_state}
                      onChange={handleData}
                      placeholder="State"
                      defaultValue={data?.business_state}
                    />
                  </Form.Item>
                  <Form.Item
                    label={<span className="labelOdd">Zip Code</span>}
                    name="business_zip"
                    rules={[
                      {
                        required: true,
                        message: "Please input your zip code.",
                      },
                    ]}
                  >
                    <Input
                      name="business_zip"
                      value={data?.business_zip}
                      onChange={handleData}
                      placeholder="Zip Code"
                      defaultValue={data?.business_zip}
                    />
                  </Form.Item>
                </div>
              </div>
            </>
          )}

          <Link
            onClick={pushDataToLocalStorage}
            to={(addressValidator() && "/customer") || "#"}
            className={`nextBtn ${!addressValidator() && "btn-disable"}`}
            // className="nextBtn"
            style={{ marginLeft: 0, marginTop: 60 }}
          >
            Next
          </Link>
        </BusinessArea>
        <span></span>
      </Form>
    </div>
  );
};

export default Address;
