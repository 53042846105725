import React, { useState, useEffect } from "react";
import { Button, Form, Input, Select } from "antd";
import { VehiclesArea } from "../styles/index";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

const BlanketAdditionalInsurance = () => {
  const [data, setData] = useState({});
  const [vehicleDetails, setVehicleDetails] = useState({});

  // getting data from localstorage which was taken from previous step, by using VIM
  React.useEffect(() => {
    const vehicleData = localStorage.getItem("vehicle");
    const vehicleDataParsed = (vehicleData && JSON.parse(vehicleData)) || {};
    setVehicleDetails(vehicleDataParsed);
  }, []);

  // Display Vehicle Info
  const displayVichleInfo = () => {
    const {
      ["Model Year"]: modelYear,
      Model: model,
      Make: make,
    } = vehicleDetails;

    if (modelYear?.Value && model?.Value && make?.Value) {
      return modelYear?.Value + " " + make?.Value + " " + model?.Value;
    }
  };

  const handleVehicleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    console.log(data);
  };
  const pushDataToLocalStorage = () => {
    let preData = JSON.parse(localStorage.getItem("driver"));
    let updatedData = { ...preData, ...data };
    // console.log(updatedData)
    localStorage.setItem("driver", JSON.stringify(updatedData));
    //  console.log(...preData,newData)
  };

  // Implement style if catagory is in state
  const setActiveOrDisactive = (catagory, value) => {
    return {
      borderColor: data[catagory] === value && "#a20010",
      backgroundColor: data[catagory] === value && "#b57f82",
      color: data[catagory] === value && "#ffffff",
    };
  };

  /*
   * Vehicles Values Validator
   * @return {boolean}
   **/
  const blanketAdditionalValidator = () => {
    return Object.keys(data).length === 4;
  };

  return (
    <div className="innerSectionOdd" style={{ marginTop: "5rem" }}>
      <VehiclesArea>
        <p className="sectionPara">
          Blanket additional insured and waiver of subrogation endorsements
        </p>

        <div className="vehicleButtons">
          <h1 style={{ fontSize: 38.41, marginLeft: 0, color: "#000" }}>
            Is a blanket additional insured endorsement needed by contract?
          </h1>
          <Button
            style={setActiveOrDisactive("is_blanket_additional", 1)}
            onClick={() => {
              setData({ ...data, is_blanket_additional: 1 });
            }}
          >
            Yes
          </Button>
          <Button
            style={setActiveOrDisactive("is_blanket_additional", 0)}
            onClick={() => {
              setData({ ...data, is_blanket_additional: 0 });
            }}
          >
            No
          </Button>
        </div>
        <div className="vehicleButtons">
          <h1
            style={{
              fontSize: 38.41,
              marginLeft: 0,
              color: "#000",
              marginTop: 50,
            }}
          >
            Is a blanket waiver of subrogation endorsement needed by contract?
          </h1>
          <Button
            style={setActiveOrDisactive("is_blanket_waiver", 1)}
            onClick={() => {
              setData({ ...data, is_blanket_waiver: 1 });
            }}
          >
            Yes
          </Button>
          <Button
            style={setActiveOrDisactive("is_blanket_waiver", 0)}
            onClick={() => {
              setData({ ...data, is_blanket_waiver: 0 });
            }}
          >
            No
          </Button>
        </div>

        <div className="vehicleButtons">
          <h1
            style={{
              fontSize: 38.41,
              marginLeft: 0,
              color: "#000",
              marginTop: 50,
            }}
          >
            Are state or federal filings required?
          </h1>
          <Button
            style={setActiveOrDisactive("are_state_or_federal", 1)}
            onClick={() => {
              setData({ ...data, are_state_or_federal: 1 });
            }}
          >
            Yes
          </Button>
          <Button
            style={setActiveOrDisactive("are_state_or_federal", 0)}
            onClick={() => {
              setData({ ...data, are_state_or_federal: 0 });
            }}
          >
            No
          </Button>
        </div>

        <div className="vehicleButtons">
          <h1
            style={{
              fontSize: 38.41,
              marginLeft: 0,
              color: "#000",
              marginTop: 50,
            }}
          >
            This customer is eligible for additional savings and free safety
            scorecards with our Snapshot ProView program. Add ProView to quote?
          </h1>
          <Button
            style={setActiveOrDisactive("customer_is_eligible", 1)}
            onClick={() => {
              setData({ ...data, customer_is_eligible: 1 });
            }}
          >
            Yes
          </Button>
          <Button
            style={setActiveOrDisactive("customer_is_eligible", 0)}
            onClick={() => {
              setData({ ...data, customer_is_eligible: 0 });
            }}
          >
            No
          </Button>
        </div>

        <Link
          onClick={pushDataToLocalStorage}
          to={(blanketAdditionalValidator() && "/drivers-list") || "#"}
          style={{ marginLeft: 0 }}
          className={`nextBtn ${
            !blanketAdditionalValidator() && "btn-disable"
          }`}
        >
          Next
        </Link>
      </VehiclesArea>
      <span></span>
    </div>
  );
};

export default BlanketAdditionalInsurance;
