import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Form, Input, Button } from "antd";
import axios from "axios";

// External Components
import { ButtonsArea } from "../styles/index";
import { UsDotAction } from "../action/counterAction";

const token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2MmY2N2MyYmM3NGI5YzFlMjQ1MWJhZTEiLCJpZCI6IjYyZjY3YzJiYzc0YjljMWUyNDUxYmFlMSIsImlhdCI6MTY2MDMyMDgyMywiZXhwIjoxNjYwODM5MjIzfQ.Tv1wKCk5yJwAn7ccvD_G88lh7ZRgeGAnL_sS8tM_3Ac";

const UsDot = (props) => {
  const dispatch = useDispatch();
  const [showDot, setShowDot] = useState(false);
  const [showNumbar, setShowNumbar] = useState(false);
  const [noButtonClass, setNoButtonClass] = useState(false);
  const [yesButtonClass, setYesButtonClass] = useState(false);
  const [notYetButtonClass, setNotYetButtonClass] = useState(false);

  // Check is Api fetching data
  const [dataFetching, setDataFetching] = React.useState(false);

  const [data, setData] = useState({});

  React.useEffect(() => {
    saveDataToLocalStorage("dot", {});
  }, []);

  useEffect(() => {
    console.log(noButtonClass, " No Button Class");
  });

  /**
   * Getting data from Dot api and save to LocalSotorage.
   * @param {string} keyName - Key Name, where data will be save in localStorage
   * @param {obj} value - Object of values to store
   * */
  const saveDataToLocalStorage = (keyName, obj) => {
    const prevObj = localStorage.getItem("business");
    const parsedPrev = (prevObj && JSON.parse(prevObj)) || {};
    if (keyName !== "dot") {
      localStorage.setItem(keyName, JSON.stringify({ ...parsedPrev, ...obj }));
    } else {
      localStorage.setItem(keyName, JSON.stringify({ ...obj }));
    }
  };

  // Validate is dot number is valid or not
  const DotNumberValidator = () => {
    if (noButtonClass) {
      return (data?.years_in_business && true) || false;
    }
    return (data?.us_dot_number && data?.years_in_business && true) || false;
  };

  // Fetch data by pasing dot number
  function getData() {
    var config = {
      method: "get",
      url:
        "https://mobile.fmcsa.dot.gov/qc/services/carriers/" +
        data?.us_dot_number +
        "?webKey=46683baed566c35088cf6414790d8ae765f3a9db",
      headers: {
        Cookie:
          "AWSALB=Ic+1P7wXJsmAVcZfgSycK1FtB6UBtPXWglD6IADmHbx40IZ6CDwWD+uERGyJdLyrYvlKXHpyTkBMEsGIoyfXQK7TtKWZ5OLIj6tDyNskwUeg3rZFK6BlBOwTxkWz; AWSALBCORS=Ic+1P7wXJsmAVcZfgSycK1FtB6UBtPXWglD6IADmHbx40IZ6CDwWD+uERGyJdLyrYvlKXHpyTkBMEsGIoyfXQK7TtKWZ5OLIj6tDyNskwUeg3rZFK6BlBOwTxkWz",
      },
    };
    return axios(config);
  }

  const usDotHandle = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    const business = data;
    console.log(business);
  };

  useEffect(() =>{
      console.log(data)
  })

  const pushDataToLocalStorage = () => {
    setDataFetching(true);

    const business = localStorage.getItem('business');
    const parsedBusiness = business && JSON.parse(business);

    if (noButtonClass) {
        localStorage.setItem("business", JSON.stringify({ ...parsedBusiness, ...data, us_dot_number: "" }))
        return;
    } else if (notYetButtonClass) {
        localStorage.setItem("business", JSON.stringify({ ...parsedBusiness, us_dot_number: "", years_in_business: "" }));
    }
    saveDataToLocalStorage("business", { ...data });
    DotNumberValidator() &&
      getData()
        .then(function (response) {
          // console.log(response.data, 'data');
          const {
            legalName,
            phyCity,
            phyCountry,
            phyState,
            phyStreet,
            phyZipcode,
          } = response.data.content.carrier;
          saveDataToLocalStorage("dot", {
            legalName,
            phyCity,
            phyCountry,
            phyState,
            phyStreet,
            phyZipcode,
            ...data,
          });
          setTimeout(() => {
            setDataFetching(false);
          }, 1000);
        })
        .catch(function (error) {
          if (noButtonClass) {
            saveDataToLocalStorage("business", { ...data });
          }
        });
  };

  const handleYes = () => {
      setShowDot(true);
      setYesButtonClass(true);
      setNoButtonClass(false);
      setNotYetButtonClass(false);
      setShowNumbar(false);
  }

  const handleNo = () => {
      setShowDot(false);
      setShowNumbar(true);
      setYesButtonClass(false);
      setNoButtonClass(true);
      setNotYetButtonClass(false);

  }

  const handleNotYet = () => {
      setShowDot(false);
      setShowNumbar(false);
      setYesButtonClass(false);
      setNoButtonClass(false);
      setNotYetButtonClass(true);
  }


    // Populate Previous data if user go back to previous page.
    useEffect(() => {
        const business = localStorage.getItem('business');
        const parsedBusiness = business && JSON.parse(business);

        const { years_in_business, us_dot_number } = parsedBusiness;
        if (years_in_business && us_dot_number) {
            handleYes();
            setData(prev => {
                return { ...prev, years_in_business, us_dot_number}
            })
        } else if (years_in_business && !us_dot_number) {
            handleNo();
            setData(prev => {
                return {...prev, years_in_business}
            })
        } else if (!years_in_business, !us_dot_number) {
            handleNotYet();
        }


    }, [])

    const cleanFromLocalStorage = () => {
        const business = localStorage.getItem('business');
        const parsedBusiness = business && JSON.parse(business);

        localStorage.setItem("business", JSON.stringify({ ...parsedBusiness, us_dot_number: "", years_in_business: "" }))
    }


  return (
    <div className="innerSectionOdd" style={{ marginTop: "2rem" }}>
      <p>We’d like to know more about your business.</p>
      <h1 style={{ marginBottom: "1rem", fontWeight: " 600", fontSize: 25 }}>
        Do you have a USDOT #?
      </h1>
        <Form fields={[
            {
                name: 'years_in_business',
                value: data?.years_in_business
            },
            {
                name: 'us_dot_number',
                value: data?.us_dot_number
            }
        ]}>
            <ButtonsArea>
        <Button
          className={yesButtonClass === true ? "active" : ""}
          onClick={() => {
              handleYes();
          }}
        >
          Yes
        </Button>
        <Button
          className={noButtonClass === true ? "active" : ""}
          onClick={() => {
            handleNo();
          }}
        >
          No
        </Button>
        <Button
          className={notYetButtonClass === true ? "active" : ""}
          onClick={() => {
            handleNotYet();
          }}
        >
          Not yet
        </Button>
        {showDot ? (
          <>
            <Form.Item
              label="DOT Number"
              name="us_dot_number"
              rules={[
                {
                  required: true,
                  message: "Please input your DOT Number!",
                },
              ]}
              style={{ marginTop: "2rem" }}
            >
              <Input
                defaultValue=""
                placeholder="Dot number"
                name="us_dot_number"
                onChange={usDotHandle}
              />
            </Form.Item>
            <Form.Item
              label={"Number of years in business?"}
              name="years_in_business"
              rules={[
                {
                  required: true,
                  message: "Please input your Number of years",
                },
              ]}
            >
              {/* number of years in your business is missing in api*/}
              <Input
                defaultValue=""
                placeholder="Number of years"
                name="years_in_business"
                onChange={usDotHandle}
              />
            </Form.Item>
          </>
        ) : (
          ""
        )}

        {showNumbar === true ? (

          <Form.Item
            label="Number of years in business?"
            name="years_in_business"
            rules={[
              {
                required: true,
                message: "Please input your Number of years",
              },
            ]}
            style={{ marginTop: "2rem" }}
            //  number of years in your business is missing in api
          >
            <Input
              placeholder="Number of years"
              name="years_in_business"
              onChange={usDotHandle}
            />
          </Form.Item>
        ) : (
          ""
        )}

        {dataFetching && <p>Data fetching...</p>}
        <Link
          onClick={!notYetButtonClass && pushDataToLocalStorage || cleanFromLocalStorage}
          to={
            ((notYetButtonClass || DotNumberValidator()) && "/entitytype") ||
            "#"
          }
          className={`nextBtn ${
            ((!notYetButtonClass && !DotNumberValidator()) || dataFetching) &&
            "btn-disable"
          }`}
          style={{
            marginLeft: 0,
            marginTop: "3rem",
            pointerEvents:
              (((!notYetButtonClass && !DotNumberValidator()) ||
                dataFetching) &&
                "none") ||
              "",
          }}
          disabled={
            (!notYetButtonClass && !DotNumberValidator()) || dataFetching
          }
        >
          Next
        </Link>
      </ButtonsArea>
        </Form>
      <span></span>
    </div>
  );
};

export default UsDot;
