import React, { useState, useEffect } from "react";
import { Form, DatePicker, Radio, Space } from "antd";
import { CoverArea } from "../styles/index";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { StartCoverage } from "../action/counterAction";
import moment from "moment";

const CoverageStart = (props) => {
  const dispatch = useDispatch();
  const [radio, setRadio] = useState("");

  const [data, setData] = useState({});

  const coverageStartHandle = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    const business = data;
  };
  const pushDataToLocalStorage = () => {
    let preData = JSON.parse(localStorage.getItem("business"));
    let updatedData = { ...preData, ...data };
    // console.log(updatedData)
    localStorage.setItem("business", JSON.stringify(updatedData));
    //  console.log(...preData,newData)
  };

  const onFinish = (values) => {
    // console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const onChange = (e) => {
    if (e.target.value === 1) {
      setData({ coverage_start: "as soon as possible" });
    }
    setRadio(e.target.value);
  };
  const dateFormat = "MM/DD/YYYY";

  /*
   * Coverage Values Selection Validator
   * @return {boolean}
   **/
  const coverageValidator = () => {
    return data?.coverage_start && true;
  };

  useEffect(() => {
    console.log(coverageValidator());
  });

  // Populate Previous data if user go back to previous page.
  useEffect(() => {
    const business = localStorage.getItem('business');
    const parsedBusiness = business && JSON.parse(business);
    const { coverage_start } = parsedBusiness;

    if (coverage_start === "as soon as possible") {
      setRadio(1)
    } else if (coverage_start) {
      setRadio(2)
      setData(prev => {
        return {...prev, coverage_start}
      })

    }

    setData(prev => {
      return { ...prev, ...parsedBusiness}
    })

  }, [])

  return (
    <>
      <Form>
        <div className="innerSection">
        <div style={{ marginTop: "2rem" }}>
          <Form.Item
            label={
              <p style={{ fontSize: "25.7px", fontWeight: 600 }}>
                When would you like coverage to start?
              </p>
            }
            name="coverage_start"
            rules={[
              {
                required: true,
                message: "Please input your coverage!",
              },
            ]}
          >
            <CoverArea>
              <Radio.Group onChange={onChange} value={radio} >
                <Space direction="vertical">
                  <Radio value={1}>As soon as possible</Radio>
                  <Radio value={2}>
                    On my renewal date
                    {radio === 2 ? (
                      <Form.Item>
                        <DatePicker
                          name="coverage_start"
                          onBlur={coverageStartHandle}
                          value={props.date}
                          placeholder="MM/DD/YYYY"
                          format={dateFormat}
                          defaultValue={data?.coverage_start !== 'as soon as possible' && moment(data?.coverage_start)}
                        />
                      </Form.Item>
                    ) : null}
                  </Radio>
                </Space>
              </Radio.Group>
            </CoverArea>
            <Link
              style={{ marginLeft: 0, marginTop: 60 }}
              onClick={pushDataToLocalStorage}
              to={(coverageValidator() && "/usdot") || "#"}
              className={`nextBtn ${!coverageValidator() && "btn-disable"}`}
            >
              Next
            </Link>
          </Form.Item>
          <span></span>
        </div>
      </div>
      </Form>
    </>
  );
};

export default CoverageStart;
