import React, {useState, useEffect, useRef} from "react";
import { Form, Input, AutoComplete } from "antd";
import { HomeArea } from "../styles/index";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Business_Description } from "../action/counterAction";

const BUSINESS_TYPE = [
  { value: "contractor", text: "Contractor" },
  { value: "hotshot", text: "Hotshot" },
  { value: "Small Parcel Delivery", text: "Small Parcel Delivery" },
  { value: "towing", text: "Towing" },
  { value: "trucking", text: "Trucking" },
  { value: "other", text: "Other" },
];

const DescribeBusiness = (props) => {
  const dispatch = useDispatch();
  const [autoCompleteResult, setAutoCompleteResult] = useState([]);
  const [data, setData] = useState({});

  const inputRef = useRef();

  const handleBusinessDescription = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  useEffect(() => {
    console.log(data, " data ");
  });

  const pushDataToLocalStorage = () => {
    let preData = JSON.parse(localStorage.getItem("business"));
    let updatedData = { ...preData, ...data };
    // console.log(updatedData)
    localStorage.setItem("business", JSON.stringify(updatedData));
    //  console.log(...preData,newData)
  };
  const onWebsiteChange = (value) => {
    setData((prev) => {
      return { ...prev, describe_your_business: value };
    });
    // props.setInput(value);
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(
        [
          "Commercial ",
          "Independent ",
          "Interstate ",
          "Leased-on ",
          "Over the Road ",
        ].map((domain) => `${domain}${value}`)
      );
    }
  };

  const websiteOptions = autoCompleteResult.map((website) => ({
    label: website,
    value: website,
  }));

  /*
   * Describe Business Validator
   * @return {boolean}
   **/
  const businessTypeValidator = () => {
    return Object.keys(data).length && data?.describe_your_business;
  };


  // Populate Previous data if user go back to previous page.
  useEffect(() => {
    const business = localStorage.getItem('business');
    const parsedBusiness = business && JSON.parse(business);
    const { describe_your_business } = parsedBusiness;
    setData(prev => {
      return { ...prev, describe_your_business }
    })

  }, [])


  return (
      <Form fields={[
        {
          name: 'describe_your_business',
          value: data?.describe_your_business

        }
      ]}>
        <div className="innerSection">
      {/* <p className="title">Welcome</p> */}
      <Form.Item
        label={
          <p style={{ fontSize: "25", fontWeight: 600, padding: 0 }}>
            Please describe your business.
          </p>
        }
        name="describe_your_business
"
        rules={[
          {
            required: true,
            message: "Please input your username!",
          },
        ]}
      >
        <HomeArea>
          <AutoComplete
            value={props.input}
            options={BUSINESS_TYPE}
            onChange={onWebsiteChange}
            defaultValue={data?.describe_your_business}
            ref={inputRef}
          >
            <Form.Item name="describe_your_business">
            <Input
                style={{marginTop: '11px'}}
              suffix={<FaSearch />}
              name="describe_your_business"
              onChange={handleBusinessDescription}
              placeholder="Describe Your Business"
            />
            </Form.Item>
          </AutoComplete>
        </HomeArea>
        <Link
          onClick={pushDataToLocalStorage}
          to={(businessTypeValidator() && "/currentinsurance") || "#"}
          className={`nextBtn ${!businessTypeValidator() && "btn-disable"}`}
          style={{ marginLeft: 0, marginTop: 60 }}
        >
          Next
        </Link>
      </Form.Item>
    </div>
      </Form>
  );
};

export default DescribeBusiness;
