import React from "react";
import { Button } from "antd";
import { ProductArea } from "../styles/index";

const Product = () => {
  return (
    <div className="innerSectionOdd">
      <h3 className="sectionHeading">2012 AIRSTREAM 390 XL</h3>
      <ProductArea>
        <h1>
          Which product category best describes the cargo transported by the
          vehicle?
        </h1>
        <div className="productButtons">
          <div>
            <Button>Autos, RVs, trailers and boats</Button>
            <Button>Garbage or refuse</Button>
            <Button>Gas, oil and energy related products</Button>
            <Button>Household goods/moving operations</Button>
            <Button>Logs and pulpwood</Button>
            <Button>Coal</Button>
            <Button>Hazardous materials</Button>
          </div>
          <div>
            <Button>Intermodal</Button>
            <Button>Mobile or modular homes</Button>
            <Button>Sand and gravel</Button>
            <Button>None of the above</Button>
          </div>
        </div>
      </ProductArea>
    </div>
  );
};

export default Product;
