import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import { FaSearch } from "react-icons/fa";
import { ProductArea, HomeArea } from "../styles/index";
import { Link } from "react-router-dom";
import { Odd_Product } from "../action/counterAction";
import { useDispatch } from "react-redux/es/exports";
import { Prev } from "react-bootstrap/esm/PageItem";

// All catagories.
const CATAGORY_ENUM = {
  GENERAL_FIGHTER: "generalFighter",
  GARBAGE_OR_REFUSE: "garbageOrRefuse",
  HOUSEHOLD: "household",
  HAZARDOUS: "hazardous",
  AUTOS: "autos",
  INTERMODAL: "intermodal",
  MOBILE_OR_MODULAR: "mobileOrModular",
  SAND_AND_GRAVEL: "sandAndGravel",
  LARGE_MACHINERYRAL: "largeMachineryral",
};

// details against catagories.
const catagoryDetails = {
  generalFighter: "General Freigh",
  garbageOrRefuse: "Garbage or refuse",
  household: "Household goods/moving operations",
  hazardous: "Hazardous materials",
  autos: "Autos, RVs, trailers and boats",
  intermodal: "Intermodal",
  mobileOrModular: "Mobile or modular homes",
  sandAndGravel: "Sand and gravel",
  largeMachineryral: "Large Machineryral Freight",
};

const ProductOdd = () => {
  const dispatch = useDispatch();

  const [toggle, setToggle] = useState(false);
  const [data, setData] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ data, [name]: value });
  };

  const pushDataToLocalStorage = () => {
    let preData = JSON.parse(localStorage.getItem("customer"));
    let updatedData = { ...preData, ...data };
    localStorage.setItem("customer", JSON.stringify(updatedData));
  };
  const handleClick = () => {
    setToggle(false);
  };
  const onNoVehicleClick = () => {
    setData("");
    setToggle(true);
  };

  // Set selected catagory to state
  const handleSelectCatagory = (catagory, value) => {
    setData(() => {
      return { [catagory]: value };
    });
  };

  // Implement style if catagory is in state
  const setActiveOrDisactive = (catagory, value) => {
    return {
      borderColor: data[catagory] === value && "#a20010",
      backgroundColor: data[catagory] === value && "#b57f82",
      color: data[catagory] === value && "#ffffff",
    };
  };

  return (
    <div className="innerSectionOdd">
      <h3 className="sectionHeading">2012 AIRSTREAM 390 XL</h3>
      <ProductArea>
        <h1>
          Which product category best describes the cargo transported by the
          vehicle?
        </h1>
        <div className="productButtons">
          <div>
            <Button
              style={setActiveOrDisactive(
                CATAGORY_ENUM.GENERAL_FIGHTER,
                catagoryDetails[CATAGORY_ENUM.GENERAL_FIGHTER]
              )}
              onClick={() => {
                handleSelectCatagory(
                  CATAGORY_ENUM.GENERAL_FIGHTER,
                  catagoryDetails[CATAGORY_ENUM.GENERAL_FIGHTER]
                );
              }}
            >
              General Freight
            </Button>
            <Button
              style={setActiveOrDisactive(
                CATAGORY_ENUM.GARBAGE_OR_REFUSE,
                catagoryDetails[CATAGORY_ENUM.GARBAGE_OR_REFUSE]
              )}
              onClick={() => {
                handleSelectCatagory(
                  CATAGORY_ENUM.GARBAGE_OR_REFUSE,
                  catagoryDetails[CATAGORY_ENUM.GARBAGE_OR_REFUSE]
                );
              }}
            >
              Garbage or refuse
            </Button>
            <Button
              style={setActiveOrDisactive(
                CATAGORY_ENUM.HOUSEHOLD,
                catagoryDetails[CATAGORY_ENUM.HOUSEHOLD]
              )}
              onClick={() => {
                handleSelectCatagory(
                  CATAGORY_ENUM.HOUSEHOLD,
                  catagoryDetails[CATAGORY_ENUM.HOUSEHOLD]
                );
              }}
            >
              Household goods/moving operations
            </Button>
            <Button
              style={setActiveOrDisactive(
                CATAGORY_ENUM.HAZARDOUS,
                catagoryDetails[CATAGORY_ENUM.HAZARDOUS]
              )}
              onClick={() => {
                handleSelectCatagory(
                  CATAGORY_ENUM.HAZARDOUS,
                  catagoryDetails[CATAGORY_ENUM.HAZARDOUS]
                );
              }}
            >
              Hazardous materials
            </Button>
            <Button
              style={setActiveOrDisactive(
                CATAGORY_ENUM.AUTOS,
                catagoryDetails[CATAGORY_ENUM.AUTOS]
              )}
              onClick={() => {
                handleSelectCatagory(
                  CATAGORY_ENUM.AUTOS,
                  catagoryDetails[CATAGORY_ENUM.AUTOS]
                );
              }}
            >
              Autos, RVs, trailers and boats
            </Button>
          </div>
          <div>
            <Button
              style={setActiveOrDisactive(
                CATAGORY_ENUM.INTERMODAL,
                catagoryDetails[CATAGORY_ENUM.INTERMODAL]
              )}
              onClick={() => {
                handleSelectCatagory(
                  CATAGORY_ENUM.INTERMODAL,
                  catagoryDetails[CATAGORY_ENUM.INTERMODAL]
                );
              }}
            >
              Intermodal
            </Button>
            <Button
              style={setActiveOrDisactive(
                CATAGORY_ENUM.MOBILE_OR_MODULAR,
                catagoryDetails[CATAGORY_ENUM.MOBILE_OR_MODULAR]
              )}
              onClick={() => {
                handleSelectCatagory(
                  CATAGORY_ENUM.MOBILE_OR_MODULAR,
                  catagoryDetails[CATAGORY_ENUM.MOBILE_OR_MODULAR]
                );
              }}
            >
              Mobile or modular homes
            </Button>
            <Button
              style={setActiveOrDisactive(
                CATAGORY_ENUM.SAND_AND_GRAVEL,
                catagoryDetails[CATAGORY_ENUM.SAND_AND_GRAVEL]
              )}
              onClick={() => {
                handleSelectCatagory(
                  CATAGORY_ENUM.SAND_AND_GRAVEL,
                  catagoryDetails[CATAGORY_ENUM.SAND_AND_GRAVEL]
                );
              }}
            >
              Sand and gravel
            </Button>
            <Button
              style={setActiveOrDisactive(
                CATAGORY_ENUM.LARGE_MACHINERYRAL,
                catagoryDetails[CATAGORY_ENUM.LARGE_MACHINERYRAL]
              )}
              onClick={() => {
                handleSelectCatagory(
                  CATAGORY_ENUM.LARGE_MACHINERYRAL,
                  catagoryDetails[CATAGORY_ENUM.LARGE_MACHINERYRAL]
                );
              }}
            >
              Large Machinery
            </Button>
          </div>
        </div>
        <div className="otherProducts">
          <Button
            className={!Object.keys(data).length ? "active" : ""}
            onClick={onNoVehicleClick}
          >
            None of the above
          </Button>
          {toggle === true ? (
            <Form.Item
              label=""
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input products!",
                },
              ]}
            >
              <HomeArea>
                <Input
                  prefix="Other"
                  suffix={<FaSearch />}
                  name="other"
                  value={data?.other}
                  onChange={handleChange}
                />
              </HomeArea>
            </Form.Item>
          ) : (
            ""
          )}
        </div>
        <Link
          onClick={pushDataToLocalStorage}
          to="/vehicletabs"
          className="nextBtn"
        >
          Next
        </Link>
      </ProductArea>
      <span></span>
    </div>
  );
};

export default ProductOdd;
