import React, { useEffect, useRef } from "react";
import { Button, DatePicker, Form, Input, Select, Checkbox } from "antd";
import { useNavigate } from "react-router-dom";
import { DriversArea } from "../styles";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Driver_Info } from "../action/counterAction";
import { useDispatch } from "react-redux";
import moment from "moment";
import {toast, ToastContainer} from "react-toastify";

// USA STATES
const STATES = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

const Drivers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [yesButtonClass, setYesButtonClass] = useState(false);
  const [noButtonClass, setNoButtonClass] = useState(false);
  const [isDriverLicenceValid, setIsDriverLicenceValid] = useState(false);

  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];

  const selectRef = useRef(null);

  const handleDrivers = (e) => {
    const { name, value } = e.target;


    if (name === 'driver_licence') {
      validateLicenseNumber(value);
    }

    setData({ ...data, [name]: value });
    // console.log(data)
  };
  const pushDataToLocalStorage = () => {
    localStorage.setItem("driver", JSON.stringify(data));
  };
  const dateFormat = "MM/DD/YYYY";
  const { Option } = Select;

  const createItemsInLocalStorage = (itemName, data) => {
    localStorage.setItem(itemName, JSON.stringify(data));
  };

  /** Initialize SelectedDrivers Array */
  const initializeDriversArray = () => {
    const selectedDriver = localStorage.getItem("selectedDrivers");
    const countSelectedDrivers = localStorage.getItem("countSelectedDrivers");

    !selectedDriver && createItemsInLocalStorage("selectedDrivers", []);
    !countSelectedDrivers && localStorage.setItem("countSelectedDrivers", 0);
  };

  useEffect(() => {
    initializeDriversArray();
  }, []);

  useEffect(() => {
    const addNewDriver = localStorage.getItem("addNewDriver");
    const parsedAddNewDriver = addNewDriver && JSON.parse(addNewDriver);

    if (!parsedAddNewDriver) {
      const driver = localStorage.getItem("driver");
      const customer = localStorage.getItem("customer");

      const parsedDriver = driver && JSON.parse(driver);
      const parsedCustomer = customer && JSON.parse(customer);

      const {
        customer_first_name,
        customer_last_name,
        customer_m_i,
        customer_dob,
        customer_state,
      } = parsedCustomer || {};

      customer && setData((prev) => {
        return {
          ...prev,
          driver_first_name: customer_first_name,
          driver_last_name: customer_last_name,
          driver_m_i: customer_m_i,
          driver_dob: customer_dob,
          driver_state: customer_state,
        };
      });

      const { driver_exclude } = parsedDriver || {};
      if (driver_exclude == "0") {
        setNoButtonClass(true);
      } else if (driver_exclude) {
        setYesButtonClass(true);
      }
      console.log(driver_exclude, " EXCLUDE");
      driver &&
        setData((prev) => {
          return {
            ...parsedDriver,
            driver_first_name: customer_first_name,
            driver_last_name: customer_last_name,
            driver_m_i: customer_m_i,
            driver_dob: customer_dob,
            driver_state: customer_state,
          };
        });
    }
  }, []);


  useEffect(() => {
    console.log(data)
  })

  const [valid, setValid] = useState(true);

  useEffect(() => {
    console.log(valid, ' valid')
  })

  /** Validate is driver which same license exist or not
   *  @return {boolean}
   **/
  const validateLicenseNumber = (licence) => {

        const selectedDrivers = localStorage.getItem('selectedDrivers');
        const parsedSelectedDrivers = selectedDrivers && JSON.parse(selectedDrivers) || [];
        parsedSelectedDrivers.map(driver => {
          const { driver_licence } = driver;
          if (licence === driver_licence) {
            setValid(false)
            notify("License Number Should be Unique");
          } else {
            setValid(true);
          }
        });

    //   const selectedDrivers = localStorage.getItem('selectedDrivers');
    //   const parsedSelectedDrivers = selectedDrivers && JSON.parse(selectedDrivers);
    //   parsedSelectedDrivers.map(driver => {
    //     const { driver_licence } = driver;
    //     licence === driver_licence && alert("alert")
    // });

  }

  /**
   * display notification
   * @param {string} message - message recevied from api response
   */
  const notify = (message) => {
    toast.error(message);
  };



  /*
   * Driver Values Validator
   * @return {boolean}
   **/
  const driverValidator = () => {
    return valid && Object.keys(data).length >= 6 && data?.driver_licence && (data?.driver_exclude === 0 || data?.driver_exclude);
  };

  useEffect(() => {
    console.log(data);
  });

  return (
    <Form
      name="form_name"
      fields={[
        {
          name: ["driver_first_name"],
          value: data?.driver_first_name,
        },
        {
          name: ["driver_last_name"],
          value: data?.driver_last_name,
        },
        {
          name: ["driver_m_i"],
          value: data?.driver_m_i,
        },
        {
          name: ["driver_licence"],
          value: data?.driver_licence,
        },
        {
          name: ["driver_state"],
          value: data?.driver_state,
        },
      ]}
    >
      <div className="innerSectionOdd" style={{ marginTop: "4rem" }}>
        <ToastContainer />
        <h3 className="sectionHeading" style={{ fontSize: "1rem" }}>
          Now, let’s talk about drivers.
        </h3>
        <DriversArea>
          <div className="driverName" style={{ marginTop: "2rem" }}>
            <h1 style={{ fontSize: 25, fontWeight: 600 }}>
              What is the driver’s name and birth date?
            </h1>
            <div className="nameInputs">
              <Form.Item
                label={<span className="labelOdd">First Name</span>}
                name="driver_first_name"
                rules={[
                  {
                    required: true,
                    message: "please input your first name!",
                  },
                ]}
              >
                <Input
                  name="driver_first_name"
                  onChange={handleDrivers}
                  defaultValue={data?.driver_first_name}
                />
              </Form.Item>
              <Form.Item
                label={<span className="labelOdd">M.I.</span>}
                name="driver_m_i"
                // rules={[
                //   {
                //     required: true,
                //     message: "m.i.!",
                //   },
                // ]}
              >
                <Input
                  name="driver_m_i"
                  onChange={handleDrivers}
                  // defaultValue={data?.driver_m_i}
                />
              </Form.Item>
              <Form.Item
                label={<span className="labelOdd">Last Name</span>}
                name="driver_last_name"
                rules={[
                  {
                    required: true,
                    message: "please input your last name!",
                  },
                ]}
              >
                <Input name="driver_last_name" onChange={handleDrivers} />
              </Form.Item>
              <Form.Item
                label={<span className="labelOdd">Date of Birth</span>}
                name="driver_dob"
                rules={[
                  {
                    required: true,
                    message: "please input your date of birth!",
                  },
                ]}
              >
                {console.log(data?.driver_dob)}
                <DatePicker
                  value={data?.driver_dob && moment(data?.driver_dob)}
                  format={dateFormat}
                  placeholder="MM/DD/YYYY"
                  name="driver_dob"
                  onBlur={handleDrivers}
                />
              </Form.Item>
            </div>
          </div>
          <h1 style={{ fontSize: 25, fontWeight: 600, marginTop: "1rem" }}>
            What is the driver’s license number and state?
          </h1>
          <div className="licenseInputs">
            <Form.Item
              label={<span className="labelOdd">License Number</span>}
              name="driver_licence"
              rules={[
                {
                  required: true,
                  message: "please input your license number!",
                },
              ]}
            >
              <Input
                placeholder="Enter Driver License Number"
                name="driver_licence"
                onChange={handleDrivers}
              />
            </Form.Item>
            <Form.Item
              label={<span className="labelOdd">State</span>}
              name="driver_state"
              rules={[
                {
                  required: true,
                  message: "Please input your state!",
                },
              ]}
            >
              <Select
                style={{ height: "50px", width: "120px" }}
                allowClear
                name="driver_state"
                onChange={(value) =>
                  setData((prev) => {
                    selectRef.current.blur();
                    return { ...prev, driver_state: value };
                  })
                }
                ref={selectRef}
              >
                {STATES.map((s) => {
                  return <Option value={s}>{s}</Option>;
                })}
              </Select>
            </Form.Item>
          </div>

          {/* Button Section */}
          <h1 style={{ fontSize: 25, fontWeight: 600, marginTop: "1rem" }}>
            Exclude this driver from the policy?
          </h1>
          <div className="licenseInputs">
            <Form.Item
              name="lll"
              rules={[
                {
                  required: true,
                  message: "please input your license number!",
                },
              ]}
            >
              <Button
                className={yesButtonClass === true ? "active" : ""}
                onClick={() => {
                  setData((prev) => {
                    return { ...prev, driver_exclude: 1 };
                  });
                  setYesButtonClass(true);
                  setNoButtonClass(false);
                }}
              >
                Yes
              </Button>
            </Form.Item>
            <Form.Item
              name="mmm"
              rules={[
                {
                  required: true,
                  message: "Please input your state!",
                },
              ]}
            >
              {" "}
              <Button
                className={noButtonClass === true ? "active" : ""}
                onClick={() => {
                  setData((prev) => {
                    return { ...prev, driver_exclude: 0 };
                  });
                  setYesButtonClass(false);
                  setNoButtonClass(true);
                }}
              >
                No
              </Button>
            </Form.Item>
          </div>

          <Link
            style={{ marginTop: 20, marginLeft: 0 }}
            onClick={pushDataToLocalStorage}
            // to="/accidents"
            to={(driverValidator() && "/drivers-list") || "#"}
            className={`nextBtn ${!driverValidator() && "btn-disable"}`}
          >
            Next
          </Link>
        </DriversArea>
        <span></span>
      </div>
    </Form>
  );
};

export default Drivers;
