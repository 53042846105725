// Tucks Area Data
export const TrucksAreaData = [
  {
    image: "FoodTruck.svg",
    text: "Food Truck",
  },
  {
    image: "GarbageTruck.svg",
    text: "Garbage Truck",
  },
  {
    image: "IceCreamTruck.svg",
    text: "Ice Cream Truck",
  },
  {
    image: "MobileCrane.svg",
    text: "Mobile Crane",
  },
  {
    image: "RefrigeratedTruck.svg",
    text: "Refrigerated Truck",
  },
  {
    image: "SnowPlow.svg",
    text: "Snow Plow",
  },
  {
    image: "StakeTruck.svg",
    text: "Stake Truck",
  },
  {
    image: "StreetSweeperorCleaner.svg",
    text: "Street Sweeper or Cleaner",
  },
  {
    image: "TowTruck-Non-Tiltbed.svg",
    text: "Tow Truck - Non-Tiltbed",
  },
  {
    image: "TowTruck-Tiltbed.svg",
    text: "Tow Truck - Tiltbed",
  },

  //
  {
    image: "DumpTruck.svg",
    text: "Dump Truck",
  },
  {
    image: "Tractor.svg",
    text: "Tractor",
  },
  {
    image: "RollOffVehicle.svg",
    text: "Roll Off Vehicle",
  },
  {
    image: "FlatbedTruck.svg",
    text: "Flatbed Truck",
  },
  {
    image: "TankTruck.svg",
    text: "Tank Truck",
  },
  {
    image: "CementMixer.svg",
    text: "Cement Mixer",
  },
  {
    image: "StraightorBoxTruck.svg",
    text: "Straight or BoxTruck",
  },
  {
    image: "BoomTruck.svg",
    text: "Boom Truck",
  },
  {
    image: "BucketTruck.svg",
    text: "Bucket Truck",
  },
  {
    image: "CargoVan.svg",
    text: "Cargo Van",
  },
  {
    image: "CementPumperTruck.svg",
    text: "Cement Pumper Truck",
  },
  {
    image: "DeliveryorStepVan.svg",
    text: "Delivery or Step Van",
  },
];
// Trailers Area Data
export const TrailersAreaData = [
  {
    image: "AllOtherTrailers.svg",
    text: "All Other Trailers",
  },
  {
    image: "AutoHaulingTrailer.svg",
    text: "Auto Hauling Trailer",
  },
  {
    image: "BellyDumpTrailer.svg",
    text: "Belly Dump Trailer",
  },
  {
    image: "BulkCommodityTrailer.svg",
    text: "Bulk Commodity Trailer",
  },
  {
    image: "DryVanTrailer.svg",
    text: "Dry Van Trailer",
  },
  {
    image: "EndDumpTrailer.svg",
    text: "End Dump Trailer",
  },
  {
    image: "FlatbedTrailer.svg",
    text: "Flatbed Trailer",
  },
  {
    image: "GooseneckTrailer.svg",
    text: "Gooseneck Trailer",
  },
  {
    image: "HopperBottomTrailer.svg",
    text: "Hopper Bottom Trailer",
  },
  {
    image: "HorseTrailer1-2Stalls.svg",
    text: "Horse Trailer 1-2 Stalls",
  },
  {
    image: "IntermodalChassis.svg",
    text: "Intermodal Chassis",
  },
  {
    image: "Other.svg",
    text: "Other",
  },
  {
    image: "LivestockTrailer.svg",
    text: "Livestock Trailer",
  },
  {
    image: "LivestockTrailer2.svg",
    text: "Livestock Trailer",
  },
  {
    image: "LowboyTrailer.svg",
    text: "Lowboy Trailer",
  },
  {
    image: "PoleTrailer.svg",
    text: "Pole Trailer",
  },
  {
    image: "RagTopTrailer.svg",
    text: "Rag Top Trailer",
  },
  {
    image: "RefrigeratedTrailer.svg",
    text: "Refrigerated Trailer",
  },
  {
    image: "SideDumpTrailer.svg",
    text: "Side Dump Trailer",
  },
  {
    image: "TankTrailer.svg",
    text: "Tank Trailer",
  },
  {
    image: "RagTopTrailer2.svg",
    text: "Rag Top Trailer",
  },
  {
    image: "UtilityTrailerupto2000lbs.svg",
    text: "Utility Trailer up to 2000 lbs",
  },
  {
    image: "WedgeTrailer.svg",
    text: "Wedge Trailer",
  },
];
// All Area Data
export const AllAreaData = [
  {
    image: "truck4.svg",
    text: "Tractor",
  },
  {
    image: "truck2.svg",
    text: "Straight or Box Truck",
  },
  {
    image: "pickup.svg",
    text: "Pickup",
  },
  {
    image: "truck3.svg",
    text: "Cargo Van",
  },
  {
    image: "truck1.svg",
    text: "Flatbed Truck",
  },
  {
    image: "truck2.svg",
    text: "Dump Truck",
  },
  {
    image: "truck3.svg",
    text: "Delivery or Step Van",
  },
  {
    image: "sedan.svg",
    text: "Sedan",
  },
  {
    image: "truck4.svg",
    text: "Tractor",
  },
  {
    image: "truck3.svg",
    text: "Straight or Box Truck",
  },
  {
    image: "truck2.svg",
    text: "Cargo Van",
  },
  {
    image: "truck1.svg",
    text: "Flatbed Truck",
  },
  {
    image: "truck1.svg",
    text: "Dump Truck",
  },
  {
    image: "truck2.svg",
    text: "Delivery or Step Van",
  },
  {
    image: "truck3.svg",
    text: "Boom Truck",
  },
  {
    image: "truck4.svg",
    text: "Bucket Truck",
  },
  {
    image: "truck4.svg",
    text: "Cement Mixer",
  },
  {
    image: "truck3.svg",
    text: "Cement Pumper Truck",
  },
  {
    image: "truck2.svg",
    text: "Food Truck",
  },
  {
    image: "truck1.svg",
    text: "Garbage Truck",
  },
  {
    image: "pickup.svg",
    text: "Pickup",
  },
  {
    image: "sedan.svg",
    text: "Sedan",
  },
  {
    image: "pickup.svg",
    text: "Minivan",
  },
  {
    image: "sportsCar.svg",
    text: "Sports Car",
  },
  {
    image: "pickup.svg",
    text: "Sport Utility Vehicle",
  },
  {
    image: "truck4.svg",
    text: "Luxury Motor Coach",
  },
  {
    image: "truck3.svg",
    text: "Passenger Bus",
  },
  {
    image: "truck2.svg",
    text: "School Bus",
  },
  {
    image: "truck1.svg",
    text: "Tram",
  },
  {
    image: "truck1.svg",
    text: "Trolley",
  },
  {
    image: "truck3.svg",
    text: "Van",
  },
  {
    image: "truck4.svg",
    text: "Luxury Motor Coach",
  },
  {
    image: "truck3.svg",
    text: "Passenger Bus",
  },
  {
    image: "truck2.svg",
    text: "School Bus",
  },
  {
    image: "truck1.svg",
    text: "Tram",
  },
  {
    image: "truck1.svg",
    text: "Trolley",
  },
  {
    image: "truck3.svg",
    text: "Van",
  },
  {
    image: "truck4.svg",
    text: "Luxury Motor Coach",
  },
  {
    image: "truck3.svg",
    text: "Passenger Bus",
  },
  {
    image: "truck2.svg",
    text: "School Bus",
  },
  {
    image: "truck1.svg",
    text: "Tram",
  },
  {
    image: "truck1.svg",
    text: "Trolley",
  },
  {
    image: "truck3.svg",
    text: "Van",
  },
  {
    image: "truck4.svg",
    text: "Luxury Motor Coach",
  },
  {
    image: "truck3.svg",
    text: "Passenger Bus",
  },
  {
    image: "truck2.svg",
    text: "School Bus",
  },
  {
    image: "truck1.svg",
    text: "Tram",
  },
  {
    image: "truck1.svg",
    text: "Trolley",
  },
  {
    image: "truck3.svg",
    text: "Van",
  },
  {
    image: "truck4.svg",
    text: "Tractor",
  },
  {
    image: "truck3.svg",
    text: "Straight or Box Truck",
  },
  {
    image: "truck2.svg",
    text: "Cargo Van",
  },
  {
    image: "truck1.svg",
    text: "Flatbed Truck",
  },
  {
    image: "truck1.svg",
    text: "Dump Truck",
  },
  {
    image: "truck2.svg",
    text: "Delivery or Step Van",
  },
  {
    image: "truck3.svg",
    text: "Boom Truck",
  },
  {
    image: "truck4.svg",
    text: "Bucket Truck",
  },
  {
    image: "truck4.svg",
    text: "Cement Mixer",
  },
  {
    image: "truck3.svg",
    text: "Cement Pumper Truck",
  },
  {
    image: "truck2.svg",
    text: "Food Truck",
  },
  {
    image: "truck1.svg",
    text: "Garbage Truck",
  },
  {
    image: "truck4.svg",
    text: "Tractor",
  },
  {
    image: "truck3.svg",
    text: "Straight or Box Truck",
  },
  {
    image: "truck2.svg",
    text: "Cargo Van",
  },
  {
    image: "truck1.svg",
    text: "Flatbed Truck",
  },
  {
    image: "truck1.svg",
    text: "Dump Truck",
  },
  {
    image: "truck2.svg",
    text: "Delivery or Step Van",
  },
  {
    image: "truck3.svg",
    text: "Boom Truck",
  },
  {
    image: "truck4.svg",
    text: "Bucket Truck",
  },
  {
    image: "truck4.svg",
    text: "Cement Mixer",
  },
  {
    image: "truck3.svg",
    text: "Cement Pumper Truck",
  },
  {
    image: "truck2.svg",
    text: "Food Truck",
  },
  {
    image: "truck1.svg",
    text: "Garbage Truck",
  },
];
