import { Form, Select } from "antd";
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { LimitFormArea } from "../styles";
import { Trailer_Limits } from "../action/counterAction";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux/es/exports";
const TrailerLimits = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [vehicleDetails, setVehicleDetails] = useState({});
  const handleTrailerLimits = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    // console.log(data)
  };
  const pushDataToLocalStorage = () => {
    let preData = JSON.parse(localStorage.getItem("coverage"));
    let updatedData = { ...preData, ...data };
    // console.log(updatedData)
    localStorage.setItem("coverage", JSON.stringify(updatedData));
    //  console.log(...preData,newData)
  };
  const { option } = Select;

  // getting data from localstorage which was taken from previous step, by using VIM
  useEffect(() => {
    const vehicleData = localStorage.getItem("vehicle");
    const vehicleDataParsed = (vehicleData && JSON.parse(vehicleData)) || {};
    setVehicleDetails(vehicleDataParsed);
  }, []);

  // Display Vehicle Info
  const displayVichleInfo = () => {
    const {
      ["Model Year"]: modelYear,
      Model: model,
      Make: make,
    } = vehicleDetails;

    if (modelYear?.Value && model?.Value && make?.Value) {
      return modelYear?.Value + " " + make?.Value + " " + model?.Value;
    }
  };

  /*
   * current Insurance Values Validator
   * @return {boolean}
   **/
  const trailerLimitsValidator = () => {
    return Object.keys(data).length === 7;
  };

  return (
    <div className="innerSectionOdd" style={{ marginTop: "5rem" }}>
      <h3 className="sectionHeading" style={{ fontSize: "27.32px" }}>
        Coverages for the vehicles
      </h3>
      <LimitFormArea style={{ marginTop: "5rem" }}>
        <Row
          className="align-items-center"
          style={{ alignItems: "end", position: "relative" }}
        >
          <Col md={3} className="coverageInputs px-3">
            <div>
              <img src="/images/truck4.svg" alt="" />
            </div>
            <h6 style={{ fontSize: 17.27, fontWeight: 700 }}>Coverage</h6>
            <Form.Item>
              <p>Comprehensive</p>
            </Form.Item>
            <Form.Item>
              <p>Collision</p>
            </Form.Item>
            <Form.Item>
              <p>Medical Payments</p>
            </Form.Item>
            <Form.Item>
              <p>Rental Reimbursement</p>
            </Form.Item>
            <Form.Item>
              <p>Rental with Downtime</p>
            </Form.Item>
            <Form.Item>
              <p>Roadside Assistance</p>
            </Form.Item>
            <Form.Item>
              <p>Fire and theft w/ combined additional coverage</p>
            </Form.Item>
          </Col>
          <Col md={8} className="limitInputs">
            <h4>2012 AIRSTREAM 390 XL</h4>
            <h6>Limit</h6>
            <Form.Item>
              <select
                name="comprehensive"
                onChange={handleTrailerLimits}
                className="NewSelect"
                defaultValue="Not Selected"
                allowClear
              >
                <option value="CSL">CSL</option>
                <option value="SPLIT">SPLIT</option>
                <option value="Non Trucking">Non Trucking</option>
                <option value="Not Selected">Not Selected</option>
              </select>
            </Form.Item>
            <Form.Item>
              <select
                name="collision"
                onChange={handleTrailerLimits}
                className="NewSelect"
                defaultValue="Not Selected"
                allowClear
              >
                <option value="CSL">CSL</option>
                <option value="SPLIT">SPLIT</option>
                <option value="Non Trucking">Non Trucking</option>
                <option value="Not Selected">Not Selected</option>
              </select>
            </Form.Item>
            <Form.Item>
              <select
                name="medical_payments"
                onChange={handleTrailerLimits}
                className="NewSelect"
                defaultValue="Not Selected"
                allowClear
              >
                <option value="CSL">CSL</option>
                <option value="SPLIT">SPLIT</option>
                <option value="Non Trucking">Non Trucking</option>
                <option value="Not Selected">Not Selected</option>
              </select>
            </Form.Item>
            <Form.Item>
              <select
                name="rental_Reimbursement"
                onChange={handleTrailerLimits}
                className="NewSelect"
                defaultValue="Not Selected"
                allowClear
              >
                <option value="CSL">CSL</option>
                <option value="SPLIT">SPLIT</option>
                <option value="Non Trucking">Non Trucking</option>
                <option value="Not Selected">Not Selected</option>
              </select>
            </Form.Item>
            <Form.Item>
              <select
                name="rental_with_downtime"
                onChange={handleTrailerLimits}
                className="NewSelect"
                defaultValue="Not Selected"
                allowClear
              >
                <option value="CSL">CSL</option>
                <option value="SPLIT">SPLIT</option>
                <option value="Non Trucking">Non Trucking</option>
                <option value="Not Selected">Not Selected</option>
              </select>
            </Form.Item>
            <Form.Item>
              <select
                name="roadside_assistance"
                onChange={handleTrailerLimits}
                className="NewSelect"
                defaultValue="Not Selected"
                allowClear
              >
                <option value="CSL">CSL</option>
                <option value="SPLIT">SPLIT</option>
                <option value="Non Trucking">Non Trucking</option>
                <option value="Not Selected">Not Selected</option>
              </select>
            </Form.Item>
            <Form.Item>
              <select
                name="combined_additional"
                onChange={handleTrailerLimits}
                className="NewSelect"
                defaultValue="Not Selected"
                allowClear
              >
                <option value="CSL">CSL</option>
                <option value="SPLIT">SPLIT</option>
                <option value="Non Trucking">Non Trucking</option>
                <option value="Not Selected">Not Selected</option>
              </select>
            </Form.Item>
          </Col>
        </Row>
      </LimitFormArea>
      <Link
        onClick={pushDataToLocalStorage}
        to={(trailerLimitsValidator() && "/coverageslimit") || "#"}
        style={{ marginLeft: 0 }}
        className={`nextBtn ${!trailerLimitsValidator() && "btn-disable"}`}
      >
        Next
      </Link>
      <span></span>
    </div>
  );
};

export default TrailerLimits;
