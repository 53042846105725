import React, { useEffect, useState } from "react";
import { BsCheckCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Navigate } from "../styles/index";

const StepsVelidation = {
  steps: {
    business: [26],
    customer: [8, 10, 9, 11],
    vehicle: [16, 12],
    driver: [6, 7],
    coverage: [24, 10, 15, 6, 7],
  },
};

function validateStep(length) {
  console.log(length);
  return this.length === length;
}

const NavigationContent = ({ sidebar, setSidebar }) => {
  const [completedSteps, setCompletedSteps] = useState({});
  const [isBusinessCompled, setIsBusinessCompleted] = useState(false);
  const [isCustomerCompleted, setIsCustomerCompleted] = useState(false);
  const [isVehicleCompleted, setIsVehicleCompleted] = useState(false);
  const [isDriverCompleted, setIsDriverCompleted] = useState(false);
  const [isCoverageCompleted, setIsCoverageCompleted] = useState(false);
  /**
   *  Create Completed Steps.
   **/

  const checkIsSectionCompleted = () => {
    StepsVelidation.steps.forEach((step) => {
      const { name } = step;
      const property = localStorage.getItem(name);

      const parsedProperty = (property && JSON.parse(property)) || {};
      Object.keys(parsedProperty).length &&
        validateStep.call(step, Object.keys(parsedProperty).length) &&
        setCompletedSteps((prev) => {
          return { ...prev, [name]: true };
        });
    });
  };

  const checkIsJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    for (let key in localStorage) {
      const p = localStorage?.getItem(key);

      if (key !== "debug" && checkIsJson(p)) {
        const p = localStorage?.getItem(key);
        const pp = (p && JSON.parse(p)) || {};

        if (
          key === "business" &&
          StepsVelidation.steps.business.includes(Object.keys(pp).length) &&
          !isBusinessCompled
        ) {
          setIsBusinessCompleted(true);
        }
        if (
          key === "customer" &&
          StepsVelidation.steps.customer.includes(Object.keys(pp).length) &&
          !isCustomerCompleted
        ) {
          setIsCustomerCompleted(true);
        }

        if (
          key === "vehicle" &&
          StepsVelidation.steps.vehicle.includes(Object.keys(pp).length) &&
          !isVehicleCompleted
        ) {
          setIsVehicleCompleted(true);
        }
        if (
          key === "driver" &&
          StepsVelidation.steps.driver.includes(Object.keys(pp).length) &&
          !isDriverCompleted
        ) {
          setIsDriverCompleted(true);
        }
        if (
          key === "coverage" &&
          StepsVelidation.steps.coverage.includes(Object.keys(pp).length) &&
          !isCoverageCompleted
        ) {
          setIsCoverageCompleted(true);
        }
      }
    }
  });

  return (
    <Navigate>
      <div className="NavigationItem">
        <div>
          <img src="/images/office.svg" alt="click here" />
          <Link to="/">Business</Link>
        </div>
        {isBusinessCompled && <BsCheckCircle style={{ marginRight: 20 }} />}
      </div>
      <div className="NavigationItem">
        <div>
          <img src="/images/customer.svg" alt="click here" />
          <Link to="/customer">Customer</Link>
        </div>
        {isCustomerCompleted && <BsCheckCircle style={{ marginRight: 20 }} />}
      </div>
      <div className="NavigationItem">
        <div>
          <img src="/images/car.svg" alt="click here" />
          <Link to="/vehicletabs">Vehicles</Link>
        </div>
        {isVehicleCompleted && <BsCheckCircle style={{ marginRight: 20 }} />}
      </div>
      <div className="NavigationItem">
        <div>
          <img src="/images/user.svg" alt="click here" />
          <Link to="/driver">Drivers</Link>
        </div>
        {isDriverCompleted && <BsCheckCircle style={{ marginRight: 20 }} />}
      </div>
      <div className="NavigationItem">
        <div>
          <img src="/images/coverage.svg" alt="click here" />
          <Link to="/coverageslimit">Coverage</Link>
        </div>
        {isCoverageCompleted && <BsCheckCircle style={{ marginRight: 20 }} />}
      </div>
    </Navigate>
  );
};

export default NavigationContent;
