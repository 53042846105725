import CryptoJS from 'crypto-js'

export const haveExpired = date => {
  const expDate = new Date(date)
  const currentDate = new Date()
  console.log(expDate.getTime() >= currentDate.getTime(), ' ><')
  return expDate.getTime() >= currentDate.getTime()
}

/** Validate current URL
 *  @param {String} url
 */
export const validateUrl = (url, callback) => {
  console.log(url, ' url...')
  const arr = url.split('/')
  if (arr.length > 2) {
    const exprDate = arr[1].replaceAll('buga', '/')
    const email = arr[2]
    var bytes = CryptoJS.AES.decrypt(exprDate.toString(), 'expDate')
    var plaintext = bytes.toString(CryptoJS.enc.Utf8)
    callback(haveExpired(JSON.parse(plaintext)), email)
  }
}
