import styled from "styled-components";

// SideBar Open
export const Sidebar = styled.div`
  background-color: #fcfcfc;
  width: 380px;
  padding: 1rem 1rem;
  height: 100%;
  transition: 300ms;
  min-height: 100vh;
  .logoBrand {
    padding: 1.5rem;
    width: 250px;
    img {
      width: 100%;
    }
  }
  .progressLine {
    margin: 1rem 0;
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background-color: #000000;
    }
  }
  p {
    font-size: 1.25rem;
    color: #343434;
  }
  .hideBtn {
    border: none;
    background-color: #fcfcfc;
    margin: 1rem 0;
    font-size: 1.125rem;
    img {
      margin-right: 0.5rem;
    }
  }
  @media (max-width: 768px) {
    & {
      padding: 1rem 0.5rem;
    }
  }
`;
export const Navigate = styled.div`
  .NavigationItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 2rem 0;
    font-size: 1.125rem;
    cursor: pointer;
    img {
      display: inline-block;
      width: 27px;
      height: auto;
      margin-right: 2rem;
      margin-bottom: 1rem;
    }
    a {
      margin-bottom: 1rem;
      color: #343434;
      font-size: 1.125rem;
      text-decoration: none;
      &:hover {
        color: #343434;
      }
    }
    .navigateList {
      p {
        font-size: 0.875rem;
        div {
          width: 6px;
          height: 6px;
          background-color: #000000;
          display: inline-block;
          border-radius: 50%;
          margin: 0 0.25rem;
          vertical-align: middle;
        }
        span {
          color: #000000;
        }
      }
    }
  }

  @media (max-width: 992px) {
    a {
      margin-bottom: 1rem;

      font-size: 1rem;
    }
    p {
      font-size: 0.9rem;
    }
    span {
      font-size: 0.9rem;
    }
  }
  @media (max-width: 768px) {
    & {
      .NavigationItem {
        margin: 1rem 0 !important;
        a {
          margin-bottom: 0.5rem;
          color: #343434;
          font-size: 0.9rem;
        }
      }
    }
  }
`;
// SideBar Close

// Home Content Open
export const HomeContent = styled.div`
  .container {
    padding-right: 0;
    max-width: 1600px;
  }
  display: flex;
  height: 100%;
  overflow-x: hide;
  position: relative;

  .SidbarContent {
    max-width: 383px;
  }
  .MainContent {
    width: 100%;
    padding: 2rem 0;

    .title {
      font-size: 32px;
      margin-bottom: 0.5rem;
    }
    @media (max-width: 992px) {
      padding: 1rem 0;
      .title {
        font-size: 24px;
      }
    }
    @media (max-width: 768px) {
      padding: 3rem 0 3rem 0;
      .title {
        font-size: 16px;
      }
    }
  }
  .ant-form {
    .innerSection {
      margin-top: 3rem;
      @media (max-width: 768px) {
        margin-top: 1.5rem;
      }
    }
    .innerSectionOdd {
      color: #353434;
      .sectionPara {
        font-size: 1.6875rem;
        margin: 1.5rem 0 2rem;
      }
      .sectionHeading {
        margin: 1.5rem 0 1rem;
        font-size: 40px;
      }
      h5 {
        margin: 8rem 0 2rem 0;
        font-size: 1rem !important;
      }
      @media (max-width: 992px) {
        h3 {
          font-size: 1.3rem;
        }
        .sectionPara {
          font-size: 1.3rem;
          margin-bottom: 0.5rem;
        }
        .sectionHeading {
          margin: 1.5rem 0 3rem;
        }
      }
      @media (max-width: 768px) {
        h3 {
          font-size: 1rem !important;
          font-weight: 500;
        }
        h5 {
          margin: 2rem 0;
          font-size: 1rem !important;
          font-weight: 500;
        }
        .sectionPara {
          font-size: 1rem;
          margin-bottom: 0.5rem;
        }
        .sectionHeading {
          margin: 1.5rem 0 2rem;
        }
      }
    }
    .innerSection,
    .innerSectionOdd {
      padding: 0 1.5rem;
      button {
        padding: 0.75rem 1.25rem;
        height: 100%;
        border: 1px solid #c4c4c4;
        color: #5f6062;
        font-size: 1rem;
        margin: 0.5rem 0;
        margin-right: 0.375rem;
        &:hover {
          border-color: #a20010;
          border: 2px solid #a20010;
        }
        &:focus {
          /* border: 2px solid #a20010; */
        }
        &:hover,
        {
          background-color: #b57f82;
          color: #ffffff;
        }
        
      }
      button.active {
        border: 2px solid #a20010 !important;
        background-color: #b57f82 !important;
        color: #ffffff !important;
      }
      @media (max-width: 768px) {
        &{
          padding: 0 0.3rem;
          padding-right:1rem ;
        }
      }
    }
    label,
    h1 {
      font-size: 1.475rem;
      font-weight:400;
      @media (max-width: 992px) {
        & {
          font-size: 1.5rem;
        }
      }
      @media (max-width: 768px) {
        & {
          font-size: 1rem;
        }
      }
      &::before {
        content: "" !important;
      }
    }
    input {
      box-shadow: none !important;
      &:focus {
        border-color: unset !important;
      }
    }
    .ant-picker {
      border: 1px solid #a20010 !important;
      box-shadow: none;
      padding-left: 3rem;
      .ant-picker-input {
        position: unset;
        input {
          font-size: 1rem;
          border: none !important;
        }
        .ant-picker-suffix,
        .ant-picker-clear {
          margin-left: 1rem;
          font-size: 1.25rem;
          position: absolute;
          left: 0;
          width: 20px;
        }
      }
    }
    input,
    .ant-input-affix-wrapper,
    .ant-picker,
    .ant-select {
      width: 415px;
      height: 55px;
      @media (max-width: 768px) {
        & {
          width: 100% !important;
          height: 40px;
        }
      }
    }
    input,
    .ant-input-affix-wrapper,
    .ant-picker,
    .ant-select {
      border: 1px solid #e0e0e0 !important;
      &:hover,
      &:focus {
        border: 1.5px solid #a20010 !important;
      }
    }
    .ant-select {
      box-shadow: none !important;
      border: none !important;
      &:hover,
      &:focus {
        border: none !important;
      }
      .ant-select-selector {
        border: 1px solid #e0e0e0;
        height: 100%;
        input {
          height: 100%;
          box-shadow: none;
        }
        &:hover,
        &:focus {
          border-color: #a20010 !important;
          box-shadow: none !important;
          outline: 0 !important;
        }
        .ant-select-selection-item {
          line-height: 3.5;
        }
      }
    }
    @media (max-width:768px) {
      label {
        font-size: 0.9rem;
      }
    }
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    box-shadow: none;
    border-color: #a20010;
    border-right-width: 2px;
  }
  .ant-select {
    .ant-input-suffix {
      margin-left: auto;
    }
    input {
      width: 100% !important;
      height: 100% !important;
      border: none !important;
      &:focus,
      &:hover {
        border: none !important;
      }
    }
    .ant-select-arrow,
    .ant-select-clear {
      width: 20px;
      height: 20px;
      font-size: 1.25rem;
      top: 44%;
    }
    @media (max-width: 768px) {
      .ant-select-arrow {
        width: 10px;
        height: 18px;
        font-size: unset;x
        top: 44%;
      }
    }
    .ant-select-clear {
      top: 35%;
    }
  }
  .ant-input-affix-wrapper {
    input {
      width: 100%;
      height: 100%;
      border: none !important;
      &:hover,
      &:focus {
        border: none !important;
      }
    }
  }
  .ant-select {
    .ant-select-selection-placeholder {
      line-height: 3.5;
    }
    @media (max-width: 768px) {
      .ant-select-selection-placeholder {
        font-size: 1rem !important;
        line-height: 2.7;
      }
    }
  }
  .labelOdd {
    font-size: 1rem !important;
    color: #343434 !important;
    display: block;
    
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #a20010;
      border-color: #a20010;
    }
  }
  .ant-checkbox-checked::after {
    border-color: #a20010;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #a20010;
  }
  .ant-input-number {
    width: 415px;
    height: 55px;
    line-height: 3.7;
    border: 1px solid #e0e0e0;
    &:hover,
    &:focus {
      border: 1px solid #a20010;
      box-shadow: none !important;
    }
    input {
      width: 100%;
      height: 100%;
      border: none !important;
      &:hover,
      &:focus {
        border: none !important;
      }
    }
    @media (max-width: 768px) {
      & {
        width: 100% !important;
        height: 40px;
        line-height: 2.7;
      }
    }
  }
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: none;
  }
`;
// Home Content Close

// Previous Button Open
export const PrevBtn = styled.button`
  border: 0;
  background-color: unset;
  font-size: 1.125rem;
  img {
    margin-right: 0.25rem;
  }
`;
// Previous Button Close

// Home Area Open
export const HomeArea = styled.div`
  .ant-select {
    width: 592px;
    height: 55px;
    .ant-select-selector {
      width: 100%;
      height: 100%;
      &:hover,
      &:focus {
        border-color: #a20010 !important;
      }
      .ant-input-affix-wrapper {
        width: 100% !important;
        height: 100% !important;
        border: none !important;
        svg {
          width: 22px;
          height: 22px;
        }
        @media (max-width: 768px) {
          svg {
            width: 14px;
            height: 22px;
          }
        }
      }
    }
    @media (max-width: 768px) {
      & {
        width: 100% !important;
        height: 40px;
      }
    }
  }
`;
// Home Area Close

// Cover Area Open
export const CoverArea = styled.div`
  label {
    font-size: 1.25rem !important;
  }
  .ant-radio-wrapper {
    color: #949494;
    .ant-radio-inner {
      width: 20px;
      height: 20px;
      border-color: #a20010 !important;
      &:after {
        width: 24px;
        height: 24px;
        top: 28%;
        left: 30%;
        background-color: #949494;
      }
    }
    .ant-radio-input:focus + .ant-radio-inner {
      box-shadow: none;
    }
  }
  .ant-radio-wrapper-checked {
    color: #000000;
  }
  @media (max-width: 768px) {
    label {
      font-size: 0.9rem !important;
    }
  }
`;
// Cover Area Close

// Buttons Area Open
export const ButtonsArea = styled.div`
  .insuranceYears {
    margin: 2rem 0;
  }
  .ant-picker {
    input {
      &:hover,
      &:focus {
        border: none !important;
      }
    }
  }
  .authority {
    button {
      width: 350px;
      height: 60px;
      display: block;
      margin-bottom: 0.5rem;
      @media (max-width: 768px) {
        width: 250px !important;
        height: 40px;
      }
    }
  }
  .repossession {
    margin-bottom: 1rem;
  }
`;
// Buttons Area Close

// DotArea Area Open
export const DotArea = styled.div`
  .ant-form-item:first-child {
    margin-bottom: 3rem;
  }
  input {
    width: 415px;
    height: 55px;
    @media (max-width: 768px) {
      & {
        width: 100%px !important;
        height: 40px;
      }
    }
  }
`;
// DotArea Area Close

// Business Area Open
export const BusinessArea = styled.div`
  input {
    width: 415px;
    height: 55px;
    box-shadow: none;
    font-size: 1.25rem;
    @media (max-width: 768px) {
      & {
        width: 100%px !important;
        height: 100%;
        font-size: 0.9rem;
      }
    }
  }
  .ant-input-affix-wrapper {
    width: 415px;
    svg {
      font-size: 1.5rem;
    }
    @media (max-width: 768px) {
      & {
        width: 100%px !important;
      }
    }
  }
  .ant-form-item:nth-child(3) {
    .ant-select {
      width: 500px;
      height: 55px;
      border: 1px solid #a20010 !important;
      &:hover,
      &focus {
        .ant-select-selector {
          border-color: #a20010 !important;
        }
      }
      .ant-select-selector {
        height: 100%;
        input {
          height: 100%;
          box-shadow: none;
        }
        .ant-select-selection-item {
          line-height: 4;
        }
      }
      .ant-select-arrow {
        display: none;
      }
      @media (max-width: 768px) {
        & {
          width: 100% !important;
          height: 40px;
        }
      }
    }
  }
  .multipleItems {
    display: flex;
    align-items: center;
    label {
      font-size: 1rem;
      color: #343434;
    }
    input {
      box-shadow: none;
      border-color: #e0e0e0 !important;
    }
    .ant-form-item:first-child {
      margin-right: 0.75rem;
      input {
        width: 239px;
      }
    }
    .ant-form-item:nth-child(2) {
      .ant-select {
        width: 118px;
        height: 55px;
        border-color: #e0e0e0 !important;
        .ant-select-selector {
          height: 100%;
          input {
            height: 100%;
          }
          .ant-select-selection-item {
            line-height: 4;
          }
        }
      }
    }
    .ant-form-item:last-child {
      margin-left: 0.75rem;
      input {
        width: 114px;
        height: 55px;
        border: 1px solid #e0e0e0 !important;
      }
    }
    @media (max-width: 768px) {
      margin-left: 0rem;
      display: block;
      .ant-form-item:nth-child(2) {
        margin-left: 0rem;
        .ant-select {
          height: 40px;
        }
      }
      .ant-form-item:last-child {
        margin-left: 0rem;
        input {
          height: 40px;
        }
      }
    }
  }
  button {
    padding: 0.75rem 1rem;
    height: 100%;
    border: 2px solid #c4c4c4;
    color: #5f6062;
    font-size: 1rem;
    margin-right: 0.375rem;
    margin-bottom: 0.75rem;
    &:hover,
    &:focus {
      border-color: #a20010;
      background-color: #b57f82;
      color: #ffffff;
    }
    .active {
      border-color: #a20010;
      background-color: #b57f82;
      color: #ffffff;
    }
  }
  .addressItem {
    display: flex;
    align-items: baseline;
    input {
      border: 1px solid #a20010;
      &:focus {
        border-color: #a20010 !important;
      }
    }
    a {
      text-decoration: none;
      color: #343434;
      font-size: 1.25rem;
      vertical-align: super;
      margin-left: 1rem;
      /* img {
        width: 36px;
        height: 55px;
        margin-right: 0.5rem;
      } */
    }
    @media (max-width: 768px) {
      a {
        font-size: 0.8rem;
        margin-left: 0rem;
        margin-top: 1rem;
      }
    }
  }
  .nameInputs {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    .ant-form-item:first-child {
      .ant-input-affix-wrapper {
        width: 284px;
        height: 60px;
        border: 1px solid #a20010;
      }
    }
    .ant-form-item:nth-child(2) {
      margin: 0 0.75rem 1.5rem;
      input {
        width: 48px;
        height: 60px;
      }
    }
    .ant-form-item:last-child {
      input {
        width: 286px;
        height: 60px;
      }
    }
    @media (max-width: 768px) {
      display: block !important;
      .ant-form-item:first-child {
        .ant-input-affix-wrapper {
          height: 40px;
        }
      }
      .ant-form-item:nth-child(2) {
        margin: 0.75rem 0;
        input {
          height: 40px;
        }
      }
      .ant-form-item:last-child {
        margin: 0.75rem 0;
        input {
          height: 40px;
        }
      }
    }
  }
  .checkBox {
    margin: 2.5rem 0;
    label {
      display: inline-flex;
    }
    .ant-checkbox-inner {
      border-color: #c4c4c4;
      box-shadow: none;
    }
    input {
      box-shadow: none;
      outline: none;
    }
  }
  .streetInput {
    input {
      width: 500px;
    }
  }
  @media (max-width: 768px) {
    & {
      .nameInputs {
        display: block !important;
        flex-direction: column !improtant;
      }
      .streetInput {
        input {
          width: 100% !important;
        }
      }
    }
  }
`;
// Business Area Close

// Owner Area Open
export const OwnerArea = styled.div`
  .nameInputs {
    margin-bottom: 0;
  }
  .datePicker {
    margin-bottom: 0.75rem;
    display: flex;
    .ant-picker {
      border: 1px solid #e0e0e0 !important;
      width: 243px !important;
    }
    @media (max-width: 768px) {
      display: block;
      .ant-picker {
        width: 100% !important;
      }
    }
  }
  .emailInput {
    margin-left: 0.7rem;
    input {
      width: 386px !important;
    }
    @media (max-width: 768px) {
      margin-left: 0rem;
      input {
        width: 100% !important;
      }
    }
  }
  .streetInput {
    a {
      text-decoration: none;
      color: #343434;
      margin-left: 0.7rem;
      font-size: 20px;
    }
    @media (max-width: 768px) {
      a {
        font-size: 0.8rem;
        margin-left: 0rem;
      }
    }
  }
  .checkBox {
    margin: 1.5rem 0 0.5rem;
  }
`;
// Owner Area Close

// Insurance Area Open
export const InsuranceArea = styled.div`
  h1 {
    font-size: 2.275rem;
    font-weight: 400 !important;
    @media (max-width: 992px) {
      font-size: 1.5rem;
    }
    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
  .authority {
    margin: 1.5rem 0 0rem;
    p {
      color: #343434;
    }
    button {
      width: 350px;
      height: 60px;
      text-align: center;
      border: 1px solid #c4c4c4;
      display: block;
      margin-bottom: 0.5rem;
      &:hover,
      &:focus {
        background-color: #b57f82;
        border: 1px solid #a20010;
        color: #ffffff;
      }
      @media (max-width: 768px) {
        width: 250px !important;
        height: 40px;
      }
    }
  }
  .ant-input-number {
    width: 415px;
    @media (max-width: 768px) {
      & {
        width: 100%px !important;
        height: 40px;
      }
    }
  }
  .numberText {
    font-size: 0.875rem;
    color: #666666;
    margin-left: 1rem;
  }
  @media (max-width: 992px) {
    p {
      font-size: 0.9rem;
    }
    span {
      font-size: 0.8rem;
    }
  }
  @media (max-width: 768px) {
    p {
      font-size: 0.9rem;
    }
    span {
      font-size: 0.8rem;
    }
  }
`;
// Insurance Area Close

// Vehicles Area Open
export const VehiclesArea = styled.div`
  .ant-form-item:nth-child(2) {
    margin-bottom: 3rem;
  }
  .ant-form-item:nth-child(5) {
    margin-bottom: 2.5rem;
  }
  .vehicleButtons {
    /* margin-bottom: 2rem; */
  }
  .ant-form-item {
    p {
      font-size: 0.75rem;
      color: #666666;
      margin-left: 1rem;
      margin-bottom: 0;
    }
  }
  .vinLink {
    color: #313131;
    font-size: 1rem;
  }
`;
// Vehicles Area Close

// Vehicle Odd Area Open
export const VehicleOddArea = styled.div`
  button {
    margin-bottom: 2rem;
  }
  h3 {
    font-size: 1.7rem;
    font-weight: 500;
    margin-top: 1.5rem;
  }
  @media (max-width: 768px) {
    & {
      button {
        margin-bottom: 2rem;
      }
      h3 {
        font-size: 1rem;
        margin-top: 1rem;
      }
    }
  }
`;
// Vehicle Odd Area Close

// Vehicle Body Area Open
export const VehicleBodyArea = styled.div`
  .ant-tabs {
    width: 100%;
    max-width: 75%;
    .ant-tabs-content-holder {
      height: 530px;
      overflow-y: auto;
      border: 0;
      &::-webkit-scrollbar {
        width: 12.5px;
      }
      &::-webkit-scrollbar-track {
        background: #ffffff;
        border-radius: 50%;
      }
      &::-webkit-scrollbar-thumb {
        background: #888;
      }
      &::-webkit-scrollbar-thumb:hover {
        // background: #ffffff;
      }
    }
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }
  .ant-tabs-tab {
    padding: 0.75rem 1.5rem;
  }
  .ant-tabs-tab-btn {
    font-size: 1rem;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 7px;
    background: #a20010;
  }
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: 1px solid #a20010;
    bottom: 3px;
    width: 100%;
    max-width: 852px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs-tab:hover {
    color: #a20010;
  }
  .tabBody {
    display: flex;
    flex-wrap: wrap;
  }
`;
// Vehicle Body Area Close

// Vehicle Card Area Open
export const VehicleCardArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 214px;
  height: 164px;
  border: 1px solid #c4c4c3;
  text-align: center;
  margin: 0 0.5rem 0.75rem 0;
  cursor: pointer;
  &:hover,
  &:focus {
    border-color: #a20010;
    /* background-color: #b57f82; */
    p {
      /* color: #ffffff; */
    }
    img {
      /* filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(67deg) */
      /* brightness(500%) contrast(102%); */
    }
  }
  img {
    margin-bottom: 0.25rem;
  }
  p {
    margin-bottom: 0;
    color: #5f6062;
  }

  @media (max-width: 768px) {
    & {
      width: 100px;
      height: 85px;
      p {
        font-size: 0.7rem;
      }
      img {
        width: 40px;
      }
    }
  }
  .vehicleSelected {
    border-color: #a20010;
    background-color: #b57f82;
  }
`;
// Vehicle Card Area Close

// Product Area Open
export const ProductArea = styled.div`
  button {
    width: 478px;
    height: 60px !important;
    display: block;
    margin-right: 0.7rem !important;
    margin-bottom: 0.375rem;
    &:hover,
    &:focus {
      /* border: 3px solid #a20010 !important; */
    }
  }
  .productButtons {
    display: flex;
  }
  .otherProducts {
    margin-top: 3.75rem;
    display: flex;
    .ant-form-item {
      margin-top: 8px;
      margin-bottom: 0px !important;
    }
    .ant-input-affix-wrapper {
      width: 370px;
      height: 60px;
      margin-left: 0.75rem;
    }
  }
  @media (max-width: 768px) {
    & {
      button {
        width: 300px;
        height: 40px !important;
        display: block;
        margin-right: 0 !important;
        margin-bottom: 0.375rem;
        padding: 0rem 0rem !important;
        &:hover,
        &:focus {
          /* border: 1px solid #a20010 !important; */
        }
      }
      .productButtons {
        display: block;
      }
    }
  }
`;
// Product Area Close

// Physical Area Open
export const PhysicalArea = styled.div`
  .ant-select-arrow,
  .ant-select-clear {
    width: 14px;
    height: 14px;
    font-size: 14px;
  }
  .ant-select-clear {
    top: 44%;
  }
  .permanentPhysical {
    display: flex;
    align-items: center;
    .ant-form-item:last-child {
      .ant-input-affix-wrapper {
        width: 350px !important;
        margin-left: 1rem;
        .ant-input-prefix,
        .ant-input-suffix {
          font-size: 1.125rem;
        }
      }
    }
  }
  .inputsBtn {
    display: flex;
    align-items: center;
    button {
      margin-top: 0.75rem;
    }
    div {
      .ant-form-item:first-child {
        margin-left: 1.5rem;
        margin-right: 3rem;
      }
    }
    .ant-form-item {
      display: inline-block;
    }
  }
  .textAreasBtn {
    display: flex;
    align-items: center;
    button {
      margin-bottom: 0.75rem;
    }
    div {
      .ant-form-item:first-child {
        margin-left: 1.5rem;
        margin-right: 3rem;
      }
    }
    .ant-form-item {
      display: inline-block;
      textarea {
        height: 86px !important;
        min-height: 86px !important;
        max-height: 86px !important;
        width: 452px;
      }
    }
  }
`;
// Physical Area Close

// Drivers Area Open
export const DriversArea = styled.div`
  .driverName {
    .nameInputs {
      display: flex;
      align-items: center;
      .ant-form-item:first-child,
      .ant-form-item:nth-child(3) {
        input {
          width: 285px;
          margin-right: 0.75rem;
        }
      }
      .ant-form-item:nth-child(2) {
        margin: 0 0.75rem 1.5rem 0;
        input {
          width: 48px;
        }
      }
      .ant-form-item-with-help {
        margin-bottom: 0 !important;
      }
    }
  }
  .licenseInputs {
    display: flex;
    align-items: center;
    .ant-form-item:first-child {
      input {
        width: 285px;
      }
    }
    .ant-form-item:last-child {
      margin-left: 0.75rem;
      .ant-select {
        width: 118px;
      }
    }
    @media (max-width: 768px) {
      display: block;
      .ant-form-item:last-child {
        margin-left: 0rem;
      }
    }
  }
  .ant-checkbox-wrapper {
    display: flex;
    margin: 2.5rem 0;
  }
  @media (max-width: 768px) {
    & {
      .nameInputs {
        display: block !important;
        flex-direction: column !improtant;
      }
    }
  }
`;
// Drivers Area Close

// Additional Area Open
export const AdditionalArea = styled.div`
  button {
    width: 210px;
    height: 60px;
    display: block;
    margin-bottom: 0.5rem;
    &:focus {
      background-color: #fff !important;
      border-color: #c4c4c4 !important;
      color: #5f6062 !important;
    }
  }
  p {
    color: #313131;
    margin: 2rem 0 0;
    position: relative;
    width: 330px;
    cursor: pointer;
    &::after {
      content: "";
      width: 100%;
      height: 1px;
      background-color: #313131;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
`;
// Additional Area Close

// Contact Area Open
export const ContactArea = styled.div`
  .nameInputs {
    display: flex;
    align-items: center;
    .ant-form-item:first-child {
      .ant-input-affix-wrapper {
        width: 284px;
        height: 60px;
        border: 1px solid #a20010;
      }
      svg {
        width: 24px;
        height: 24px;
        color: #a6a6a6;
      }
      @media (max-width: 768px) {
        svg {
          width: 14px;
          height: 24px;
          color: #a6a6a6;
        }
      }
    }
    .ant-form-item:nth-child(2) {
      margin: 0 0.75rem 1.5rem;
      input {
        width: 48px;
        height: 60px;
      }
    }
    .ant-form-item:last-child {
      input {
        width: 286px;
        height: 60px;
      }
    }
    .ant-form-item-with-help {
      margin-bottom: 0 !important;
    }
  }
  @media (max-width: 768px) {
    & {
      .nameInputs {
        display: block !important;
        flex-direction: column !improtant;
      }
    }
  }
`;
// Contact Area Close

// Liability Area Open
export const LiabilityArea = styled.div`
  .ant-form-item {
    display: inline-block;
  }
  .motorTruckCargo {
    display: flex;
    align-items: end;
    .limitItem {
      margin-left: 1.5rem;
      .ant-form-item-label {
        padding-bottom: 0;
      }
    }
  }
  .commodities {
    p {
      margin-bottom: 0;
    }
    .commoditiesInputs:nth-child(2) {
      .ant-form-item {
        margin-bottom: 0.5rem;
      }
    }
    .commoditiesInputs {
      .ant-form-item:last-child {
        margin-left: 1.5rem;
        @media (max-width: 768px) {
          margin-left: 0rem;
        }
      }
    }
    .commoditiesInputs:nth-child(3) {
      .ant-form-item {
        margin-bottom: 1.25rem;
      }
      .ant-input-affix-wrapper:focus,
      .ant-input-affix-wrapper-focused {
        box-shadow: none;
      }
    }
  }
`;
// Liability Area Close

// Liability Area Open
export const LiabilityOddArea = styled.div`
  .ant-form-item-label {
    padding-bottom: 0;
    align-self: flex-start;
  }
  .hmb-0 {
    margin-bottom: 0;
  }
  div {
    display: flex;
    align-items: end;
  }
  @media (max-width: 768px) {
    div {
      display: block;
    }
  }
`;
// Liability Area Close

// Limit Form Area Open
export const LimitFormArea = styled.div`
  background-color: #fafafa;
  padding: 3rem 2rem 5rem;
  width: 100%;
  max-width: 878px;
  p,
  h6 {
    margin-bottom: 0;
  }
  h4 {
    margin-bottom: 2rem;
  }
  img {
    margin: 1rem 0;
  }
  .ant-form-item {
    margin-top: 1.5rem;
  }
  .coverageInputs {
    .liner {
      background-color: #a20010;
      width: 36px;
      height: 6px;
      margin: 2rem 0 1rem;
    }
    .ant-select {
      width: 155px !important;
    }
    .coverLogo {
      display: flex;
      align-items: center;
      margin-bottom: 0.75rem;
      div {
        width: 25px;
        height: 25px;
        background-color: #a20010;
        color: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0.5rem;
      }
    }
  }
  .ant-form-item {
    margin-bottom: 0;
    .ant-select {
      border: none !important;
      height: 30px !important;
      .ant-select-selector {
        border: none !important;
        background-color: unset !important;
        border-bottom: 1px solid #919191 !important;
        padding: 0;
        .ant-select-selection-placeholder {
          line-height: 2;
        }
        .ant-select-selection-item {
          line-height: 1.8 !important;
        }
      }
      .ant-select-arrow,
      .ant-select-clear {
        width: 14px;
        height: 14px;
        font-size: 1rem;
        top: 40%;
      }
      .ant-select-clear {
        top: 30%;
      }
    }
  }
  .limitInputs {
    .ant-select {
      width: 370px;
    }
  }
  @media (max-width: 768px) {
    & {
      padding: 1rem 1rem 1rem;
    }
  }
`;
// Limit Form Area Close

// Next Button Open
export const NextBtn = styled.button`
  width: 110px;
  background-color: #a20010;
  color: #ffffff;
  height: 66px;
  border: none;
  margin: 4rem 1.5rem 0;
  font-size: 1.125rem;
  text-transform: uppercase;
  &:disabled {
    background-color: #e0e0e0;
    color: #a6a6a6;
  }
`;
// Next Button Close
