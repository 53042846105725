import React from "react";
import { Container } from "react-bootstrap";
import { Form } from "antd";
import CoverageStart from "../../components/CoverageStart";
import { Link } from "react-router-dom";

const Coverage = () => {
  return (
    <Container>
      <Form name="basic" layout="vertical" onFinish={""} onFinishFailed={""}>
        <Link className="prevBtn" to="/">
          <img src="./images/chevron.svg" alt="click here" />
          PREVIOUS
        </Link>
        <CoverageStart />
        {/* <Link to="/usdot" className="nextBtn">
          Next
        </Link> */}
        <span></span>
      </Form>
    </Container>
  );
};

export default Coverage;
