import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Input, Select } from "antd";
import { VehiclesArea } from "../styles/index";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Vehicle_S } from "../action/counterAction";

const Vehicles = () => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const [placement, SetPlacement] = useState("topLeft");
  const [data, setData] = useState({});
  const [vehicleDetails, setVehicleDetails] = useState({});
  const selectRef = useRef(null);
  const [form] = Form.useForm();


  const initializeVehicle = () => {
    const vehicleData = localStorage.getItem("vehicle");
    const vehicleDataParsed = (vehicleData && JSON.parse(vehicleData)) || {};
    setVehicleDetails(vehicleDataParsed);
    const { zip_code_not_use, hour_of_service, place_card, typically_travel } =
        vehicleDataParsed;
    vehicleData &&
    setData({
      zip_code_not_use,
      hour_of_service,
      place_card,
      typically_travel,
    });
  }

  // getting data from localstorage which was taken from previous step, by using VIM
  React.useEffect(() => {
    const vehicleData = localStorage.getItem("vehicle");
    const vehicleDataParsed = (vehicleData && JSON.parse(vehicleData)) || {};
    setVehicleDetails(vehicleDataParsed);
    const { zip_code_not_use, hour_of_service, place_card, typically_travel } =
      vehicleDataParsed;
    vehicleData &&
      setData({
        zip_code_not_use,
        hour_of_service,
        place_card,
        typically_travel,
      });
  }, []);

  // Display Vehicle Info
  const displayVichleInfo = () => {
    const {
      "Model Year": modelYear,
      Model: model,
      Make: make,
    } = vehicleDetails;

    if (modelYear?.Value && model?.Value && make?.Value) {
      return modelYear?.Value + " " + make?.Value + " " + model?.Value;
    }
  };

  const handleVehicleChange = (e) => {
    selectRef.current.blur();
    const { name, value } = e.target;
    setData((prev) => {
      return { ...prev, [name]: value };
    });
    console.log(data);
  };
  useEffect(() => {
    console.log(data);
  });
  const pushDataToLocalStorage = () => {
    let preData = JSON.parse(localStorage.getItem("vehicle"));
    let updatedData = { ...preData, ...data };
    localStorage.setItem("vehicle", JSON.stringify(updatedData));
  };

  // Implement style if catagory is in state
  const setActiveOrDisactive = (catagory, value) => {
    return {
      borderColor: data[catagory] === value && "#a20010",
      backgroundColor: data[catagory] === value && "#b57f82",
      color: data[catagory] === value && "#ffffff",
    };
  };

  /*
   * Vehicles Values Validator
   * @return {boolean}
   **/
  const vehiclesValidator = () => {
    return (data?.hour_of_service || data?.hour_of_service === 0) && (data?.place_card || data?.place_card === 0) && data?.typically_travel && data?.zip_code_not_use
  };

  return (
    <div className="innerSectionOdd" style={{ marginTop: "3rem" }}>
      <VehiclesArea>
        <p className="sectionPara">{displayVichleInfo()}</p>
        <Form
          form={form}
          fields={[
            {
              name: "zip_code_not_use",
              value: data?.zip_code_not_use,
            },
            {
              name: "typically_travel",
              value: data?.typically_travel,
            },
          ]}
        >
          <Form.Item
            label={
              <p style={{ fontSize: 25, fontWeight: 600, marginLeft: 0, color: "#000" }}>
                What is the zip code of the location where you park your
                vehicles when not in use?
              </p>
            }
            name="zip_code_not_use"
            rules={[
              {
                required: true,
                message:
                  "Please input your zip code location where you park your vehicle",
              },
            ]}
            style={{ marginBottom: 30 }}
          >
            <Input
              defaultValue={vehicleDetails?.zip_code_not_use}
              placeholder="Vehicle Zip Code"
              name="zip_code_not_use"
              onChange={handleVehicleChange}
            />
          </Form.Item>
          <div className="vehicleButtons">
            <h1
              style={{
                fontSize: 25,
                fontWeight: 600,
                marginLeft: 0,
                color: "#000",
              }}
            >
              Is an Electronic Log Device (ELD) required to record hours of
              service?
            </h1>
            <Button
              style={setActiveOrDisactive("hour_of_service", 1)}
              onClick={() => {
                setData({ ...data, hour_of_service: 1 });
              }}
            >
              Yes
            </Button>
            <Button
              style={setActiveOrDisactive("hour_of_service", 0)}
              onClick={() => {
                setData({ ...data, hour_of_service: 0 });
              }}
            >
              No
            </Button>
          </div>
          <div className="vehicleButtons">
            <h1
              style={{
                fontSize: 25,
                fontWeight: 600,
                marginLeft: 0,
                color: "#000",
                marginTop: 30,
              }}
            >
              Do any listed vehicles or the load require a hazardous material
              place card?
            </h1>
            <Button
              style={setActiveOrDisactive("place_card", 1)}
              onClick={() => {
                setData({ ...data, place_card: 1 });
              }}
            >
              Yes
            </Button>
            <Button
              style={setActiveOrDisactive("place_card", 0)}
              onClick={() => {
                setData({ ...data, place_card: 0 });
              }}
            >
              No
            </Button>
          </div>
          <Form.Item
            label={
              <p
                style={{
                  fontWeight: 600,
                  fontSize: 25,
                  marginLeft: 0,
                  color: "#000",
                  marginTop: 30,
                }}
              >
                Farthest one-way distance this vehicle typically travels (90% or
                more of the time)
              </p>
            }
            name="typically_travel"
            rules={[
              {
                required: true,
                message: "Please input your distance of this vehicle",
              },
            ]}
          >
            {/* facing on change problem */}
            <Select
              Style={{ height: "50px", width: "350px" }}
              name="typically_travel"
              onChange={(value) => {
                setData((prev) => {
                  return { ...prev, typically_travel: value };
                });
              }}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              placement={placement}
              ref={selectRef}
              defaultValue={data?.typically_travel}
              placeholder="Distance"
            >
              {/*<Option value=""></Option>*/}
              <Option value="50 Miles">50 Miles</Option>
              <Option value="100 Miles">100 Miles</Option>
              <Option value="200 Miles">200 Miles</Option>
              <Option value="300 Miles">300 Miles</Option>
              <Option value="500 Mileseee">500 Miles</Option>
              <Option value="More than 500 Miles">More than 500 Miles</Option>
            </Select>
          </Form.Item>
        </Form>
        <Link
          onClick={pushDataToLocalStorage}
          to={(vehiclesValidator() && "/physicalodd") || "#"}
          style={{ marginLeft: 0 }}
          className={`nextBtn ${!vehiclesValidator() && "btn-disable"}`}
        >
          Next
        </Link>
      </VehiclesArea>
      <span></span>
    </div>
  );
};

export default Vehicles;
