import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import 'antd/dist/antd.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

// layout
import AllRoutes from './AllRoutes'
import { useEffect } from 'react'
import { validateUrl } from './utils/helper'

function App () {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    validateUrl(location.pathname, (expStatus, email) => {
      if (expStatus) {
        axios
          .get(`https://backend.cffinsure.com/v1/api/temp/${email}`)
          .then(res => {
            for (let key in res.data.data) {
              if (localStorage.hasOwnProperty) {
                localStorage.setItem(key, res.data.data[key])
              }
            }
            navigate('/')
          })
      } else {
        navigate('/404')
      }
    })
  }, [])

  return (
    <div className='App' style={{ height: '90vh' }}>
      {<AllRoutes />}
    </div>
  )
}

export default App
