import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Sidebar } from "../styles/index";
import { FaBars } from "react-icons/fa";
import NavigationContent from "./Navigation";
import useWindowDimensions from "./Dimension";

import chevron from "../assests/images/chevron.svg";

const SideMenu = (props) => {
  const [sidebar, setSidebar] = useState(false);
  const { width, height } = useWindowDimensions();
  console.log(width, height);
  useEffect(() => {
    if (width <= 992) {
      setSidebar(true);
    } else {
      setSidebar(false);
    }
  }, [width]);
  return (
    <Sidebar
      style={
        sidebar
          ? {
              width: "0px",
              height: "0px",
              backgroundColor: "transparent",
              position: "fiexd",
            }
          : { width: "280px", position: "relative" }
      }
    >
      {sidebar ? (
        <button
          style={{
            border: "none",
            display: "block",
            position: "fixed",
            top: "14px",
            left: "14px",
            zIndex: 1111,
          }}
          onClick={() => setSidebar(!sidebar)}
        >
          <FaBars />
        </button>
      ) : (
        <div
          style={
            sidebar
              ? { position: "absolute", zIndex: "11111", left: "100%" }
              : { position: "fixed", left: "0" }
          }
        >
          <Container style={{ marginLeft: 10 }}>
            <div className="logoBrand">
              <img src="/images/logo.png" alt="click here" />
            </div>
            <div>
              <button className="hideBtn" onClick={() => setSidebar(!sidebar)}>
                <img src={chevron} alt="click here" />
                HIDE
              </button>
            </div>
            <div className="progressLine">
              <p>Your progress:</p>
            </div>
            <div>
              <NavigationContent
                openSidebar={sidebar}
                hideSidebar={setSidebar}
                step={props.step}
              />
            </div>
          </Container>
        </div>
      )}
    </Sidebar>
  );
};

export default SideMenu;
