import React, { useState, useEffect } from "react";
import { Form, Input, Checkbox, Select, DatePicker } from "antd";
import { FaRegAddressCard } from "react-icons/fa";
import { BusinessArea, OwnerArea } from "../styles/index";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Owner_Information } from "../action/counterAction";

// google address auto complete api
import Autocomplete from "react-google-autocomplete";
import { legacy_createStore } from "redux";
import moment from "moment";

const OwnerInformation = () => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const dateFormat = "MM/DD/YYYY";
  const [toggle, setToggle] = useState(false);
  const [data, setData] = useState({});
  const [location, setLocation] = useState("");

  /** Populate previous form data to this form */
  useEffect(() => {
    const business = localStorage.getItem("business");
    const businessParsed = (business && JSON.parse(business)) || {};

    const customer = localStorage.getItem("customer");
    const customerParsed = (customer && JSON.parse(customer));

    if (customer) {
      setData(prev => {
        return {...prev, ...customerParsed}
      })
    } else {
      const {
        business_first_name: customer_first_name,
        business_last_name: customer_last_name,
        business_email: customer_email,
        business_m_i: customer_m_i,
        business_city: customer_city,
        business_state: customer_state,
        business_zip: customer_zip,
        business_street: customer_street,
      } = businessParsed;

      setData((prev) => {
        return {
          ...prev,
          customer_first_name,
          customer_last_name,
          customer_email,
          customer_m_i,
          customer_state,
          customer_zip,
          customer_street,
          customer_city,
        };
      });
    }
  }, []);

  const pushDataToLocalStorage = () => {
    localStorage.setItem("customer", JSON.stringify(data));
  };
  const handleData = (e) => {
    const { name, value } = e.target;
    setData((prev) => {
      return { ...prev, [name]: value };
    });
    // setLocation(value);
    // console.log(data)
  };

  /*
   * Customer Values Validator
   * @return {boolean}
   **/
  const customerValidator = () => {
    const {
      customer_first_name,
      customer_last_name,
      customer_email,
      customer_city,
      customer_zip,
      customer_state,
      customer_dob,
    } = data;

    if (!toggle) {
      return (
        customer_first_name &&
        customer_last_name &&
        customer_email &&
        customer_city &&
        customer_state &&
        customer_zip &&
        customer_dob
      );
    }

    return (
      customer_first_name &&
      customer_last_name &&
      customer_email &&
      customer_dob
    );
  };

  const handleSuggestByGoogle = (address, name) => {
    const { formatted_address } = address;
    console.log(data, " address");
    setData((prev) => {
      return { ...prev, [name]: formatted_address };
    });
    // setLocation(formatted_address);
    // postData();
  };

  function handlePlaceChanged(p) {
    const place = p;
    //console.log(" from google auto complete place===>",place);
    let lat,
      lng,
      addrSel,
      placeName,
      placeId = "";
    let country,
      state,
      city = null;
    if (place.geometry !== undefined) {
      const plcGeom = place.geometry;
      if (plcGeom.location !== undefined) {
        lat = plcGeom.location ? plcGeom.location.lat() : "";
        lng = plcGeom.location ? plcGeom.location.lng() : "";
      }
    }

    addrSel =
      place.formatted_address !== undefined ? place.formatted_address : "";
    placeName = place.name !== undefined ? place.name : "";
    placeId = place.place_id !== undefined ? place.place_id : "";

    if (place.address_components !== undefined) {
      let addrComp = place.address_components;
      for (let i = 0; i < addrComp.length; ++i) {
        var typ = addrComp[i].types;
        if (this.compIsType(typ, "administrative_area_level_1"))
          state = addrComp[i].long_name; //store the state
        else if (this.compIsType(typ, "locality"))
          city = addrComp[i].long_name; //store the city
        else if (this.compIsType(typ, "country"))
          country = addrComp[i].long_name; //store the country

        //we can break early if we find all three data
        if (state != null && city != null && country != null) break;
      }
    }

    let nameData = "";
    if (this.props.showKey !== undefined) {
      if (this.props.showKey == "CITY") {
        nameData = city;
      } else if (this.props.showKey == "STATE") {
        nameData = state;
      } else if (this.props.showKey == "COUNTRY") {
        nameData = country;
      } else if (this.props.showKey == "PLACE_NAME") {
        nameData = country;
      } else if (this.props.showKey == "FORMATTED_ADDRESS") {
        nameData = addrSel;
      } else if (this.props.showKey == "PLACE_ID") {
        nameData = placeId;
      } else {
        nameData = addrSel;
      }
    } else {
      nameData = addrSel;
    }

    if (
      this.props.removeTextFlag !== undefined &&
      this.props.removeTextFlag == "YES"
    ) {
      nameData = "";
    }

    let stateResp = {
      lat: lat,
      lng: lng,
      formattedAddress: addrSel,
      placeName: placeName,
      placeId: placeId,
      city: city,
      state: state,
      country: country,
      textboxtext: nameData,
    };
    console.log(stateResp, " state Resp ");
    // this.setState(stateResp, () => {
    //   this.props.selectedGoogleDataInfo !== undefined &&
    //     this.props.selectedGoogleDataInfo(stateResp);
    // });
    console.log(place, " place ");
    //console.log("==lat==>", lat," ==lng===>",lng, "==addrSel==>",addrSel);
    //this.props.onPlaceLoaded(place);
  }

  const handleAddress = (e) => {
    const { name, value } = e.target;
    setData((prev) => {
      return { ...prev, [name]: value };
    });
    setLocation(value);
    // postData();
  };

  useEffect(() => {
    console.log(data, " dfata");
  });

  return (
    <Form
      fields={[
        {
          name: "customer_first_name",
          value: data?.customer_first_name,
        },
        {
          name: "customer_last_name",
          value: data?.customer_last_name,
        },
        {
          name: "customer_m_i",
          value: data?.customer_m_i,
        },
        {
          name: "customer_email",
          value: data?.customer_email,
        },
        {
          name: "customer_street",
          value: data?.customer_street,
        },
        {
          name: "customer_state",
          value: data?.customer_state,
        },
        {
          name: "customer_city",
          value: data?.customer_city,
        },
        {
          name: "customer_zip",
          value: data?.customer_zip,
        },
        {
          name: 'customer_dob',
          value: '12/11/1020'
        }
      ]}
    >
      <div className="innerSection noTopMargin" style={{ marginTop: "3rem" }}>
        <BusinessArea>
          <OwnerArea>
            <h1 style={{ fontSize: 25, fontWeight: 600 }}>Who is the owner of the business?</h1>
            <div className="nameInputs">
              <Form.Item
                label={<span className="labelOdd">First Name</span>}
                name="customer_first_name"
                rules={[
                  {
                    required: true,
                    message: "Please input your first name",
                  },
                ]}
              >
                <Input
                  suffix={<FaRegAddressCard />}
                  placeholder="First Name"
                  name="customer_first_name"
                  onChange={handleData}
                  defaultValue={data?.customer_first_name}
                />
              </Form.Item>
              <Form.Item
                label={<span className="labelOdd">M.I.</span>}
                name="customer_m_i"
                rules={[
                  {
                    required: true,
                    message: "m.i.!",
                  },
                ]}
              >
                <Input
                  name="customer_m_i"
                  onChange={handleData}
                  defaultValue={data?.customer_m_i}
                />
              </Form.Item>
              <Form.Item
                label={<span className="labelOdd">Last Name</span>}
                name="customer_last_name"
                rules={[
                  {
                    required: true,
                    message: "Please input your last name.",
                  },
                ]}
              >
                <Input
                  name="customer_last_name"
                  placeholder="Last Name"
                  onChange={handleData}
                  defaultValue={data?.customer_last_name}
                />
              </Form.Item>
            </div>
            <div className="datePicker">
              <Form.Item
                label={<span className="labelOdd">Date of Birth</span>}
                name="customer_dob"
                rules={[
                  {
                    required: true,
                    message: "Please input your last name.",
                  },
                ]}
                // style={{width: 200}}
              >
                {console.log(data?.customer_dob, ' hello')}
                <DatePicker
                  value={data?.customer_dob && moment(data?.customer_dob)}
                  placeholder="MM/DD/YYYY"
                  format={dateFormat}
                  name="customer_dob"
                  onChange={(value) => {
                    setData(prev => {
                      return {...prev, customer_dob: value }
                    })
                    console.log(value.toDate());
                  }}
                  // ref={}
                  onBlur={handleData}
                />
              </Form.Item>
              <div>
                <Form.Item
                  style={{ width: 500 }}
                  className="emailInput"
                  label={<span className="labelOdd">Email</span>}
                  name="customer_email"
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: "Please input your email.",
                    },
                  ]}
                >
                  <Input
                    placeholder="Email"
                    type="email"
                    name="customer_email"
                    style={{ width: 500 }}
                    onChange={handleData}
                    defaultValue={data?.customer_email}
                  />
                </Form.Item>
              </div>
            </div>
            <h1 style={{ fontSize: 25, fontWeight: 600, margin: 0, padding: 0 }}>What is the owner’s address?</h1>
            <div className="checkBox" style={{marginTop: 12}}>
              {/* bussiness location here */}
              <Checkbox className="labelOdd" onClick={() => setToggle(!toggle)}>
                Same as business location
              </Checkbox>
            </div>
            {toggle === false ? (
              <div>
                <Autocomplete
                  apiKey={"AIzaSyDXxkjxHrqT_gzSfR4L3ilTylRU0C7CaDM"}
                  onPlaceSelected={(place) => {
                    handleSuggestByGoogle(place, "customer_street");
                  }}
                  name="customer_street"
                  onChange={handleAddress}
                  defaultValue={data?.customer_street}
                />
                <div className="multipleItems" style={{marginTop: 20}}>
                  <Form.Item
                    label={<span className="labelOdd">City</span>}
                    name="customer_city"
                    rules={[
                      {
                        required: true,
                        message: "Please input your city.",
                      },
                    ]}
                  >
                    <Input placeholder="City" name="customer_city" onChange={handleData} />
                  </Form.Item>
                  {/* state onChange error */}
                  <Form.Item
                    label={<span className="labelOdd">State</span>}
                    name="customer_state"
                    rules={[
                      {
                        required: true,
                        message: "Please input your state.",
                      },
                    ]}
                  >
                    <Input
                      name="customer_state"
                      placeholder="State"
                      onChange={handleData}
                      defaultValue={data?.customer_state}
                    />
                  </Form.Item>
                  <Form.Item
                    label={<span className="labelOdd">Zip Code</span>}
                    name="customer_zip"
                    rules={[
                      {
                        required: true,
                        message: "Please input your zip code.",
                      },
                    ]}
                  >
                    <Input
                      name="customer_zip"
                      placeholder="Zip Code"
                      onChange={handleData}
                      defaultValue={data?.customer_zip}
                    />
                  </Form.Item>
                </div>
              </div>
            ) : (
              ""
            )}
            <Link
              onClick={pushDataToLocalStorage}
              to={(customerValidator() && "/vehicletabs") || "#"}
              className={`nextBtn ${!customerValidator() && "btn-disable"}`}
              style={{ marginLeft: 0, marginTop: 60 }}
            >
              Next
            </Link>
          </OwnerArea>
        </BusinessArea>
        <span></span>
      </div>
    </Form>
  );
};

export default OwnerInformation;
