import React from "react";
import { Container } from "react-bootstrap";
import { Form } from "antd";
import { Link } from "react-router-dom";
import VehicleIdentity from "../../components/VehiclesIdentity";

const VIdentity = () => {
  return (
    <Container>
      <Form name="basic" layout="vertical" onFinish={""} onFinishFailed={""}>
        <Link className="prevBtn" to="/vehicletabs">
          <img src="./images/chevron.svg" alt="click here" />
          PREVIOUS
        </Link>
        <VehicleIdentity />
        {/* <Link to="/vehicleodd" className="nextBtn">
          Next
        </Link> */}
      </Form>
    </Container>
  );
};

export default VIdentity;
