import React, { useEffect, useState, useRef } from "react";
import { Form, Select } from "antd";

import { Link } from "react-router-dom";
import { Additional_Insured } from "../action/counterAction";
import { useDispatch } from "react-redux";
const AdditionalInsureds = () => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const [data, setData] = useState({});
  const selectRef = useRef();

  const handleAdditionalInsureds = (e) => {
    selectRef.current.blur();
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const pushDataToLocalStorage = () => {
    let preData = JSON.parse(localStorage.getItem("business"));
    let updatedData = { ...preData, ...data };
    localStorage.setItem("business", JSON.stringify(updatedData));
  };

  /*
   * additional insured Values Validator
   * @return {boolean}
   **/
  const additionalInsuredValidator = () => {
    return data?.additional_insureds && data?.subrogation_holders
  };

  useEffect(() => {
    const driver = localStorage.getItem("business");
    const parsedDriver = driver && JSON.parse(driver);

    const { additional_insureds, subrogation_holders } = parsedDriver || {};
    driver &&
      setData((prev) => {
        return {
          additional_insureds,
          subrogation_holders,
        };
      });
  }, []);

  return (
    <Form
      fields={[
        {
          name: "additional_insureds",
          value: data?.additional_insureds,
        },
        {
          name: "subrogation_holders",
          value: data?.subrogation_holders,
        },
      ]}
    >
      <div className="innerSectionOdd" style={{ marginTop: "2rem" }}>
        <p>
          Named additional insureds and waivers of subrogation
        </p>
        <div style={{ marginTop: "2rem" }}>
          <Form.Item
            label={
              <p style={{ fontSize: 25, fontWeight: 600, margin: 0 }}>
                Number of named additional insureds
              </p>
            }
            name="additional_insureds"
            rules={[
              {
                required: true,
                message: "please input your convictions!",
              },
            ]}
          >
            <Select
              style={{ width: "400px", height: "50px" }}
              placeholder="Number of Insured"
              allowClear
              name="additional_insureds"
              onChange={(value) =>
                setData((prev) => {
                  return { ...prev, additional_insureds: value };
                })
              }
              ref={selectRef}
              defaultValue={data?.additional_insureds}
            >
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={
              <p style={{ fontSize: 25, fontWeight: 600, margin: 0 }}>
                Number of named waiver of subrogation holders
              </p>
            }
            name="subrogation_holders"
            rules={[
              {
                required: true,
                message: "please input your convictions!",
              },
            ]}
          >
            <Select
              style={{ width: "400px", height: "50px" }}
              placeholder="Number of Subrogation holders"
              allowClear
              name="subrogation_holders"
              onChange={(value) =>
                setData((prev) => {
                  return { ...prev, subrogation_holders: value };
                })
              }
              ref={selectRef}
              defaultValue={data?.subrogation_holders}
            >
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
            </Select>
          </Form.Item>
          <span></span>
          <Link
            onClick={pushDataToLocalStorage}
            // to={(additionalInsuredValidator() && "/tlimits") || "#"}
            to={(additionalInsuredValidator() && "/locationaddress") || "#"}
            style={{ marginLeft: 0, marginTop: 60 }}
            className={`nextBtn ${
              !additionalInsuredValidator() && "btn-disable"
            }`}
          >
            Next
          </Link>
        </div>
      </div>
    </Form>
  );
};

export default AdditionalInsureds;
