import React, { useState } from "react";
import { Button, Form, Input, Select } from "antd";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Driver } from "../action/counterAction";
const DriversAccidents = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [driverName, setDriverName] = useState("");
  const { Option } = Select;
  const [yesButtonClass, setYesButtonClass] = useState(false);

  const handleDriverAccidents = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    console.log(data);
  };

  const pushDataToLocalStorage = () => {
    let preData = JSON.parse(localStorage.getItem("driver"));
    let updatedData = { ...preData, ...data };
    localStorage.setItem("driver", JSON.stringify(updatedData));
    dispatch(Driver(updatedData));
  };

  // Implement style if catagory is in state
  const setActiveOrDisactive = (catagory, value) => {
    return {
      borderColor: data[catagory] === value && "#a20010",
      backgroundColor: data[catagory] === value && "#b57f82",
      color: data[catagory] === value && "#ffffff",
    };
  };

  // Get driver Name from localStorage
  React.useEffect(() => {
    const driver = localStorage.getItem("driver");
    const driverParsed = (driver && JSON.parse(driver)) || {};
    const { driver_first_name, driver_last_name } = driverParsed;
    setDriverName(driver_first_name || "John Doe");
  }, []);

  return (
    <div className="innerSectionOdd">
      <h3 className="sectionHeading">{`We need a little more information about ${driverName}.`}</h3>
      <div>
        <h1>
          {`Has ${driverName} had any at fault accidents or moving violations in the
          last 3 years?`}
        </h1>
        <Button
          style={setActiveOrDisactive("driver_last_year", "1")}
          onClick={() => {
            setYesButtonClass(true);
            setData({ ...data, driver_last_year: "1" });
          }}
        >
          Yes
        </Button>
        <Button
          style={setActiveOrDisactive("driver_last_year", "0")}
          onClick={() => {
            setYesButtonClass(false);
            setData({ ...data, driver_last_year: "0" });
          }}
        >
          No
        </Button>
        <div>
          {yesButtonClass === true ? (
            <Row>
              <Col md={3}>
                <Form.Item
                  label={<span className="labelOdd">Date of incident</span>}
                  name="jkl"
                  rules={[
                    {
                      required: true,
                      message: "Please input your DBA Name",
                    },
                  ]}
                >
                  <Input
                    placeholder="DBA"
                    name="date_of_incident"
                    onChange={handleDriverAccidents}
                  />
                </Form.Item>
              </Col>
              <Col md={3}>
                <Form.Item
                  label={
                    <span style={{ marginLeft: "30px" }} className="labelOdd">
                      Accident / Violation
                    </span>
                  }
                  name="jkl"
                  rules={[
                    {
                      required: true,
                      message: "Please input your DBA Name",
                    },
                  ]}
                >
                  <select
                    style={{
                      width: "190px",
                      height: "55px",
                      marginLeft: "30px",
                    }}
                    defaultValue=""
                    name="accident_violation"
                    onChange={handleDriverAccidents}
                  >
                    <option value="jack">Jack</option>
                    <option value="lucy">Lucy</option>
                    <option value="Yiminghe">yiminghe</option>
                  </select>
                </Form.Item>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </div>
        <div style={{ margin: "2rem 0" }}>
          <h1>{`Does ${driverName} need an SR22?`}</h1>
          <Button
            style={setActiveOrDisactive("driver_doe_need", "1")}
            onClick={() => setData({ ...data, driver_doe_need: "1" })}
          >
            Yes
          </Button>
          <Button
            style={setActiveOrDisactive("driver_doe_need", "0")}
            onClick={() => setData({ ...data, driver_doe_need: "0" })}
          >
            No
          </Button>
        </div>
      </div>
      <span></span>
      <Link
        onClick={pushDataToLocalStorage}
        to="/currentinsurance"
        className="nextBtn"
      >
        Next
      </Link>
    </div>
  );
};

export default DriversAccidents;
