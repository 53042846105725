import React, { useState } from "react";
import { Button, Form, Input, Select } from "antd";
import { LiabilityArea } from "../styles";
import { Link } from "react-router-dom";
import { Liability_Action } from "../action/counterAction";
import { useDispatch } from "react-redux";
const Liability = () => {
  const [data, setData] = useState({});
  const handleLiability = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const { Option } = Select;
  const [testValue, setTestValue] = useState(false);
  const handleChange = (value) => {
    debugger;

    if (value === "0") {
      setData({ ...data, truck_cargo: "0" });
      setTestValue(false);
    } else {
      if (value === "1") {
        setData({ ...data, truck_cargo: "1" });
        setTestValue(true);
      }
    }
    // console.log(value, testValue);
  };
  return (
    <div className="innerSection">
      <LiabilityArea>
        <div>
          <Form.Item
            label="Hired Auto Liability"
            name="pzp"
            rules={[
              {
                required: true,
                message: "Please input your hired liability!",
              },
            ]}
          >
            <select
              style={{ height: "50px", width: "400px" }}
              name="auto_libility"
              onChange={handleLiability}
              placeholder="Select"
              allowClear
            >
              <option value="0">Not Selected</option>
              <option value="1">Selected</option>
            </select>
          </Form.Item>
        </div>
        <h1 style={testValue === true ? { marginBottom: "0" } : {}}>
          Motor Truck Cargo
        </h1>
        <div className="motorTruckCargo">
          <Form.Item
            label=""
            name="rzr"
            rules={[
              {
                required: true,
                message: "!",
              },
            ]}
          >
            <Select
              style={{ height: "50px", width: "400px" }}
              onChange={handleChange}
              placeholder="Select"
              allowClear
            >
              <Option value="0">Not Selected</Option>
              <Option value="1">Selected</Option>
            </Select>
          </Form.Item>
          {testValue === true ? (
            <Form.Item
              className="limitItem"
              label={
                <span className="labelOdd">Coverage limit and deductible</span>
              }
              name="trp"
              rules={[
                {
                  required: true,
                  message: "Please input your coverage limit!",
                },
              ]}
            >
              <select name="" placeholder="Select" allowClear>
                <option value="0">$5k with a $500 Deductible</option>
                <option value="1">$5k with a $1,000 Deductible</option>
                <option value="2">$10k with a $500 Deductible</option>
                <option value="3">$10k with a $1,000 Deductible</option>
                <option value="4">$25k with a $500 Deductible</option>
                <option value="5">$25k with a $500 Deductible</option>
                <option value="6">$50k with a $1,000 Deductible</option>
                <option value="7">$50k with a $1,000 Deductible</option>
                <option value="8">$75k with a $1,000 Deductible</option>
                <option value="9">$75k with a $1,000 Deductible</option>
                <option value="10">$100k with a $1,000 Deductible</option>
                <option value="11">$100k with a $1,000 Deductible</option>
                <option value="12">$150k with a $1,000 Deductible</option>
                <option value="13">$150k with a $1,000 Deductible</option>
                <option value="14">$200k with a $1,000 Deductible</option>
                <option value="15">$200k with a $1,000 Deductible</option>
                <option value="16">$250k with a $1,000 Deductible</option>
                <option value="17">$250k with a $1,000 Deductible</option>
              </select>
            </Form.Item>
          ) : (
            ""
          )}
        </div>
        {testValue === true ? (
          <div className="commodities">
            <p className="labelOdd">What commodities are you hauling?</p>
            <div className="commoditiesInputs">
              <Form.Item
                label=""
                name="zrp"
                rules={[
                  {
                    required: true,
                    message: "!",
                  },
                ]}
              >
                <Input prefix="1." />
              </Form.Item>
              <Form.Item
                label=""
                name="prz"
                rules={[
                  {
                    required: true,
                    message: "!",
                  },
                ]}
              >
                <Input prefix="3." />
              </Form.Item>
            </div>
            <div className="commoditiesInputs">
              <Form.Item
                label=""
                name="rep"
                rules={[
                  {
                    required: true,
                    message: "!",
                  },
                ]}
              >
                <Input prefix="2." />
              </Form.Item>
              <Form.Item
                label=""
                name="rap"
                rules={[
                  {
                    required: true,
                    message: "!",
                  },
                ]}
              >
                <Input prefix="4." />
              </Form.Item>
            </div>
            <div>
              <p className="labelOdd">
                Do you require Refrigeration Breakdown coverage?
              </p>
              <Button>Yes</Button>
              <Button>No</Button>
            </div>
          </div>
        ) : (
          ""
        )}
        <Link to="/liabilityodd" className="nextBtn">
          Next
        </Link>
      </LiabilityArea>
      <span></span>
    </div>
  );
};

export default Liability;
