import React, {useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Form, Input, Button } from "antd";
import axios from "axios";

import { VehiclesArea } from "../styles/index";
import { Vehicle_Number } from "../action/counterAction";

const REQUIRED_FIELDS = ["Model", "Make", "Model Year"];

const VehicleIdentity = () => {
  // Check is Api fetching data
  const [dataFetching, setDataFetching] = React.useState(false);
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**
   * Redirect to next route if api fetched successfull
   */
  React.useEffect(() => {
    Object.keys(data).length > 3 && navigate("/vehicleodd");
  });

  React.useEffect(() => {
    const editVehicle = localStorage.getItem("editVehicle");
    const parsedEditVehicle = editVehicle && JSON.parse(editVehicle);

    !parsedEditVehicle && localStorage.setItem("vehicle", JSON.stringify({}));
  }, []);

  const VehicleIdentityHandle = (e) => {
    const { name, value } = e.target;
    setData(() => {
      return { ...data, [name]: value };
    });
  };

  const VimNumberValidator = () => {
    return (data?.vehicle_number?.length === 17 && true) || false;
  };

  const findRequiredValueFromResult = (result) => {
    for (let i in result) {
      if (REQUIRED_FIELDS.includes(result[i])) {
        setData((prev) => {
          return { ...prev, [result[i]]: result };
        });
        localStorage.setItem(
          "vehicle",
          JSON.stringify({
            ...JSON.parse(localStorage.getItem("vehicle")),
            [result[i]]: result,
          })
        );
      }
    }
  };

  const pushDataToLocalStorage = () => {
    localStorage.setItem("vehicleNumber", JSON.stringify(data?.vehicle_number))
    setDataFetching(true);
    VimNumberValidator() &&
      getData()
        .then((res) => {
          res.data.Results.forEach((result) => {
            findRequiredValueFromResult(result);
          });
          setTimeout(() => {
            setDataFetching(false);
          }, 1000);
          localStorage.setItem(
            "vehicle",
            JSON.stringify({
              ...JSON.parse(localStorage.getItem("vehicle")),
              ...data,
            })
          );
        })
        .catch((err) => {
          setDataFetching(false);
          console.log(err, "error");
        });
  };

  function getData() {
    // LRBFZNR49ND067634
    return axios.get(
      "https://vpic.nhtsa.dot.gov/api/vehicles/decodevin/" +
        data?.vehicle_number +
        "?format=json"
    );
  }

  // Populate Previous data if user go back to previous page.
  useEffect(() => {
    const vehicle = localStorage.getItem('vehicleNumber');
    const parsedVehicle = vehicle && JSON.parse(vehicle);
    if (vehicle) {
      setData(prev => {
        return { ...prev, vehicle_number: parsedVehicle}
      })
    }
  }, [])

  return (
    <div className="innerSectionOdd" style={{ marginTop: "4rem" }}>
      <Form fields={[
        {
          name: 'vehicle_number',
          value: data?.vehicle_number
        }
      ]}>
      <VehiclesArea>
        <Form.Item
          label={
            <p style={{ fontSize: 25, fontWeight: 600, marginLeft: 0, color: '#000' }}>
              What is the Vehicle Identification Number (VIN)?
            </p>
          }
          name="vehicle_number"
          rules={[
            {
              required: true,
              message: "Please input your vehicle identification",
            },
          ]}
        >
          <Input placeholder="Enter Your VIN Number." name="vehicle_number" onChange={VehicleIdentityHandle} />
        </Form.Item>
        <Link className="vinLink" to="/vehicleodd" onClick={() => {
          localStorage.removeItem('vehicleNumber')
        }}>
          I don’t have the VIN
        </Link>
        {dataFetching && <p>Data fetching...</p>}
        <Link
          onClick={pushDataToLocalStorage}
          to={(Object.keys(data).length > 3 && "/vehicleodd") || "#"}
          className={`nextBtn ${
            (!VimNumberValidator() || dataFetching) && "btn-disable"
          }`}
          style={{
            marginLeft: 0,
            pointerEvents:
              ((!VimNumberValidator() || dataFetching) && "none") || "",
          }}
          disabled={!VimNumberValidator() || dataFetching}
        >
          Next
        </Link>
      </VehiclesArea>
      <span></span>
      </Form>
    </div>
  );
};

export default VehicleIdentity;
