import React from "react";
import { Container } from "react-bootstrap";
import { Form } from "antd";
import { Link } from "react-router-dom";
import AdditionalInsureds from "../../components/AdditionalInsureds";

const AddInsureds = () => {
  return (
    <Container>
      <Form name="basic" layout="vertical" onFinish={""} onFinishFailed={""}>
        <Link className="prevBtn" to="/insuranceodd">
          <img src="./images/chevron.svg" alt="click here" />
          PREVIOUS
        </Link>
        <AdditionalInsureds />
        {/* <Link to="/tlimits" className="nextBtn">
          Next
        </Link> */}
      </Form>
      <span></span>
    </Container>
  );
};

export default AddInsureds;
