import React, { useState } from "react";
import { VehicleCardArea } from "../styles/index";
import { Link } from "react-router-dom";
const VehicleCard = (props) => {
  //const [vehicleType , setVehicleType] = useState({})
  //console.log(vehicleType)

  // Implement style if catagory is in state
  const styles = {
    vehicleCardArea: (value) => {
      return {
        backgroundColor: props.currentVehicle === value && "#b57f82",
        borderColor: props.currentVehicle === value && "#a20010",
      };
    },
    p: (value) => {
      return {
        color: props.currentVehicle === value && "#ffffff",
      };
    },
    img: (value) => {
      return {
        filter:
          props.currentVehicle === value &&
          "invert('100%') sepia('0%') saturate('0%') hue-rotate('67deg') brightness('500%') contrast('102%')",
      };
    },
  };
  const setActiveOrDisactive = (value) => {
    return {
      backgroundColor: props.currentVehicle === value && "#b57f82",
      borderColor: props.currentVehicle === value && "#a20010",
    };
  };
  const data = props.data;
  console.log(data, "data in vehicle");
  return (
    <VehicleCardArea style={styles.vehicleCardArea(data.text)}  onClick={() => {
      props.handleChange({
        vehicle_type: data.text,
        vehicle_image: data.image,
      });
    }}>
      <div>
        <img
          style={styles.img(data.text)}
          src={`/images/${data.image}`}
          alt="click here"
        />
        <p style={styles.p(data.text)}>{data.text}</p>
      </div>
    </VehicleCardArea>
  );
};

export default VehicleCard;
