import React from "react";
import { useLocation } from "react-router-dom";
import { HomeContent } from "../../styles";
import SideMenu from "../SideMenu";

const Layout = ({ children }) => {
  const location = useLocation();
  console.log(location.pathname, " pathname in layout");
  return (
    <>
      <HomeContent>
        {location.pathname !== "/404" && (
          <div className="SidbarContent">
            <SideMenu />
          </div>
        )}
        <div className="MainContent">{children}</div>
      </HomeContent>
    </>
  );
};

export default Layout;
