import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { LimitFormArea } from "../styles";
import LimitItem from "./LimitItem";
import LimitItem2 from "./LimitItem2";
import { Link } from "react-router-dom";
import LimitItem1 from "./LimitItem1";
import { coverage } from "../action/counterAction";
import { useDispatch } from "react-redux";
const CoveragesLimit = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [alldata, setAllData] = useState({});
  function settingAllData(data, data1, data2) {
    setAllData({
      liability: data,
      UIM: data1,
      UM: data2,
    });
  }

  const handleData = (name, value) => {
    setData({ ...data, [name]: value });
    settingAllData(data);
  };

  const dispatchData = () => {
    let liability = localStorage.getItem("liablility");
    let uim = localStorage.getItem("UIM");
    let um = localStorage.getItem("UM");
    let data = {
      liability: JSON.parse(liability),
      um_2: JSON.parse(uim),
      um_1: JSON.parse(um),
    };

    let preData = JSON.parse(localStorage.getItem("coverage"));
    let updatedData = { ...preData, ...data };
    localStorage.setItem("coverage", JSON.stringify(updatedData));
    dispatch(coverage(updatedData));
  };

  /*
   * current Insurance Values Validator
   * @return {boolean}
   **/
  const coveragesLimitValidator = () => {
    return Object.keys(data).length === 3;
  };

  // Populate Previous data if user go back to previous page.
  useEffect(() => {
    const coverage = localStorage.getItem('coverage');
    const parsedCoverage = coverage && JSON.parse(coverage);

    const liablility = localStorage.getItem('liablility');
    const parsedLiablility = liablility && JSON.parse(liablility);

    const UM = localStorage.getItem('UM');
    const parsedUM = UM && JSON.parse(UM);

    const UIM = localStorage.getItem('UIM');
    const parsedUIM = UIM && JSON.parse(UIM);

    const { liability, um_1, um_2 } = parsedCoverage || {};


    //
    // setData(prev => {
    //   return { ...prev, ...parsedBusiness}
    // })

  }, [])

  return (
    <div className="innerSectionOdd" style={{ marginTop: "3rem" }}>
      <h3 className="sectionHeading" style={{ fontSize: 25, fontWeight: 600 }}>
        We have all the details, now select your coverages.
      </h3>
      <LimitFormArea>
        <Row style={{ alignItems: "end", position: "relative" }}>
          <Col md={3} className="coverageInputs">
            <p>Coverage</p>
          </Col>
          <Col md={8} className="limitInputs">
            <p>Limit</p>
          </Col>
          <LimitItem
            handleData={handleData}
            cat="Liability"
            coverName="ssa"
            limitName="ssb"
            coverHeading="Liability"
          />
          <LimitItem1
            handleData={handleData}
            cat="UM"
            coverName="ssc"
            limitName="ssd"
            coverHeading="UM"
          />
          <LimitItem2
            handleData={handleData}
            cat="UIM"
            coverName="sse"
            limitName="ssf"
            coverHeading="UIM"
          />
        </Row>
      </LimitFormArea>
      <Link
        onClick={dispatchData}
        to={(coveragesLimitValidator() && "/hired-liability") || "#"}
        className={`nextBtn ${!coveragesLimitValidator() && "btn-disable"}`}
        style={{ marginTop: "4rem", marginLeft: 0 }}
      >
        Next
      </Link>
      <span></span>
    </div>
  );
};

export default CoveragesLimit;
