import React, { useState, useEffect } from "react";
import { Button, Form, InputNumber, Input, Select } from "antd";
import { ButtonsArea } from "../styles/index";
import { Link } from "react-router-dom";
import { Insurance_Odd } from "../action/counterAction";
import { useDispatch } from "react-redux";
const CurrentInsuranceOdd = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [insuranceFilling, setInsuranceFilling] = useState({
    "MCS-90": 0,
    State: 0,
    "Federal Liability": 0,
    None: 0,
  });

  const handleCurrentInsuranceOdd = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    console.log(data);
  };

  const pushDataToLocalStorage = () => {
    let preData = JSON.parse(localStorage.getItem("business"));
    let updatedData = {
      ...preData,
      ...data,
      insuranceFilling: { ...insuranceFilling },
    };
    // console.log(updatedData)
    localStorage.setItem("business", JSON.stringify(updatedData));
    //  console.log(...preData,newData)
  };

  const { Option } = Select;

  // Implement style if catagory is in state
  const setActiveOrDisactive = (catagory, value) => {
    return {
      borderColor: data[catagory] === value && "#a20010",
      backgroundColor: data[catagory] === value && "#b57f82",
      color: data[catagory] === value && "#ffffff",
    };
  };

  const insuranceFillingStyle = (name) => {
    if (insuranceFilling[name]) {
      return {
        borderColor: "#a20010",
        backgroundColor: "#b57f82",
        color: "#ffffff",
      };
    }
  };

  const resetInsuranceFilling = () => {
    setInsuranceFilling((prev) => {
      return {
        "MCS-90": 0,
        State: 0,
        "Federal Liability": 0,
        None: !prev.None,
      };
    });
  };

  /*
   * Customer Values Validator
   * @return {boolean}
   **/
  const insuranceOddValidator = () => {
    const { business_repossession, coverages_below, last_five_year } = data;
    return (
        business_repossession && coverages_below && last_five_year &&
      (insuranceFilling?.["Federal Liability"] ||
        insuranceFilling?.["MCS-90"] ||
        insuranceFilling?.State ||
        insuranceFilling?.None)
    );
  };

  useEffect(() => {
    console.log(data, ' data ')
  })

  useEffect(() => {
    const driver = localStorage.getItem("business");
    const parsedDriver = driver && JSON.parse(driver);

    const {
      insuranceFilling,
      last_five_year,
      business_repossession,
      coverages_below,
    } = parsedDriver || {};
    driver &&
      setData((prev) => {
        return {
          last_five_year,
          business_repossession,
          coverages_below,
        };
      });
    setInsuranceFilling({ ...insuranceFilling });
  }, []);

  return (
    <div className="innerSection">
      <ButtonsArea>
        <div className="authority">
          <h1 style={{ fontSize: 25, fontWeight: 600 }}>
            Does your operating authority require any of these insurance
            filings?
          </h1>
          <Button
            style={insuranceFillingStyle("Federal Liability")}
            onClick={() =>
              // setData({ ...data, insurance_falling: "Federal Liability" })
              setInsuranceFilling((prev) => {
                return {
                  ...prev,
                  "Federal Liability": !prev["Federal Liability"],
                  None: 0,
                };
              })
            }
          >
            Federal Liability
          </Button>
          <Button
            style={insuranceFillingStyle("MCS-90")}
            onClick={() =>
              setInsuranceFilling((prev) => {
                return {
                  ...prev,
                  "MCS-90": !prev["MCS-90"],
                  None: 0,
                };
              })
            }
          >
            MCS-90
          </Button>
          <Button
            style={insuranceFillingStyle("State")}
            onClick={() =>
              setInsuranceFilling((prev) => {
                return {
                  ...prev,
                  State: !prev["State"],
                  None: 0,
                };
              })
            }
          >
            State
          </Button>
          <Button
            style={insuranceFillingStyle("None")}
            onClick={() => {
              resetInsuranceFilling();
            }}
          >
            None
          </Button>
        </div>
        <Form
          fields={[
            {
              name: "last_five_year",
              value: data?.last_five_year,
            },
            {
              name: "coverages_below",
              value: data?.coverages_below,
            },
          ]}
        >
          <Form.Item
            label={
              <p style={{ fontSize: 25, fontWeight: 600, marginTop: 12, marginBottom: 0 }}>
                How many accidents has your business had in the last 5 years?
              </p>
            }
            name="last_five_year"
            rules={[
              {
                required: true,
                message: "Please input your policy expiration date",
              },
            ]}
            style={{ marginTop: ".75rem" }}
          >
            <Input
              name="last_five_year"
              placeholder="Accidents quantity"
              onBlur={handleCurrentInsuranceOdd}
              // type="number"
              defaultValue={data?.last_five_year}
            />
          </Form.Item>
          <h1 style={{ fontSize: 25, fontWeight: 600, marginTop: 12, marginBottom: 0 }}>
            Does your business do repossession?
          </h1>
          <div className="repossession">
            <Button
              style={setActiveOrDisactive("business_repossession", "Yes")}
              onClick={() => setData({ ...data, business_repossession: "Yes" })}
            >
              Yes
            </Button>
            <Button
              style={setActiveOrDisactive("business_repossession", "No")}
              onClick={() => setData({ ...data, business_repossession: "No" })}
            >
              No
            </Button>
            <Button
              style={setActiveOrDisactive(
                "business_repossession",
                "Not applicable to my business"
              )}
              onClick={() =>
                setData({
                  ...data,
                  business_repossession: "Not applicable to my business",
                })
              }
            >
              Not applicable to my business
            </Button>
          </div>
          <Form.Item
            label={
              <p style={{ fontSize: 25, fontWeight: 600, marginBottom: 0 }}>
                Does the business currently have any of the coverages below?
              </p>
            }
            name="coverages_below"
            rules={[
              {
                required: true,
                message: "Please input your liability limit",
              },
            ]}
          >
            <Select
              style={{ height: "50px", width: "400px" }}
              allowClear
              name="coverages_below"
              placeholder="Coverage"
              onChange={(value) =>
                setData((prev) => {
                  return { ...prev, coverages_below: value };
                })
              }
              defaultValue={data?.coverages_below}
            >
              <Option value="General Liability">General Liability</Option>
              <option value="Business Owner Policy">
                Business Owner Policy
              </option>
              <Option value="None">None</Option>
            </Select>
          </Form.Item>
        </Form>
        <Link
          onClick={pushDataToLocalStorage}
          to={(insuranceOddValidator() && "/additionalinsureds") || "#"}
          style={{ marginLeft: 0 }}
          className={`nextBtn ${!insuranceOddValidator() && "btn-disable"}`}
        >
          Next
        </Link>
      </ButtonsArea>
      <span></span>
    </div>
  );
};

export default CurrentInsuranceOdd;
